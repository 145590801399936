<template>
  <div>
    <input type="hidden" v-model="item.defvalue" />
    <input
        ref="formitemchange"
        type="text"
        class="iteminpu"
        :class="item.correct==true?'':'error'"
        style="width:92%;"
        :disabled="item.caneidt==true?false:true"
        
        v-model="item.deftext"
        />
     <div class="formbox">
            
            <i class="ivu-icon ivu-icon-navicon-round"></i><span>选择</span>
        </div>
    <!-- <Input
      icon="md-funnel"
      @on-blur="itemchange"
      @on-click="opendataselect"
      v-model="item.deftext"
      class="error"
      style="width:92%;height:29px"
    /> -->
    <Selecttable :selecttable="selecttable" @tableselectedcallback="tableselectedcallback" />
  </div>
</template>
<script>
import Selecttable from "@/components/wxe/selecttable.vue";
 import validity_ from "@/components/wxe/smartvalidate.js";
 import Datautil from "@/utils/datautil";
 import Request from '@/utils/baseInterface.js';
export default {
    components:{
        Selecttable
    },
  data() {
    return {
        selecttable: {
        title: "选择一级菜单",
        isshow: false,
        datagrid: null,
       
      },
       itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,caneidt:false},
                    {title:"菜单名称",field:"mname",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,caneidt:true,validation:"*",isquery:true},
                    {title:"图标",field:"icon",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,caneidt:true,validation:"*",isquery:false}
        ],
        griddatas:[],
        griddatasize:0,
    };
  },
  created() {
       //this.LoadDatas();
       var quickquery_={};
       quickquery_.formitems=Datautil.$getQueryFormItems(this.itemdatas);

      var grid={};
      grid.tableColumn = Datautil.$getGridColumn(this.itemdatas);
      grid.quickquery=quickquery_;
      grid.datas=this.griddatas;
      grid.total=0;
      window.console.log("created",grid)
      this.selecttable.datagrid=grid;
      this.selecttable.textfiled="mname";
      this.selecttable.model="1";//选择模式 1 单选 2 多选
      this.selecttable.requesturl="/employapi/tbManageMenuController/datas";
    //this.selecttable.datagrid.datas=[];
  },
  updated(){
    
  },
  props: {
    item: {
      type: Object,
      value: null
    }
  },
  methods: {
    itemchange(e){
       //window.console.log(e);
    },
    opendataselect(){
         window.console.log("opendataselect:",this.selecttable);

        this.selecttable.isshow = true;
    },
    tableselectedcallback(data) {
      this.item.defvalue = data.ids.join(",");
      this.item.deftext = data.texts.join(",");
    },
    LoadDatas(){
        Request({url:"/employapi/tbManageMenuController/datas",method:"post",data:{}}).then(res=>{
        this.selecttable.datagrid.datas=res.datas;
        //this.selecttable.datagrid.total=res.total;
        this.selecttable.datagrid.total=5;
        window.console.log(res);
        });
    },
    
  }
};
</script>

<style scoped>
.error{
  border: 1px solid #f32323;
}
.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
 .iteminpu:focus {
   display: inline-block;
   outline: none;
border-color: #33aacc !important;box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}.error{
  border: 1px solid #f32323;
}
</style>