/**
 * 数据中心 
 * 数据格式 items:[{title:"名字",field:"spuName",ctype:"simpletext",show:"list,add,edit,detail",canedit:"add,edit",datatype:"add,edit",step:'',groupname:''}]
 * 
 * 组装表单的时候先组装step，然后是分组，最后是字段 如果没有则给默认值
 */

var datautil={
    /**
     * 根据配置信息拿到grid配置列
     * @param {*} items 
     */
    $getGridColumn(items){
        var columns=[];
        items.forEach((item) => {
           
            if(item.show!=null&&item.show!=undefined&&item.show!=""&&item.show.indexOf("list")!=-1){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                
                if(item.options){
                    c.options =item.options;
                }
                columns.push(c);
            }
        });
        return columns;
    },
     /**
     * 根据配置信息拿到grid配置列
     * @param {*} items iview专用
     */
    $getGridColumnIview(items){
        var columns=[];
        items.forEach((item) => {
            if(item.show!=null&&item.show!=undefined&&item.show!=""&&item.show.indexOf("list")!=-1){
                var c={};
                c.key=item.field;
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.pkcol){
                    c.width= 60;
                    c.type="selection";
                }
                if(item.options){
                    c.options =item.options;
                }
                columns.push(c);
            }
        });
        return columns;
    },
    $getAddFormItems(items){
        var columns=[];
        var columns=[];
        items.forEach((item) => {
            item.isshowadd =  this.getdefault(item.isshowadd);
            item.pkcol = item.pkcol?item.pkcol:false;
            if(item.show!=null&&item.show!=undefined&&item.show!=""&&item.show.indexOf("add")!=-1){
                if(!item.pkcol){
                    var c={};
                    c.field=item.field;
                    c.title=item.title;
                    c.ctype=item.ctype;
                    c.correct=item.correct?item.correct:true;
                    c.defvalue=item.defvalue;
                    c.datatype = item.datatype;
                    c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                    c.canedit = this.getdefaultEdit(item.canedit,"add");//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                    c.datatype = this.getdefaultDataType(item.datatype,"add");//如果新增模式下已经设置是否必填，那么使用配置里面的值，默认必填
                    c.targetfiled=item.targetfiled?item.targetfiled:null;
                    if(item.options){
                        c.options =item.options;
                    }
                    if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                        c.maxlength = item.maxlength;
                    }
                    if(item.ctype == 'videoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                    }
                    if(c.ctype == 'dateselect'){
                        c.datetype='date'
                        c.dataformat='yyyy-MM-dd'
                    }
                    else if(c.ctype == 'datetime'){
                        c.datetype='datetime'
                        c.dataformat='yyyy-MM-dd HH:mm:ss'
                    }
                   
                    columns.push(c);
                }
               
            }
        });
        return columns;
    },
    /**
     * 获取编辑的表单列
     * @param {*} items 
     */
    $getAddFormItemsExt(items){
        var newitems=[];
        //1. 表单步骤
        var steps=[];
        items.forEach((item) => {
            //1.1 如果没有配置默认步骤就配置一个进去
            if(item.step==null||item.step==undefined||item.step==""){
                item.step="系统自动创建步骤";
            }
            //1.2如果没有配置分组 给默认分组
            if(item.groupname==null||item.groupname==undefined||item.groupname==""){
                item.groupname="系统自动默认分组";
            }
            //如果步骤里面不包含该属性则添加
            if(steps.findIndex(i=>i.title==item.step)==-1){
                var step={};
                step.title=item.step;
                step.iscreate=item.step=="系统自动创建步骤"?1:2;
                step.index=steps.length;
                steps.push(step);
            }
            newitems.push(item);

        });

        // 2. 分组
        steps.forEach((item)=>{
            var groups=[];
            var temparr=newitems.filter(i=>i.step==item.title);
            if(temparr){
                temparr.forEach((arr)=>{
                    if(groups.findIndex(j=>j.title==arr.groupname)==-1){
                        var group_={};
                        group_.title=arr.groupname;
                        group_.iscreate=arr.groupname=="系统自动默认分组"?1:2;
                        group_.fields=[];
                        groups.push(group_);
                    }
                });

            }
            item.groups=groups;

        });

        //3.字段集合
        steps.forEach((item)=>{
            if(item.groups&&item.groups.length>0){
                item.groups.forEach(group=>{
                    var fields=[];
                    console.log("group:",group);
                    var temparr=newitems.filter(t=>t.step==item.title&&t.groupname==group.title);
                    if(temparr){
                        temparr.forEach(arr=>{
                            console.log("arr:",arr);
                            arr.pkcol = arr.pkcol?arr.pkcol:false;
                            if(arr.show!=null&&arr.show!=undefined&&arr.show!=""&&arr.show.indexOf("add")!=-1){
                                if(!arr.pkcol){
                                    var c={};
                                    c.field=arr.field;
                                    c.title=arr.title;
                                    c.ctype=arr.ctype;
                                    c.correct=arr.correct?arr.correct:true;
                                    c.defvalue=arr.defvalue;
                                    c.datatype = arr.datatype;
                                    c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                                    c.canedit = this.getdefaultEdit(arr.canedit,"add");//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                                    c.targetfiled=arr.targetfiled?arr.targetfiled:null;
                                    if(arr.options){
                                        c.options =arr.options;
                                    }
                                    if(arr.ctype == 'imageupload'||arr.ctype == 'photoupload'){
                                        c.baseurl = arr.baseurl;
                                        c.uploadurl = arr.uploadurl;
                                        c.maxlength = arr.maxlength;
                                    }
                                    if(arr.ctype == 'videoupload'){
                                        c.baseurl = arr.baseurl;
                                        c.uploadurl = arr.uploadurl;
                                    }
                                    if(c.ctype == 'dateselect'){
                                        c.datetype='date'
                                        c.dataformat='yyyy-MM-dd'
                                    }
                                    else if(c.ctype == 'datetime'){
                                        c.datetype='datetime'
                                        c.dataformat='yyyy-MM-dd HH:mm:ss'
                                    }
                                
                                    fields.push(c);
                                }
                               
                            }
                           
                        });
                    }
                    group.fields=fields;
                });
            }

        });

       
        return steps;
    },

    $getEditFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowedit =  this.getdefault(item.isshowedit);
            if(item.isshowedit){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.correct=item.correct?item.correct:true;
                c.defvalue=item.defvalue;
                c.canedit =  this.getdefault(item.canedit);//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                c.validation=item.validation;
                c.datatype = item.datatype;
                c.targetfiled=item.targetfiled?item.targetfiled:null;
                if(item.options){
                    c.options =item.options;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }
                columns.push(c);
               
            }
        });
        return columns;
    },
    $getAuditFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowaudit =  this.getdefault(item.isshowaudit);
            if(item.isshowaudit){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.correct=item.correct?item.correct:true;
                c.defvalue=item.defvalue;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.canedit =  this.getdefaultext(item.auditcanedit);//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                c.validation=item.validation;
                c.datatype = item.datatype;
                c.targetfiled=item.targetfiled?item.targetfiled:null;
                if(item.options){
                    c.options =item.options;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }
                columns.push(c);
               
            }
        });
        return columns;
    },
    $getDetailFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowdetail = this.getdefault(item.isshowdetail);
            if(item.isshowdetail){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.correct=true;
                c.defvalue=item.defvalue;
                c.canedit=false;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.datatype=item.datatype;
                c.targetfiled=item.targetfiled;
                if(item.options){
                    c.options =item.options;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }
                columns.push(c);
            }
        });
        return columns;
    },
    /**
     * 获取查询表单
     * @param {} items 
     * @returns 
     */
    $getQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.options){
                    c.options =item.options;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='daterange'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetimerange'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }
               
                c.correct=true;
                c.canedit=true;
               // console.log("c:",c);
                columns.push(c);
            }
        });
        return columns;
    },
    $getChartQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.defvalue=item.defvalue;
                if(item.options){
                    c.options =item.options;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'monthselect'){
                    c.datetype='month'
                    c.dataformat='yyyy-MM'
                    c.defvalue=this.getCurrentMonth();
                }else if(c.ctype == 'yearselect'){
                    c.datetype='year'
                    c.dataformat='yyyy'
                    c.defvalue=this.getCurrentYear();
                }
               
                c.correct=true;
                c.canedit=true;
               // console.log("c:",c);
                columns.push(c);
            }
        });
        return columns;
    },

    
    $resetQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;

                if(item.options){
                    c.options =item.options;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='daterange'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetimerange'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'monthselect'){
                    c.datetype='month'
                    c.dataformat='yyyy-MM'
                    c.defvalue=this.getCurrentMonth();
                }else if(c.ctype == 'yearselect'){
                    c.datetype='year'
                    c.dataformat='yyyy'
                    c.defvalue=this.getCurrentYear();
                }
               
                c.defvalue=null;
               c.deftext=null;
                c.correct=true;
                c.canedit=true;
                console.log("c:",c);
                columns.push(c);
                
            }
        });
        return columns;
    },
    $loadDetailData(items,data){
      
        items.forEach((item) => {
            if(data[item.field]){
                item.defvalue=data[item.field]+"";
            }
          
           if(item.ctype=='dropselect'){
                var arr=item.options;
                var deftexts_=[];
                if(arr){
                    arr.forEach(i=>{
                    var dev=item.defvalue+"";
                    var itemval=i.id+"";
                    // console.log("dev:",dev,"itemval:",itemval);
                    if(dev&&dev.indexOf(itemval)!=-1){
                        deftexts_.push(i.text);
                    }
                    });
                    item.deftext=deftexts_.join(",");
                }
            }
            if(item.targetfiled){
                item.deftext=data[item.targetfiled]?data[item.targetfiled]:null;
            }
            // console.log(item);
        });
        return items;
    },
    
    $getimglength(defvalue){
        if(defvalue!=null&&defvalue!=undefined&&defvalue!=""){
            var arr=defvalue.split(",");
            return arr.length;
        }else{
            return 0;
        }
    },
    /**
     * 根据键和值查询数组对象中的对象（只返回一个）
     * @param {*} data 
     * @param {*} key 
     * @param {*} val 
     */
    finditem(data, key, val) {
        let ret = null;
        if (data) {
            data.forEach(item => {
                if (item[key] == val) {
                    ret = item;
                }
            });
        }
        return ret;
    },
    /**
     * 获取当前年份
     */
    getCurrentYear(){
        let yy = new Date().getFullYear();
        return yy+"";
    },
     /**
     * 获取当前月份
     */
    getCurrentMonth(){
        let yy = new Date().getFullYear();
        let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
        return yy +"-"+ mm;
    },
    /**
     * 格式化列表数据
     * @param {*} data  原始数据
     * @param {*} columns 表格列
     */
    $formattrgriddatas(data,columns){
        var datas_ = data;
        datas_.forEach(item1=>{

            columns.forEach(item=>{
                if(item.ctype == 'dropselect'){
                    var options_ =item.options;
                    if(options_){
                        var oldval =item1[item.field];
                        var arr =options_.filter(item=>item.id==oldval);
                        if(arr&&arr.length>0&&arr[0].text){
                            item1[item.field] = arr[0].text 
                        }
                    }
                }
            })
        })
        
        return datas_;
    },

    getdefault(val){
        if(val == null||val==undefined||val==true){
            return true;
        }else{
            return false;
        }
    },
    /**
     * 获取编辑默认值
     * @param {*} val 值
     * @param {*} model 模式 add|edit
     */
    getdefaultEdit(val,model){
        if(val == null||val==undefined||val==""){
            return false;
        }else if(val.indexOf(model)!=-1){
            return true;
        }else{
            return false;
        }
    },
    /**
     * 获取必填配置
     * @param {*} val 
     * @param {*} model 
     */
    getdefaultDataType(val,model){
        if(val == null||val==undefined||val==""){
            return "*";
        }else if(val.indexOf(model)!=-1){
            return "*";
        }else{
            return "";
        }
    },

    getdefaultext(val){
        if(val == null||val==undefined){
            return false;
        }else{
            return true;
        }
    }

};
export default datautil
