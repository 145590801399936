<template>

  <div class="upload-flex">
    <div class="demo-upload-list" v-for="(item1,ind1) in getImagedatas(formitem.defvalue)" :key="ind1">
      <template >
            <img class="upload" :src="item1.realurl" />
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" @click.native="handleView(item1.realurl)"></Icon>
              <Icon type="ios-trash-outline" @click.native="handleRemove(item1.suburl,ind1)"></Icon>
            </div>
      </template>
      <!-- <template >
        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
      </template> -->
    </div>
    <Upload
      ref="upload"
        :headers="header"
        :show-upload-list="false"
        :on-success="handleSuccess"
        :format="['jpg','jpeg','png']"
        :max-size="5120"
        :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize"
        :before-upload="handleBeforeUpload"
        multiple
        type="drag"
        action="http://192.168.0.100:10008/employapi/commenslideshow/uploadslideimage"
        style="display: inline-block;width:58px;"
        v-if="showimgadd(this.formitem)"
    > 
        <div style="width: 58px;height:60px;line-height: 60px;" >
          <Icon type="ios-camera" size="20"></Icon>
        </div>
    </Upload>
     <Modal title="查看图片" v-model="visible">
        <img :src="imgName" v-if="visible" style="width: 100%">
    </Modal>
  </div>
</template>
<script>
// import validity_ from "@/components/wxe/smartvalidate.js";
import Datautil from "@/utils/datautil";
export default {
  data() {
    return {
      uploadList: [],
      imgName: '',
      visible: false,
      header:{
          'apitoken': 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJjNmVlNTUzOWY2N2E0YzI1YjNjM2QyYTQ0MDQ1OGY3NiIsInN1YiI6Im93eXRBNktMaTAwbUxJY3Y2WEFFMElrV1hDOTQiLCJpYXQiOjE2MjgyNDA1Njd9.cKBCyiRyF-I87tqMrqr4rYLWcI6IdcGQbcG9aKuYcsQ',
        },
    };
  },
  created() {
   console.log(this.formitem)
  },
  updated() {},
  props: {
    formitem: {
      type: Object,
      value: null,
      uploadurl: {
        type: String,
        value: null,
      },
    },
  },
  methods: {
    getImagedatas(defvalue,baseurl='https://qxask.oss-cn-chengdu.aliyuncs.com/'){
    var arr=[];
    if(defvalue!=null&&defvalue!=undefined&&defvalue!=""){
      var arr_=defvalue.split(",");
      arr_.forEach(function(item,index){
        var temp={};
        temp.realurl=baseurl+item;
        temp.suburl=item;
        arr.push(temp);
      });
    }
    return arr;
  },
  showimgadd(item){
    let defvalue_ = item.defvalue;
    let maxlength_ = item.maxlength;
    let caneidt= item.caneidt;
    console.log(maxlength_)
    if(caneidt){
      var imglenth =Datautil.$getimglength(defvalue_);
      if(imglenth<maxlength_){
        return true;
      }else{
        // this.handleBeforeUpload(maxlength_);
        return false;
      }
    }else{
      return false;
    }
  },
    handleView (val) {
      this.imgName = val;
      this.visible = true;
    },
    handleRemove (defvalue,index) {
      // 从 upload 实例删除数据
      console.log(defvalue,index);
      console.log( this.formitem.defvalue);
      var defvalue_= this.formitem.defvalue;
      var arr = defvalue_.split(",");
      var curl = defvalue;
      console.log(arr.indexOf(curl))
      if(arr.indexOf(curl)!=-1){
        var index_ = arr.indexOf(curl);
        arr.splice(index_,1)
      }
      console.log(arr)
      this.formitem.defvalue = arr.join(",")
    },
    handleSuccess (res, file) {
      // 因为上传过程为实例，这里模拟添加 url
      if(res.msg){
        let oldval_=this.formitem.defvalue;
        let newval_=res.obj;
        if(oldval_!=null&&oldval_!=""){
          oldval_= oldval_ + (newval_ ?","+ newval_:'');
        }else{
          oldval_= newval_ ? newval_ : '';
        }
        this.formitem.defvalue= oldval_
      }
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。'
      });
    },
    handleMaxSize (file) {
      this.$Notice.warning({
        title: '超出文件大小限制',
        desc: '文件 ' + file.name + ' 太大，不能超过 5M。'
      });
    },
    handleBeforeUpload () {
       var imglenth =Datautil.$getimglength(this.formitem.defvalue);
       var maxlenth =this.formitem.maxlength?this.formitem.maxlength:'9';

      if (imglenth>=maxlenth) {
        this.$Notice.warning({
           title: `最多只能上传 ${maxlenth} 张图片。`
        });
      }
      return false;
    },
  },
  mounted () {
    // this.uploadList = this.$refs.upload.fileList;
  }
};
</script>
<style   scoped>
 .demo-upload-list{
        display: flex !important;
        flex-wrap: wrap;
        text-align: center;
        line-height: 60px;
        /* border: 1px solid transparent; */
        border-radius: 4px;
        overflow: hidden;
        /* background: #fff; */
        position: relative;
        /* box-shadow: 0 1px 1px rgba(0,0,0,.2); */
        margin: 4px;
    }
    .demo-upload-list img{
        width: 60px;
        height: 60px;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
    .upload-flex{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
</style>