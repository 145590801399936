<template>
    <div style="background-color: cadetblue;">
      <!-- <vxe-table
        border="full"
        size="mini"
        align="center"
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        resizable
        stripe
        class="Tables"
        show-overflow
        :data="baseInfoVos"
      >
        <vxe-table-column field="description" title="统计标题" show-header-overflow show-overflow="title" show-footer-overflow ></vxe-table-column>
        <vxe-table-column field="val" title="数值" show-header-overflow show-overflow="title" show-footer-overflow ></vxe-table-column>
        <vxe-table-column field="description1" title="统计标题" show-header-overflow show-overflow="title" show-footer-overflow ></vxe-table-column>
        <vxe-table-column field="val1" title="数量" show-header-overflow show-overflow="title" show-footer-overflow ></vxe-table-column>

      </vxe-table> -->
    <!-- <Row :gutter="20">
      <i-col :xs="2" :md="4" :lg="6" v-for="(item, i) in inforCardData" :key="`infor-${i}`" style="height: 120px;padding-bottom: 10px;">
        <infor-card :infoitem="item">
        </infor-card>
      </i-col>
    </Row>
   <Row :gutter="20" style="display: flex;flex-wrap: wrap;">
      
     
    <Card :style="{width:item.boxwidth,margin:'10px'}" v-for="item in charts" :key="item.id">
     <chart-pie v-if="item.type=='pie'" style="height: 300px;" :itemdata="item" :text="item.title"/>
     <chart-line-bar v-if="item.type=='line'" style="height: 300px;" :itemdata="item" :text="item.title"/> 
    </Card>

    </Row> -->
      

      
    </div>
   
</template>
<script>
import ChartPie from '@/components/echarts/pie.vue'
import ChartLineBar from '@/components/echarts/linebar.vue'
import InforCard from '@/components/infocard/infocard.vue'
import Request from '@/utils/baseInterface.js';
    export default {
        components: {
           ChartPie,InforCard,ChartLineBar
        },
        data () {
            return {
                    //定义信息，后端不好控制图标颜色等，因此定义该数据结构，通过code和返回数据做对比
                infodatas:[
                      {code:"platOrderCount", title: '订单数量', icon: 'md-person-add', count: 0, color: '#2d8cf0' },
                      {code:"platOrderAmount", title: '订单金额', icon: 'md-locate', count: 0, color: '#19be6b' },
                      {code:"platUserCount", title: '用户数量', icon: 'md-help-circle', count: 0, color: '#ff9900' },
                      {code:"platOutletCount", title: '门店数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platYearMemberAmount", title: '年卡金额', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platClubCardCount", title: '储值卡数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platUserNew", title: '新增用户', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platOrderNew", title: '新增订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderCount", title: '订单数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderAmount", title: '订单金额', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderNew", title: '新增订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderOtherCount", title: '第三方订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderOtherWaitCount", title: '待审核订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      
                      {code:"employServiceCount", title: '取号累计', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employServiceDayCount", title: '今日取号', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employServiceDayWaitCount", title: '待服务', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employOrderWaitCheckCount", title: '待审核', icon: 'md-share', count: 0, color: '#ed3f14' },
                     
                      {code:"otherOrderCount", title: '订单数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherUserCount", title: '用户数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherOutletCount", title: '门店数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                ],
                baseinfos:[],//统计数量  这个从接口取回来
                inforCardData:[],//实际展示的信息
                charts:[],//图标数据集合
                baseInfoVos:[],//
            }
        },
        created(){
         //  console.log(this.pieData)
            var parma={};
            Request({url:"/employapi/pcoprate/queryhomedatas",method:"post",data:parma}).then(res=>{
              if(res.success){
                 //this.screenBaseInfo(this.infodatas,res.baseinfos);
                 console.log("baseInfoVos:",res.baseInfoVos);
                  this.charts=res.charts;
                  //var baseInfoVos_=res.baseInfoVos;
                  this.TableSplit(res.baseInfoVos)
                 //this.baseInfoVos=res.baseInfoVos;
              }
            });
             
        
        },
        mounted() {
           
            
        },
        methods:{
          /**
           * 筛选要展示的信息
           */
           screenBaseInfo(infodatas,baseinfos){
             var arr=[];
             if(baseinfos&&infodatas.length>0){
               baseinfos.forEach(item => {
                 var temp_=infodatas.find(t=>t.code==item.code);
                 if(temp_){
                   var v_=0;
                   if(item.con>0){
                    v_=item.con;
                   }else if(item.sum){
                      v_=item.sum;
                   }
                   temp_.count=v_;
                   arr.push(temp_);
                 }
               });
             }
             this.inforCardData=arr;
           },
           //拆分表格
           TableSplit(baseinfo){
             var datas=[];
             if(baseinfo){
               var item_=null;
               baseinfo.forEach(function(item,index){
                 if(index%2==0){
                   item_={};
                   item_.description=item.description;
                   if(item.sum==0||(item.sum!=null&&item.sum!=undefined&&item.sum!="")){
                      console.log(item)
                    item_.val=item.sum;
                   }else{
                      item_.val=item.con;
                   }
                 }else{
                   item_.description1=item.description;
                   if(item.sum==0||(item.sum!=null&&item.sum!=undefined&&item.sum!="")){
                     console.log(item)
                    item_.val1=item.sum;
                   }else{
                      item_.val1=item.con;
                   }
                   datas.push(item_);
                 }
               });
               this.baseInfoVos=datas;
             }
           }
           
        }
    }

</script>