<template>
    <Row>
        <i-col span='14' style="padding-right:10px;">
         <div style="text-align: left;">
            <Button class="actionbtn"  icon="md-add" @click="openaddview" >新增</Button>
            <Button class="actionbtn"    icon="md-create" @click="openEditview">编辑</Button>
            <Button class="actionbtn"    icon="md-close" @click="dodelete">删除</Button>
        </div>
      
          
         <!-- 表格组件 -->
            <simpletable
              :datagrid="gridedata"
              @girdpagechanged="girdpagechanged"
             @onOptionclick="onOptionclick"
            />
            
        </i-col>
       <i-col span='10' style="padding-right:10px;">
           <vxe-table
                 resizable
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"
                :tree-config="{lazy: true,labelField: 'categoryTitle',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="600"
                :data="categorydatas">
            <vxe-table-column field="id" title="编号"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           
            <vxe-table-column field="categoryTitle" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           
            <vxe-table-column field="con" title="子分类数量"  :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            
            <vxe-table-column title="操作" width="100" fixed="right" show-overflow> 
  
        <template #default="{ row }"> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'bcid#ISNULL')" icon="vxe-icon--check" @click="funoptcilck(row,'addrelation')" >绑定</vxe-button> 
            <vxe-button type="text" status="danger" v-if="showoptionbutton(row,'bcid#ISNOTNULL')" icon="vxe-icon--close" @click="funoptcilck(row,'removerelation')" >解绑</vxe-button>   
        </template> 
        </vxe-table-column>

        </vxe-table>
       </i-col>    
        <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
    </Row>
   
</template>
<script>
import Request from '@/utils/baseInterface.js';
import simpletable from "@/components/wxe/simpletable";
import smartForm from "@/components/wxe/smartForm"; //表单
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
import Datautil from "@/utils/datautil";

    export default {
        components: {
            simpletable,smartForm,quickqueryform
        },
        data () {
            return {
                itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,caneidt:false},
                    {title:"品牌名字",field:"brandName",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,caneidt:true,validation:"*",isquery:true},
                    {title:"品牌编码",field:"brandCode",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,caneidt:true,validation:"*",isquery:true},
                    {isshowedit: true,correct: true,isquery: false,maxlength: 1,isshowadd: true,title: "商品图片",isshowdetail: true,isshowlist: false, baseurl: "https://qxask.oss-cn-chengdu.aliyuncs.com/",field: "logo",ctype: "photoupload",canedit: true,pkcol: false},
                    {title: "品牌状态",field: "brandStatus",ctype: "dropselect",isshowlist: true,isshowedit: false,correct: true,isquery: true,canedit: false,pkcol: false,isshowadd: false,isshowdetail: true,options: [{ id: "1", text: "启用" },{ id: "2", text: "停用" }],},
                    {title:"排序",field:"brandSort",ctype:"integernumber",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,caneidt:true,validation:"*",isquery:true},
                     {title:"创建时间",field:"createTime",ctype:"dateselect",isshowlist:true,isshowadd:false,isshowedit:false, defvalue:null  },
                     {title:"创建人编号",field:"createBy",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:false,isshowedit:false},
                     {title:"创建人姓名",field:"createName",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:false,isshowedit:false},

                    {title:"更新时间",field:"updateTime",ctype:"dateselect",isshowlist:true,isshowadd:false,isshowedit:false, defvalue:null  },
                     {title:"更新人编号",field:"updateBy",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:false,isshowedit:false},
                     {title:"更新人姓名",field:"updateByName",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:false,isshowedit:false},
                
                ],
               
                gridedata:{tid:'brandlist', tableColumn:[], datas:[], total:0,optwidth:120,funopt: [{ funname: "showcategory", icon: "vxe-icon--menu", title: "查看分类" }], },
               
                quickquery:{formitems:[]}, 
                formitems:[],
                formconfig: { title: "编辑", modal1: false },
                selectedrow:null,//选中数据
                queryparma:{page:1,rows:20},//查询参数
                relationqueryparma:{},//关系列表查询参数
                categorydatas:null,
            }
        },
        created(){
    
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.gridedata.tableColumn=columndata;
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            this.LoadDatas();
           
           
        
        },
        methods:{
            //分页加载数据
            LoadDatas(){
                Request({url:"/empapi/pro/brand/datalist",method:"post",data:this.queryparma}).then(res=>{
            
                this.gridedata.datas=res.data.datas;
                this.gridedata.total=res.data.total;
                });
            },

             LoadCategoryDatas(){
                 var parma={};
                 parma.catelogId='0';
                 parma.brandId=this.selectedrow.id;
                Request({url:"/empapi/pro/category/queryRelation",method:"post",data:parma}).then(res=>{
                    var datas_=res.data.datas;
                   datas_.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                       }
                   });
                    this.categorydatas=datas_;
                });
            },
            loadchildData({row}){
                return new Promise(resolve => {
                 var parma={};
                 parma.catelogId=row.id;
                parma.brandId=this.selectedrow.id;
                Request({url:"/empapi/pro/category/queryRelation",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                  
                   resolve(datas)
                window.console.log(res);        
                });
                
                console.log(row);
                 });
            },
             openaddview(){
                var columndataadd=  Datautil.$getAddFormItems(this.itemdatas);
                //window.console.log("columndata1:",columndata1);
                this.formitems=columndataadd;
                this.formconfig.option="add";
                this.formconfig.title="新增",
                this.formconfig.modal1=true;
            },
            openEditview(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择数据，请选择数据后再点击编辑按钮');
                }else{
                    var columndataedit=  Datautil.$getEditFormItems(this.itemdatas);
                    columndataedit= Datautil.$loadDetailData(columndataedit,this.selectedrow);
                    this.formconfig.option="edit";
                    this.formitems=columndataedit;
                    this.formconfig.title="编辑",
                    this.formconfig.modal1=true;
                }
                
            },
            dodelete(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择待删除的数据');
                }else{
                    this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=this.selectedrow.id;
                             Request({url:"/empapi/pro/brand/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            },
            openDetailview(){
                 var columndatadetail=  Datautil.$getDetailFormItems(this.itemdatas);
                this.formitems=columndatadetail;
                this.formconfig.title="详情",
                this.formconfig.option="detaill";
                this.formconfig.modal1=true;
            },
           
            onsubmitquerydata(data){
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.queryparma=d;
                this.gridedata.cpage=1;//表格从第一页开始
                this.LoadDatas();
               
            },
             onOptionclick(data){
                  console.log(data);
                   this.selectedrow=data.selected;
                  if (data.tid == "brandlist") {
                    if (data.funname == "showcategory") {
                       this.LoadCategoryDatas();
               
                    }
                }
            },
            girdpagechanged(data){
                this.queryparma.page=data.cpage;
                this.LoadDatas();
                
            },
            onsubmit(data){
               
                if(data.option=="add"){
                    Request({url:"/empapi/pro/brand/doAdd",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                        this.LoadDatas();
                         this.formconfig.modal1=false;
                    });
                }else if(data.option=="edit"){
                    Request({url:"/empapi/pro/brand/doUpdate",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.LoadDatas();
                          this.formconfig.modal1=false;
                    });
                }
                
            },
           showoptionbutton(row, exp) {
                if (exp) {
                    var arr = exp.split("#");
                    if (arr && arr.length >= 2) {
                    var coleval = row[arr[0]];
                    var operation = arr[1];
                    var val = arr[2];
                    if (operation == "eq") {
                        return coleval == val;
                    } else if (operation == "ne") {
                        return coleval != val;
                    } else if (operation == "ISNOTNULL") {
                        return coleval ? true : false;
                    } else if (operation == "ISNULL") {
                        return coleval ? false : true;
                    } else {
                        return false;
                    }
                    } else {
                    return false;
                    }
                } else {
                    return true;
                }
            },

            funoptcilck(row,funname){
                if(funname=='addrelation'){
                    var parma={};
                    parma.catelogId=row.id;
                    parma.brandId=this.selectedrow.id;
                    Request({url:"/empapi/pro/category/addRelation",method:"post",data:parma}).then(res=>{
                        // if(res.success){
                        //         this.$Message.info('操作成功');
                        // }else{
                        //         this.$Message.error(res.message);
                        // }
                        this.LoadCategoryDatas();
                        
                    });
                }else if(funname=='removerelation'){
                    var parma={};
                    parma.id=row.bcid;
                   
                    Request({url:"/empapi/pro/category/removeRelation",method:"post",data:parma}).then(res=>{
                       
                        this.LoadCategoryDatas();
                        
                    });
                }
                
            }
        }
    }

</script>