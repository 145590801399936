<template>
    <div style="">
     
      <Row :gutter="20" >
          <i-col :xs="16" :md="16" :lg="16"  >

            <Row  style="margin-top:calc( 48vh - 200px);">
              <Col span="8">
              <Card style="background-color: rgb(7 148 154);    border: 0;margin-right:1rem">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_all.png">
                            <h3 style="font-size:1rem ">累计检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
              <Col span="8">
                <Card style="background-color: rgb(7 148 154);  border: 0;margin-right:1rem">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_year.png">
                            <h3>年度检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
              <Col span="8">
              <Card style="background-color: rgb(7 148 154) ; border: 0;">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_month.png">
                            <h3>月度检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
          </Row>
           <Row style="margin-top:1rem" >
              <Col span="8" >
              <Card style="background-color: rgb(7 148 154) ; border: 0;margin-right:1rem">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_month1.png">
                            <h3>本月检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
              <Col span="8">
                <Card style="background-color: rgb(7 148 154) ; border: 0;margin-right:1rem">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_man.png">
                            <h3>男性检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
              <Col span="8">
              <Card style="background-color: rgb(7 148 154) ; border: 0;">
                        <div style="text-align:center;color:#fff;">
                            <img style="width:120px;height:120px" src="~@/assets/images/index/check_woman.png">
                            <h3>女性检查</h3>
                             <h4>1000</h4>
                        </div>
                    </Card>
              </Col>
          </Row>
           
             
              
          </i-col>
           <i-col :xs="4" :md="4" :lg="4" style="margin-top:calc(48vh - 300px)">
           
                <img style="width:400px;height:600px;" src="~@/assets/images/index/person.png" />
           
          </i-col>
      </Row>

      
    </div>
   
</template>
<script>
import ChartPie from '@/components/echarts/pie.vue'
import ChartLineBar from '@/components/echarts/linebar.vue'
import InforCard from '@/components/infocard/infocard.vue'
import Request from '@/utils/baseInterface.js';
    export default {
        components: {
           ChartPie,InforCard,ChartLineBar
        },
        data () {
            return {
                    //定义信息，后端不好控制图标颜色等，因此定义该数据结构，通过code和返回数据做对比
                infodatas:[
                      {code:"platOrderCount", title: '订单数量', icon: 'md-person-add', count: 0, color: '#2d8cf0' },
                      {code:"platOrderAmount", title: '订单金额', icon: 'md-locate', count: 0, color: '#19be6b' },
                      {code:"platUserCount", title: '用户数量', icon: 'md-help-circle', count: 0, color: '#ff9900' },
                      {code:"platOutletCount", title: '门店数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platYearMemberAmount", title: '年卡金额', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platClubCardCount", title: '储值卡数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platUserNew", title: '新增用户', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"platOrderNew", title: '新增订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderCount", title: '订单数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderAmount", title: '订单金额', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderNew", title: '新增订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderOtherCount", title: '第三方订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"outletOrderOtherWaitCount", title: '待审核订单', icon: 'md-share', count: 0, color: '#ed3f14' },
                      
                      {code:"employServiceCount", title: '取号累计', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employServiceDayCount", title: '今日取号', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employServiceDayWaitCount", title: '待服务', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"employOrderWaitCheckCount", title: '待审核', icon: 'md-share', count: 0, color: '#ed3f14' },
                     
                      {code:"otherOrderCount", title: '订单数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherUserCount", title: '用户数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherOutletCount", title: '门店数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                      {code:"otherEmployCount", title: '员工数量', icon: 'md-share', count: 0, color: '#ed3f14' },
                ],
                baseinfos:[],//统计数量  这个从接口取回来
                inforCardData:[],//实际展示的信息
                charts:[],//图标数据集合
                baseInfoVos:[],//
            }
        },
        created(){
         //  console.log(this.pieData)
            var parma={};



        
        },
        mounted() {
           
            
        },
        methods:{
          /**
           * 筛选要展示的信息
           */
           screenBaseInfo(infodatas,baseinfos){
             var arr=[];
             if(baseinfos&&infodatas.length>0){
               baseinfos.forEach(item => {
                 var temp_=infodatas.find(t=>t.code==item.code);
                 if(temp_){
                   var v_=0;
                   if(item.con>0){
                    v_=item.con;
                   }else if(item.sum){
                      v_=item.sum;
                   }
                   temp_.count=v_;
                   arr.push(temp_);
                 }
               });
             }
             this.inforCardData=arr;
           },
           //拆分表格
           TableSplit(baseinfo){
             var datas=[];
             if(baseinfo){
               var item_=null;
               baseinfo.forEach(function(item,index){
                 if(index%2==0){
                   item_={};
                   item_.description=item.description;
                   if(item.sum==0||(item.sum!=null&&item.sum!=undefined&&item.sum!="")){
                      console.log(item)
                    item_.val=item.sum;
                   }else{
                      item_.val=item.con;
                   }
                 }else{
                   item_.description1=item.description;
                   if(item.sum==0||(item.sum!=null&&item.sum!=undefined&&item.sum!="")){
                     console.log(item)
                    item_.val1=item.sum;
                   }else{
                      item_.val1=item.con;
                   }
                   datas.push(item_);
                 }
               });
               this.baseInfoVos=datas;
             }
           }
           
        }
    }

</script>