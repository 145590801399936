<template>
    <div class="formitembox-mm" ref="formitem" :data-d="formitem.defvalue">
       
         <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
         <Select   :class="formitem.correct==true?'':'error'" :element-id="formitem.field" @on-change="itemchange" clearable :disabled="formitem.canedit==true?false:true"
         v-model="formitem.defvalue"  style="width:92%;">
            <Option v-for="item in formitem.options" :key="item.id" :value="item.id">{{ item.text }}</Option>
        </Select>
         </FormItem>
    </div>  
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
    export default {
    created(){
        // console.log(this.formitem);
       
       
   },
   updated(){
    //    console.log(this.formitem);
   },
    data(){
        return{
          
        }
    },
   props:{
       formitem:{
           type:Object,
           value:null
       },
      
   },
   
   methods:{
       formattertitle(item){
        var title=item.title;
        if(item.datatype){
            title=title+'*'
        }
        title=title+":";
        return title;
        },
       itemchange(e){
         console.log(e);
         var selecteditem=this.formitem.options.find(t=>t.id==e);
        let e_={};
        e_.filedname=this.formitem.field;
        e_.val=e;
        e_.deftext=selecteditem?selecteditem.text:null;
         if(this.formitem.validation){
            var validateret= validity_(this.formitem.validation,this.formitem.defvalue);
            if(!validateret.success){
            this.formitem.correct=false;
            //console.log(validateret);
            }else{
            this.formitem.correct=true;
            }
        }
        this.$emit("formitemchange",e_);
           
           
       }
   }
}
</script>
<style   scoped>

.error{
  border: 1px solid #f32323;
}

</style>