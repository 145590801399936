<template>
  <div class="formitembox-mm">
    <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <input type="hidden" v-model="formitem.defvalue" />
    <Input
      icon="md-funnel"
      @on-blur="itemchange"
      @on-enter="opendataselect"
      @on-click="opendataselect"
      v-model="formitem.deftext"
      
       :disabled="formitem.canedit == true ? false : true"
      style="width:92%;height:29px"
    />
     </FormItem>
    <Modal
      v-model="isshow"
      width="55vw"
      title="分类数据选择器"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="modalcontent">
           <div class="flex" >
              <div span="4">已选择数据：</div>
              
            <div  v-for="(item,index) in selectdatas" :key="index" :data-id="item.id">
            <Tag closable @on-close="removerow(item)">{{item.text}}</Tag>
            </div>
        </div>
         
        <div class="tables">
        <vxe-table
                resizable
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"
                :tree-config="{lazy: true,labelField: 'categoryTitle',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="600"
                :data="datas">
            <vxe-table-column field="id" title="编号"  :visible="false" show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           <vxe-table-column field="categoryTitle" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="icon" title="logo地址" width="100"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="categorySort" title="排序" width="60"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="pid" title="父级编号" :visible="false" show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="con" title="子分类数量"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
        <vxe-table-column title="操作" width="120" fixed="right" show-overflow> 
  
        <template #default="{ row }"> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'')" icon="vxe-icon--d-arrow-left" @click="funoptcilck(row,'selectorg')" >选择</vxe-button> 
           
        </template> 
        </vxe-table-column>
        </vxe-table>

        

        
        </div>
      </div>
    </Modal>
   
  </div>
</template>
<script>
import Datautil from "@/utils/datautil";
import Request from '@/utils/baseInterface.js';
export default {
  components: {
    
  },
  data() {
    return {
        datas:[],//组织机构数据
        isshow: false,
        selectdatas:[]
    };
  },
  created() {
     //加载部门表数据
      this.textfiled='categoryTitle';
      this.LoadDatas();
  },
  updated() {},
  props: {
    formitem: {
      type: Object,
      value: null,
    },
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    opendataselect(){
      //带图标的好像没办法禁用
      if(this.formitem.canedit){
        this.isshow = true;
      }
       
    },
    removerow(row){
      //1. 查找下标
        if(this.selectdatas){
            this.selectdatas.forEach((item, index) => {
                if(item.id==row.id){
                    this.selectdatas.splice(index,1);
                }
            });
        }
    },
    itemchange(e) {
      
      let d_ = {};
      let filedname = this.formitem.field;
      let val = this.formitem.defvalue; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
  
    LoadDatas(){
                 
          var parma={};
          parma.id=0;
        Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
            var datas=res.data.datas;
            datas.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                    item.childs=[];
                }
            });
            this.datas=datas;   
              
        });
    },
    loadchildData({row}){
        return new Promise(resolve => {
          var parma={};
          parma.id=row.id;
        Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
            var datas=res.data.datas;
            datas.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                    item.childs=[];
                }
            });
          
            resolve(datas)
          
        });
        
       
          });
    },
     showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
    },
      funoptcilck(row,funname){
          if(funname=='selectorg'){
             var i={};
              i.id=row.id;
              i.text=row[this.textfiled];
              this.selectdatas=[];
              this.selectdatas.push(i);
          }
      },
    ok() {
      if (!this.selectdatas || this.selectdatas.length < 1) {
        this.$Message.info({
          content: "您没有选择数据",
          duration: 2,
        });
      } else {

       if(this.selectdatas){
           var ids_=[];
           var texts_=[];
           this.selectdatas.forEach(item=>{
               ids_.push(item.id);
               texts_.push(item.text);
           });
           this.formitem.defvalue=ids_.join(",");
           this.formitem.deftext=texts_.join(",");
          let d_ = {};
          let filedname = this.formitem.field;
          let val = this.formitem.defvalue; //取值
          d_.filedname = filedname;
          d_.val = val; 
          this.$emit("formitemchange", d_);
       }
       
      }
    },
    cancel() {},
    //加载默认配置数据
    loaddefaultdata(){
      if(this.formitem.deftext){
        var selectdatas_=[];
        var deftext_=this.formitem.deftext.split(",");
        var defvalue_=this.formitem.defvalue.split(",");
        deftext_.forEach((item,index)=>{
          var tem_={};
          tem_.id=defvalue_[index];
          tem_.text=deftext_[index];
          selectdatas.push(tem_);
        });
        this.selectdatas=selectdatas_;
      }
    },
  },
};
</script>
<style   scoped>
.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}
</style>