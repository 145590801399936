<template>
  <div id="large-header" class="large-header">
    <canvas id="demo-canvas" style="display: none"></canvas>

    <Row
      style="
        display: flex;
        align-items: center;
        text-align: right;
        justify-content: center;
        border-radius: 35px;
        background: rgb(255, 255, 255);
        margin-right: 16vw;
        margin-left: 16vw;
        width: 68vw;
        height: 80vh;
      "
    >
      <i-col span="12" style="height: 80vh">
        <img
          src="~@/images/login_bj_03.png"
          style="width: 100%; height: 100%"
        />
      </i-col>
      <i-col span="12">
        <div style="padding: 3rem">
          <Form>
            <div class="logo_box" style="margin: 0 auto">
              <img
                src="~@//assets/images/logo_02.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div
              style="
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                padding: 10px;
              "
            >
              葫芦绿诊
            </div>
            <FormItem prop="phone">
              <Input type="text" v-model="phone" placeholder="用户名">
                <template #prepend>
                  <Icon type="ios-person-outline"></Icon>
                </template>
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input type="password" v-model="password" placeholder="密码">
                <template #prepend>
                  <Icon type="ios-lock-outline"></Icon>
                </template>
              </Input>
            </FormItem>
            <FormItem style="margin-top: 1.8rem">
              <Button type="primary" style="width: 100%" @click="accountlogin"
                >登录</Button
              >
            </FormItem>
          </Form>
        </div>
      </i-col>
      <Loadingv :msg="loadingmsg" />
    </Row>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";

import store from "@/store";

import Request from "@/utils/baseInterface.js";
import { formatterAsycMenu } from "@/libs/util.js";
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
} from "@/utils/localStorage.js";
import Loadingv from "@/components/loading/Loading.vue";
export default {
  name: "login",
  data() {
    return {
      value1: 1,
      loading: false,
      code: null,
      phone: "",
      password: "",
      useAccount: true,
      redirectUrl: null,
      loadingmsg: {
        title: "请等待,正在登录中..",
        ismask: false,
        show: false,
      },
    };
  },
  components: {
    wxlogin,
    Loadingv,
  },
  mounted() {
    this.bgInit();
  },
  created() {
    //setlocalStorage("apitoken", "1234");

    this.redirectUrl = this.$route.query.redirectUrl;

    //如果从其它页面过来
    if (this.redirectUrl) {
    }
    console.log(this.redirectUrl);

    // this.code = this.$route.query.code;
    // this.scanlogin();
    //  window.console.log("code:",this.$route.query.code);
  },
  props: {
    msg: String,
  },
  methods: {
    //扫码登录
    scanlogin() {
      var that = this;
      if (this.code) {
        var parma = {};
        parma.code = this.code;
        Request({
          url: "/empapi/sys/tbsysemploy/pcLogin",
          method: "post",
          data: parma,
        }).then((res) => {
          if (res.code == 0) {
            window.console.log(res);
            setlocalStorage("apitoken", res.data.data.token);
            setlocalStorage("userinfo", res.data.data.user);
            this.loadMenu();
            //跳转页面
            that.$router.push({
              path: "/",
            });
          }
        });
      }
    },

    //账号密码登录
    accountlogin() {
      var that = this;
      var parma = {};
      if (!this.phone) {
        this.$Message.warning("手机号不能为空");
        return;
      } else if (!this.password) {
        this.$Message.warning("密码不能为空");
        return;
      } else if (this.password.length < 6) {
        this.$Message.warning("密码长度不能小于6位数！");
        return;
      }
      parma.phone = this.phone;
      parma.password = this.password;

      that.loadingmsg.show = true;
      Request({
        url: "/empapi/sys/tbsysemploy/loginAccount",
        method: "post",
        data: parma,
      })
        .then((res) => {
          that.loadingmsg.show = false;
          if (res.code == 0) {
            window.console.log(res);
            var userinfo = res.data.data.user;
            if (!userinfo.headimg) {
              userinfo.headimg = "/upload/common/20240309/113156.jpg";
            }
            this.$Message.info("登录成功！");


            setlocalStorage("apitoken", res.data.data.token);
            setlocalStorage("userinfo", userinfo);
            setlocalStorage("userdpt", res.data.data.dpt);
            setlocalStorage("roletype", res.data.data.dpt.createName);
      




          store.commit('setshowleftmenu',true);
          this.collapsed =  false;



            this.loadMenu();
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
              if (this.redirectUrl) {
                var url = this.redirectUrl + "?code=" + res.data.data.token;
                window.location.replace(url);
              } else {
                this.$router.push({
                  path: "/",
                });
              }
            }, 2000);
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((error) => {
          that.loadingmsg.show = false;
        });
    },
    loadMenu() {
      var parma = {};
      Request({
        url: "/empapi/sys/tbsysemploy/getpcemploymenudatas",
        method: "post",
        data: parma,
      }).then((res) => {
        var marr = formatterAsycMenu(res.data.datas);
        setlocalStorage("menus", marr);
        setlocalStorage("menusgroup", res.data.menusgroup);
        this.menuList = marr;
        this.$store.commit("InitMenus", marr);
      });
    },
    bgInit() {
      var width,
        height,
        largeHeader,
        canvas,
        ctx,
        circles,
        target,
        animateHeader = true;
      // sta
      var lastTime = 0;
      var vendors = ["ms", "moz", "webkit", "o"];
      for (
        var x = 0;
        x < vendors.length && !window.requestAnimationFrame;
        ++x
      ) {
        window.requestAnimationFrame =
          window[vendors[x] + "RequestAnimationFrame"];
        window.cancelAnimationFrame =
          window[vendors[x] + "CancelAnimationFrame"] ||
          window[vendors[x] + "CancelRequestAnimationFrame"];
      }

      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (callback, element) {
          var currTime = new Date().getTime();
          var timeToCall = Math.max(0, 16 - (currTime - lastTime));
          var id = window.setTimeout(function () {
            callback(currTime + timeToCall);
          }, timeToCall);
          lastTime = currTime + timeToCall;
          return id;
        };
      }
      if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (id) {
          clearTimeout(id);
        };
      }
      // end

      // Main
      initHeader();
      addListeners();

      function initHeader() {
        width = window.innerWidth;
        height = window.innerHeight;
        target = { x: 0, y: height };

        largeHeader = document.getElementById("large-header");
        largeHeader.style.height = height + "px";

        canvas = document.getElementById("demo-canvas");
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");

        // create particles
        circles = [];
        for (var x = 0; x < width * 0.5; x++) {
          var c = new Circle();
          circles.push(c);
        }
        animate();
      }

      // Event handling
      function addListeners() {
        window.addEventListener("scroll", scrollCheck);
        window.addEventListener("resize", resize);
      }

      function scrollCheck() {
        if (document.body.scrollTop > height) animateHeader = false;
        else animateHeader = true;
      }

      function resize() {
        width = window.innerWidth;
        height = window.innerHeight;
        largeHeader.style.height = height + "px";
        canvas.width = width;
        canvas.height = height;
      }

      function animate() {
        if (animateHeader) {
          ctx.clearRect(0, 0, width, height);
          for (var i in circles) {
            circles[i].draw();
          }
        }
        requestAnimationFrame(animate);
      }

      // Canvas manipulation
      function Circle() {
        var _this = this;

        // constructor
        (function () {
          _this.pos = {};
          init();
          // console.log(_this);
        })();

        function init() {
          _this.pos.x = Math.random() * width;
          _this.pos.y = height + Math.random() * 100;
          _this.alpha = 0.1 + Math.random() * 0.3;
          _this.scale = 0.1 + Math.random() * 0.3;
          _this.velocity = Math.random();
        }

        this.draw = function () {
          if (_this.alpha <= 0) {
            init();
          }
          _this.pos.y -= _this.velocity;
          _this.alpha -= 0.0005;
          ctx.beginPath();
          ctx.arc(
            _this.pos.x,
            _this.pos.y,
            _this.scale * 10,
            0,
            2 * Math.PI,
            false
          );
          ctx.fillStyle = "rgba(255,255,255," + _this.alpha + ")";
          ctx.fill();
        };
      }
    },
  },
};
</script>

<style scoped>
.qrcode-box {
  position: fixed;
  top: 52%;
  left: 75%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 4px;
  justify-items: center;
}

.logo_box {
  width: 120px;
  height: 120px;

  margin-left: 0px;
  margin-bottom: 20px;
}

.large-header {
  /* position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1; */
}

.large-header {
  /* width: 98vw;
  min-height: 95vh;
  margin-left: auto;
  margin-right: auto;
  background: url("../images/bg_04.png") no-repeat;
  background-size: 100% 100%;
  background-position: center 0; */
  /* background-position: center bottom; */
  min-height: 100vh;
  background-color: #00232f;
  background-image: linear-gradient(transparent 8px, #073f42 1px),
    linear-gradient(to right, transparent 8px, #073f42 1px);
  background-size: 10px 10px;
  background-repeat: repeat;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.bg_box {
  width: calc(100vw - 340px);
  height: calc(100vh - 140px);
  margin-left: 174px;
  margin-right: 174px;
  margin-top: 69px;
  margin-bottom: 69px;
  background: url("../images/login_bj_03.png") no-repeat;
  position: fixed;
  background-color: white;
  border-radius: 15px;
  top: 0;
}
.login-div {
  padding: 30px;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  width: 410px;
  height: 240px;
}
.login-div .logo {
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
