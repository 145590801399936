<template>
   <Row style="display:flex;align-items: stretch;">
        <i-col :span="common.getWidth(viewGrid,'orglist')" v-if="common.showView(viewGrid,'orglist')" style="padding-right:10px;background: #fff;margin-right:4px;">
        <div style="background: #fff;margin-right: 10px;">
                <OrgTreeView ref="orgtree" exp="orgType#eq#2" menuTitle="角色管理" :hasSubActive="common.getSubViewCount(viewGrid,'orglist')"
                    @onOrgSelected="onOrgSelected" @colseSubView="colseSubView('orglist')"/>
        </div>
    </i-col>

      <i-col :span="common.getWidth(viewGrid,'rolelist')" v-if="common.showView(viewGrid,'rolelist')" style="overflow: hidden; padding-right:10px;background: #fff;margin-right:4px;">
         

            <div class="gridtoobar">
                    <div class="gridbtn">
                        <Button type="primary"  size="small" icon="md-add" @click="openaddview">新增角色</Button>

                    </div>
                    <div class="gridbar">
                        <Button-group>
                            <Button type="dashed" size="small" icon="md-refresh" @click="LoadDatas" ></Button>
                           
                        </Button-group>
                    </div>
            </div>
        
        
         <!-- 表格组件 -->
            <simpletable
              :datagrid="gridedata"
              @girdpagechanged="girdpagechanged"
              @onOptionclick="onOptionclick"
            />
            
        </i-col>


    <i-col :span="common.getWidth(viewGrid,'menulist')" v-if="common.showView(viewGrid,'menulist')" style="overflow: hidden; padding-right:10px;background: #fff;margin-right:4px;">
             
   
             <div class="gridtoobar">
                    <div class="gridbtn">
                       
                    </div>
                    <div class="gridbar">
                        <Button-group>
                            <Button type="dashed" size="small" icon="md-refresh" @click="LoadMenuDatas" ></Button>
                           
                        </Button-group>
                    </div>
            </div>
            
         <!-- 表格组件 -->
            <simpletable
              :datagrid="gridemenudata"
              @girdpagechanged="girdpagechanged"
              @onOptionclick="onOptionclick"
            />
            
        </i-col>
        <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
    </Row>
   
</template>
<script>
import Request from '@/utils/baseInterface.js';
import simpletable from "@/components/wxe/simpletable";
import smartForm from "@/components/wxe/smartForm"; //表单
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
import Datautil from "@/utils/datautil";
import OrgTreeView from "@/components/wxe/OrgTreeView";
import {
 getDictItemText
} from "@/utils/localStorage.js";
    export default {
        components: {
            simpletable,smartForm,quickqueryform,OrgTreeView
        },
        data () {
            return {
                itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:false,isshowadd:false,isshowdetail:true,isshowedit:false,correct:true,defvalue:null,pkcol:true,canedit:false},
                    {field:"rol_type",title:"角色类型",ctype:"dropselect",isshowlist:false,correct:true,defvalue:3,canedit:true,datatype:"*",dictCode:"rol_type",isquery:true},
                    {title:"角色名称",field:"rolename",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:'',canedit:true,datatype:"*",isquery:true},
                    {title:"结构名称",field:"dptname",ctype:"simpletext",isshowlist:false,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:'',canedit:false,datatype:"*",isquery:true},
                     {title:"角色编码",field:"rolecode",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:false,isshowedit:false,correct:true,defvalue:null,canedit:true,datatype:"*",isquery:true},
                ],
                menuitemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:false,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
                    {title:"菜单名称",field:"mname",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*",isquery:true},
                    {title:"关联id",field:"rmid",ctype:"simpletext",isshowlist:false,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*",isquery:true}
                ],
                gridedata:{tid:'rolelist', tableColumn:[], datas:[], total:0,optwidth:230,funopt:[
                     {funname:'edit',icon:'vxe-icon--edit-outline',title:'编辑'},
                {funname:'del',icon:'vxe-icon--close',title:'删除',buttoncolor:'danger'},
                    {funname:'setmenu',icon:'vxe-icon--menu',title:'设置菜单',buttoncolor:'success'}] },
                gridemenudata:{tid:'menulist', tableColumn:[], datas:[], total:0,optwidth:100,funopt:[
                    {funname:'unsignmenu',icon:'vxe-icon--close',title:'取消授权',exp:"rmid#ISNOTNULL",buttoncolor:'danger'},
                {funname:'signmenu',icon:'vxe-icon--check',title:'授权',exp:"rmid#ISNULL",buttoncolor:'success'}] },
                quickquery:{formitems:[]},
                formitems:[],
                formconfig: { title: "编辑", modal1: false },
                selectedrow:null,//选中数据
                queryparma:{page:1,rows:20},//查询参数
                menuqueryparma:{page:1,rows:20},//查询参数
                rid:null,//角色编号
                orgName:"未选",
                orgid:null,// 
                selectedOrg:null,//选择机构数据
                selectedrole:null,
                viewGrid:[
                    {viewName:"orglist",isShow:true,proportion:1,index:1,hasSubActive:true},
                    {viewName:"rolelist",isShow:true,proportion:2,index:2,hasSubActive:true},
                    {viewName:"menulist",isShow:true,proportion:1,index:3,hasSubActive:false}
                ],

  


            }
        },
        created(){
    
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.gridedata.tableColumn=columndata;
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
      
           
            var columnmenudata=  Datautil.$getGridColumn(this.menuitemdatas);
            this.gridemenudata.tableColumn=columnmenudata;
           
           
        
        },
                mounted(){
              this.$refs.orgtree.LoadDataspid('');
        },
        methods:{
         LoadDatas(){
             Request({url:"/empapi/sys/roledpt/datalist",method:"post",data:this.queryparma}).then(res=>{
                this.gridedata.datas=Datautil.$formattrgriddatas(res.data.datas,this.gridedata.tableColumn);
                this.gridedata.total=res.data.total;
                });
            },
             openaddview(){
                if(this.selectedOrg){
                    this.itemdatas[3]["defvalue"]=this.orgName
                    var columndataadd=  Datautil.$getAddFormItems(this.itemdatas);
                    this.formitems=columndataadd;
                    this.formconfig.option="add";
                    this.formconfig.title="新增",
                    this.formconfig.modal1=true;
                 }else{
                    this.$Message.error("未选择机构数据");
                }

            },
            openEditview(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择数据，请选择数据后再点击编辑按钮');
                }else{
                    this.selectedrole=this.selectedrow
                    this.itemdatas[1]["defvalue"]=this.selectedrow['rolecode']
                    this.itemdatas[3]["defvalue"]=this.orgName
                    var columndataedit=  Datautil.$getEditFormItems(this.itemdatas);
                    columndataedit= Datautil.$loadDetailData(columndataedit,this.selectedrow);
                    this.formconfig.option="edit";
                    this.formitems=columndataedit;
                    this.formconfig.title="编辑",
                    this.formconfig.modal1=true;
                }
                
            },
            dodelete(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择待删除的数据');
                }else{
                    this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=this.selectedrow.id;
                             Request({url:"/empapi/sys/roledpt/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            },
            openDetailview(){
                 var columndatadetail=  Datautil.$getDetailFormItems(this.itemdatas);
                this.formitems=columndatadetail;
                this.formconfig.title="详情",
                this.formconfig.option="detaill";
                this.formconfig.modal1=true;
            },
            onOptionclick(data){
                if(data.tid=='rolelist'){
                     this.selectedrow=data.selected;
                     if(data.funname=="setmenu"){
                        this.menuqueryparma.id=data.selected.id;
                        this.LoadMenuDatas();
                     }else if(data.funname=="edit"){
                         this.openEditview();
                     }else if(data.funname=="del"){
                         this.dodelete();
                     }

                }else if(data.tid=='menulist'){
                    if(data.funname=="signmenu"){
                        var parma={};
                        parma.rid=this.menuqueryparma.id;
                        parma.mid=data.selected.id;
                        Request({url:"/empapi/sys/role/signrolemenu",method:"post",data:parma}).then(res=>{
                           if(res.code==0){
                               this.$Message.info('操作成功');
                           }else{
                               this.$Message.info('操作失败',res.message);
                           }
                            this.LoadMenuDatas();
                        });
                    }else if(data.funname=="unsignmenu"){
                        var parma={};
                       
                        parma.id=data.selected.rmid;
                        Request({url:"/empapi/sys/role/unsignrolemenu",method:"post",data:parma}).then(res=>{
                             if(res.code==0){
                               this.$Message.info('操作成功');
                           }else{
                               this.$Message.info('操作失败',res.message);
                           }
                            this.LoadMenuDatas();
                        });
                    }
                }
                window.console.log(data);
            },
            onsubmitquerydata(data){
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.queryparma=d;
                this.gridedata.cpage=1;//表格从第一页开始
                this.LoadDatas();
               
            },
            girdpagechanged(data){
                if(data.tid=="rolelist"){
                    this.queryparma.page=data.cpage;
                    this.LoadDatas();
                }else if(data.tid=='menulist') {
                    this.menuqueryparma.page=data.cpage;
                    this.LoadMenuDatas();
                }
            },
            onsubmit(data){
                if(data.option=="add"){
                    if( data.datas['rol_type']=="3"){
                        this.$Message.info('请选择角色类型！');
                        return;
                    }
                let  postdb={};
                 postdb['rolename']=data.datas['rolename'];
                 postdb['rolecode']=data.datas['rol_type'];
                 postdb['dptname']=this.orgName;
                 postdb['did']=this.orgid;
                Request({url:"/empapi/sys/roledpt/doAdd",method:"post",data:postdb}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                                        this.formconfig.modal1=false;
                                });
                 
                }else if(data.option=="edit"){
                     if( data.datas['rol_type']=="3"){
                        this.$Message.info('请选择角色类型！');
                        return;
                    }
                    let  postdb={};
                    postdb['rolename']=data.datas['rolename'];
                    postdb['rolecode']=data.datas['rol_type'];
                    postdb['dptname']=data.datas['dptname'];
                    postdb['id']=this.selectedrole['id'];
                    Request({url:"/empapi/sys/roledpt/doUpdate",method:"post",data:postdb}).then(res=>{
                    if(res.success){
                            this.$Message.info('操作成功');
                    }else{
                            this.$Message.error(res.message);
                    }
                        this.LoadDatas();
                        this.formconfig.modal1=false;
                });
                }
                
            },
            /**
             * 分页加载菜单数据
             */
            LoadMenuDatas(){
                console.log("menuqueryparma:",this.menuqueryparma)
                 Request({url:"/empapi/sys/role/signedsunmenudatalist",method:"post",data:this.menuqueryparma}).then(res=>{
                this.gridemenudata.datas=res.data.datas;
                this.gridemenudata.total=res.data.total;
                });
            },
            onOrgSelected(data){
                this.selectedOrg=data.selected;
                this.queryparma.orgId=data.selected.id;
                this.orgName=this.selectedOrg.departname;
                this.orgid=this.selectedOrg.id;
                this.LoadDatas();
                this.viewGrid=this.common.activeView(this.viewGrid,"rolelist");
            },
        }
    }

</script>