<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
import echarts from 'echarts'

export default {
  name: 'ChartLineBar',
  props: {
    itemdata: {
        type:Object,
        default:null
    },
    text: String,
  },
  data () {
    return {
      dom: null
    }
  },
  methods: {
    resize () {
      this.dom.resize()
    }
  },
  mounted () {
    this.$nextTick(() => {
      
      var opt_={};
      opt_.title={ text:this.itemdata.title?this.itemdata.title: this.text};
      opt_.tooltip={ trigger: 'axis'};
      opt_.grid={ left: '3%', right: '4%', bottom: '3%', containLabel: true};
      opt_.legend={ data: this.itemdata.legenddatas?this.itemdata.legenddatas:[]};
      opt_.xAxis={ type: 'category',boundaryGap: false,data: this.itemdata.xdatas?this.itemdata.xdatas:[]};
      opt_.yAxis={ type: 'value'};
      opt_.series=this.itemdata.datas?this.itemdata.datas:[];
      // console.log(this.itemdata)
      this.dom = echarts.init(this.$refs.dom)
      this.dom.setOption(opt_)
      //on(window, 'resize', this.resize)
    })
  },
  beforeDestroy () {
    //off(window, 'resize', this.resize)
  }
}
</script>
