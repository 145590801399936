<template>
  <div>
      <div class="gridtoobar">
                    <div class="gridbtn">
                        
                    </div>
                    <div class="gridbar">
                        <Button-group>
                            <Button type="dashed" size="small" icon="md-refresh" @click="LoadDatas" ></Button>
                            <Button type="dashed" size="small" icon="ios-arrow-round-forward " v-if="hasSubActive" @click="colseSubView" ></Button>
                             
                        </Button-group>
                    </div>
            </div>
                <vxe-table
                        resizable
                        keep-source
                        border="full"
                        size="mini"
                         highlight-current-row
                        ref="xTree"
                        row-id="id"
                        :tree-config="{lazy: true,labelField: 'departname',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
                    max-height="600"
                        :data="datas">
                    <vxe-table-column field="id" title="编号"  :visible="false" show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                    <vxe-table-column field="departname" title="机构名称" width="40%" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                    <vxe-table-column field="orgcode" title="机构编码" width="20%"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                    <vxe-table-column field="orgType" title="机构类型" width="20%" :formatter="formatterOrgType"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                    <vxe-table-column field="parentdepartid" title="父级编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                    <vxe-table-column field="con" title="子菜单" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                <vxe-table-column title="操作" width="20%" fixed="right" show-overflow> 
        
                <template #default="{ row }"> 
                    <vxe-button type="text" status="primary" v-if="showoptionbutton(row,exp)" icon="vxe-icon--menu" @click="funoptcilck(row)" >{{menuTitle}}</vxe-button> 
                </template> 
                </vxe-table-column>
                </vxe-table>
  </div>
</template>

<script>
import Request from '@/utils/baseInterface.js';
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
} from "@/utils/localStorage.js";
import {
 getDictItemText
} from "@/utils/localStorage.js";
export default {
    props:{
        menuTitle:{
            type:String,
            default:"查看列表"
        },
        exp:{
             type:String,
            default:""
        },
        hasSubActive:{
            type:Boolean,
            default:false
        },
         viewName:{
            type:String,
            default:'orglist'
        }
        
    },
    data(){
        return{
            datas:[],
             pid:'1a5ad7a4681f478eb0d0c5654dfc340b',
        }
    },
    created(){
        //this.LoadDatas();
    },
    methods:{
        LoadDatas(){
                var parma={};
                parma.parentdepartid=this.pid;
                Request({url:"/empapi/sys/tbsysdepart/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                   this.datas=datas;   
                window.console.log(res);        
                });
            },
        LoadDataspid(pid){
                var parma={};
                parma.parentdepartid=pid;
                Request({url:"/empapi/sys/tbsysdepart/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                   this.datas=datas;   
                window.console.log(res);        
                });
            },

            formatterOrgType({ cellValue }){
                return getDictItemText("sys_orgType",cellValue)
            },
            loadchildData({row}){
                setlocalStorage("mdepartname", row.departname);
                return new Promise(resolve => {
                 var parma={};
                 parma.parentdepartid=row.id;
                 console.log(parma);
                Request({url:"/empapi/sys/tbsysdepart/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                  
                   resolve(datas)
                window.console.log(res);        
                });
                
                console.log(row);
                 });
            },
            funoptcilck(row){
                 let data_ = {};
                 data_.selected = row;
                this.$emit("onOrgSelected", data_);
            },
            colseSubView(){
                let data_ = {};
               
                this.$emit("colseSubView", data_);
            },
            showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
            }
    }
}
</script>

<style>

</style>