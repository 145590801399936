/**
 *   @description 接口请求拦截
 *   @author Mao Xiang Dong
 *   @date 2020/5/14
 */
import axios from 'axios'
import Qs from 'qs'

// 创建一个axios实例
const service = axios.create({
    //baseURL: "/",
    //  baseURL: "https://xmyx.ynyzmx.com/xmyx/",
    //  baseURL :'http://192.168.1.125:10008',
    baseURL: 'http://192.168.0.100:10011',
    timeout: 30000, // 请求超时时间 默认30秒
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'content-type': 'application/x-www-form-urlencoded'
    }
})
// 请求拦截器
service.interceptors.request.use(config => {
    if (config.data) {
        config.data = Qs.stringify(config.data)
    }
    return config
},
    error => {
        // debug
        window.console.log('接口异常', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // window.console.log(response.status)
        if (response.status !== 200) {
            return Promise.reject(new Error(response.msg || 'Error'))
        } else {
            return response.data
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service
