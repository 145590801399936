export const getlocalStorage = key => {
    return window.localStorage.getItem(key)
  }
  
  export const setlocalStorage = (key, value) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, value)
    
  }
  
  export const removelocalStorage = key => {
    window.localStorage.removeItem(key)
  }

  export const removeAllLocalStorage = key => {
    window.localStorage.clear()
  }

  /*
     * 根据字典编码和字典值格式化标题
  */
  export const getDictItemText= (dictCode,itemValue) =>{
    
    if(!itemValue){
      return '';
    }
    itemValue=itemValue+"";
    var dicts_= window.localStorage.getItem("dictdats");
    if(dicts_){
      var dictArr_=JSON.parse(dicts_);
      var dictStr=  dictArr_.find(i=>i.dictCode==dictCode);
      if(dictStr&&dictStr.opt){
         
            var arr=dictStr.opt.split(",");
            if(arr&&arr.length>0){
              var options=[];
                arr.forEach(element => {
                  var arr1  =element.split("|");
                  if(itemValue.indexOf(arr1[0]+"")!=-1){
                    options.push(arr1[1]);
                  }
                  
                });
                return options.join(",");
            }else{
              return itemValue;
            }
      }else{
        return itemValue;
      }
    }else{
        return itemValue;
    }
  }
  /**
   * 通过字典编码获取字典选项
   * @param {*} dictCode 
   */
  export const getDictOption = dictCode =>{
    var dicts_= window.localStorage.getItem("dictdats");
    if(dicts_){
      var dictArr_=JSON.parse(dicts_);
      var dictStr=  dictArr_.find(i=>i.dictCode==dictCode);
      if(dictStr&&dictStr.opt){
         
            var arr=dictStr.opt.split(",");
            if(arr&&arr.length>0){
              var options=[];
                arr.forEach(element => {
                  var arr1  =element.split("|");
                  var opt={};
                  opt.id=arr1[0];
                  opt.text=arr1[1];
                  options.push(opt);
                });
                return options;
            }else{
              return [];
            }
      }else{
        return [];
      }
    }else{
        return [];
    }
   
  }
  