<template>
  <div class="formitembox-mm">
    <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <input type="hidden" v-model="formitem.defvalue" />
    <Input
      icon="md-funnel"
      @on-blur="itemchange"
      @on-enter="opendataselect"
      @on-click="opendataselect"
      v-model="formitem.deftext"
       :disabled="formitem.canedit == true ? false : true"
      style="width:92%;height:29px"
    />
     </FormItem>
    <Modal
      v-model="isshow"
      width="55vw"
      title="渠道优惠劵数据选择器"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="modalcontent">
           <div class="flex" >
              <div span="4">已选择数据：</div>
              
            <div  v-for="(item,index) in selectdatas" :key="index" :data-id="item.id">
            <Tag closable @on-close="removerow(item)">{{item.text}}</Tag>
            </div>
        </div>
         
        <div class="tables">
        <vxe-table
        border="full"
        size="mini"
        align="center"
       
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        resizable
        stripe
        class="Tables"
          :data="datagrid.datas"
        >
          <vxe-table-column  type="seq" width="60" ></vxe-table-column>
         
          <vxe-table-column
            v-for="(item,index) in datagrid.tableColumn"
            :key="index"
            :field="item.field"
            :title="item.title"
            :width="item.colwidth?item.colwidth:120"
            show-header-overflow
            show-overflow="title"
            show-footer-overflow
          ></vxe-table-column>
          <vxe-table-column title="操作" width="150" show-overflow align="center">
             <template #default="{ row }"> 
                <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'')" icon="vxe-icon--d-arrow-left" @click="funoptcilck(row,'selectdeduction')" >选择</vxe-button> 
              </template>
          </vxe-table-column>

        </vxe-table>

        

         <vxe-pager
            :current-page="datagrid.cpage?datagrid.cpage:0"
            :page-size="datagrid.rows?datagrid.rows:10"
            :total="datagrid.total?datagrid.total:0"
           
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']"
            @page-change="pagechanged"
        ></vxe-pager>
        </div>
      </div>
    </Modal>
   
  </div>
</template>
<script>
import Datautil from "@/utils/datautil";
import Request from '@/utils/baseInterface.js';
export default {
  components: {
    
  },
  data() {
    return {
     
        isshow: false,
        datagrid: {tableColumn:[], datas:[], total:0,optwidth:240},
        quickquery:{formitems:[]},
        queryparma:{page:1,rows:10},//查询参数
     
       itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,caneidt:false},
                    {title:"名称",field:"name",ctype:"simpletext",isshowlist:true,isquery:false},
                    {title:"金额",field:"amount",ctype:"simpletext",isshowlist:true,defvalue:null,isquery:false},
                    {title:"有效天数",field:"preDays",ctype:"simpletext",isshowlist:false,defvalue:null,isquery:false},
                    {title:"限领数量",field:"limitNum",ctype:"simpletext",isshowlist:false,defvalue:null,isquery:false},
                    {title:"库存",field:"stock",ctype:"simpletext",isshowlist:false,defvalue:null,isquery:false},
                    {isquery: false,title: "是否门店通用",isshowlist: true,field: "isOutletCurrency",ctype: "dropselect",canedit: true,options: [{ id: "1", text: "是" },{ id: "2", text: "否" },],pkcol: false,},
                {isquery: false,title: "状态",isshowlist: true,field: "isEnable",ctype: "dropselect",options: [{ id: "1", text: "启用 " },{ id: "2", text: "停用" }, ],pkcol: false,},
                {isquery: false,title: "是否服务通用",isshowlist: true,field: "isServiceCurrency",ctype: "dropselect",options: [{ id: "1", text: "是" },{ id: "2", text: "否" },],pkcol: false,},
                {isquery: false,title: "领取渠道",isshowlist: true,field: "getWay",ctype: "dropselect",options: [{ id: "1", text: "手动领取" },{ id: "2", text: "推荐用户" }, { id: "3", text: "新用户注册" },{ id: "4", text: "活动" },],pkcol: false,},
                ],
                selectdatas:[]
    };
  },
  created() {
     //加载用户表数据
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.datagrid.tableColumn=columndata;
            this.model=1;//设置单选
            this.textfiled='name';
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            this.LoadDatas();
  },
  updated() {},
  props: {
    formitem: {
      type: Object,
      value: null,
    },
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    opendataselect(){
        this.isshow = true;
    },
    itemchange(e) {
      
      let d_ = {};
      let filedname = this.formitem.field;
      let val = this.formitem.defvalue; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
  
    LoadDatas(){
        Request({url:"/employapi/channeldection/deductiondatas",method:"post",data:this.queryparma}).then(res=>{
    
        this.datagrid.datas=Datautil.$formattrgriddatas(res.datas,this.datagrid.tableColumn);
        this.datagrid.total=res.total;
        });
    },
     selectrow(row){
      var i={};
        i.id=row.id;
        i.text=row[this.textfiled];
        // if(this.selecttable.model=="1"){
            this.selectdatas=[];
            this.selectdatas.push(i);
        // }else if(this.selecttable.model=="2"){
            // var t_= this.selectdatas.filter( t=> t.id==row.id);
            // if(t_.length==0){
            //     this.selectdatas.push(i);
            // }
        //}
    },
     showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
    },
     funoptcilck(row,funname){
          if(funname=='selectdeduction'){
             var i={};
              i.id=row.id;
              i.text=row[this.textfiled];
              this.selectdatas=[];
              this.selectdatas.push(i);
          }
      },
    pagechanged({ currentPage }){
      
        this.queryparma.page=currentPage;
        this.LoadDatas();
        
    },
    ok() {
      if (!this.selectdatas || this.selectdatas.length < 1) {
        this.$Message.info({
          content: "您没有选择数据",
          duration: 2,
        });
      } else {

       if(this.selectdatas){
           var ids_=[];
           var texts_=[];
           this.selectdatas.forEach(item=>{
               ids_.push(item.id);
               texts_.push(item.text);
           });
           this.formitem.defvalue=ids_.join(",");
           this.formitem.deftext=texts_.join(",");
          
       }
       
      }
    },
    cancel() {},
    //加载默认配置数据
    loaddefaultdata(){
      if(this.formitem.deftext){
        var selectdatas_=[];
        var deftext_=this.formitem.deftext.split(",");
        var defvalue_=this.formitem.defvalue.split(",");
        deftext_.forEach((item,index)=>{
          var tem_={};
          tem_.id=defvalue_[index];
          tem_.text=deftext_[index];
          selectdatas.push(tem_);
        });
        this.selectdatas=selectdatas_;
      }
    },
  },
};
</script>
<style   scoped>
.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}
</style>