<template>
  <Card :shadow="infoitem.shadow" class="info-card-wrapper" :padding="0">
      <div style="display: flex;padding: 5px;">
          <div class="cen" :style="{background: infoitem.color, width: leftWidth}">
            <Icon class="icon" :type="infoitem.icon" :size="infoitem.iconSize?infoitem.iconSize:20" color="#fff" />
          </div>
          
          <div class="info-base" :style="{width: rightWidth}">
               <p style="font-size: -webkit-xxx-large;">{{infoitem.count}}</p>
               <p>{{infoitem.title}}</p>
          </div>
      </div>
  </Card>
</template>

<script>

export default {
  name: 'InforCard',
  components: {
    
  },
  props: {
    infoitem:{
        type: Object,
        default: {icon:'',iconSize:20,shadow:true,color:'#2d8cf0',left:36,title:'标题',count:0}
    }
  },
  computed: {
    leftWidth () {
      return `36%`
    },
    rightWidth () {
      return `64%`
    }
  }
}
</script>

<style >
.cen{
    display: flex;justify-content: center;align-items: center;
}
.info-base{
    font-size:larger;
}

</style>
