<template>

    <div>
   sfdsdfsdf
    </div>

</template>

<script>
import Request from '@/utils/baseInterface.js';

export default {
    data() {
      return {
        codes:'',//code
      }
    },
    name: 'register',
    components: {
    
    },
    created(){
        window.console.log("code:",this.$route.query.code);
        let parma={code:this.$route.query.code};
        // parma.code=this.$route.query.code;
        // parma.nickname="123";
        Request({url:"/employapi/tbManageEmployController/pclogin",method:"post",data:parma}).then(res=>{
          window.console.log(res);
        });
    }
  }
</script>