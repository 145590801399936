import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		hasInit:false,
		showleftmenu:true,	
		menus: [],//
	},
	mutations: {
		InitMenus(state, data) {
			state.menus = data
		},
		ResetHasInit(state, data){
			state.hasInit = data
		},
		setshowleftmenu(state, data){
			state.showleftmenu = data
		}
	
	},
	actions: {
		asynInitRouter(context){
			context.commit('InitMenus');
		}
	},
	modules: {},
})
