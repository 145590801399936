<template>
  <div ref="dom" class="charts chart-pie"></div>
</template>

<script>
import echarts from 'echarts'

export default {
  name: 'ChartPie',
  props: {
   itemdata: {
        type:Object,
        default:[]
    },
    text: String
  },
  data () {
    return {
      dom: null
    }
  },
  methods: {
    resize () {
      this.dom.resize()
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(this.itemdata)
      var opt_={};
      opt_.tooltip={ trigger: 'item'};
      opt_.title={ text:this.itemdata.title?this.itemdata.title: this.text, left: 'center'};
      opt_.legend={ orient: 'vertical',left: 'left'};
      opt_.series=[{type: 'pie',radius: '50%',data: this.itemdata.datas?this.itemdata.datas:[],}];
      this.dom = echarts.init(this.$refs.dom)
      this.dom.setOption(opt_)
      //on(window, 'resize', this.resize)
    })
  },
  beforeDestroy () {
    //off(window, 'resize', this.resize)
  }
}
</script>
