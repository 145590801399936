<template>
  <div id="app">
      <router-view v-if="isRouterAlive"/>
   
  </div>
</template>

<script>

 export default {
  name: 'App',
  provide(){
    return {
        reload:this.reload
    } 
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
 
    }
  }
}
</script>


<style lang="less">
.size{
  width: 100%;
  height: 100%;
}
html,body{
  .size;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#app {
  .size;
}

.qick-modalcontent-form
{
    overflow:hidden;
}
.gridtoobar
{
  overflow:hidden;
}
.overhide{overflow:hidden;}
 
.ivu-row 
{
height:100%!important; 

}
.acitem
{
  background:#2d8cf0!important;
}


.vxe-table--main-wrapper
{
	height:66vh;
}

.iteminpu
{
   width: 200px !important;
    margin-right: 10px;
}
</style>



