<template>
  
         <Row type='flex'>
             <i-col span='14' style="padding-right:10px;">
                 <div class="toolbarbox">
                <Button type="primary" class="gridbtn" size="small" icon="md-add" @click="openaddview" >新增</Button>
                <Button type="primary" class="gridbtn" size="small"   icon="md-create" @click="openEditview">编辑</Button>
                <Button type="primary" class="gridbtn" size="small"   icon="md-eye" @click="openDetailview">详情</Button>
                <Button type="primary" class="gridbtn" size="small"   icon="md-close" @click="dodelete">删除</Button>
                 <Button type="primary" class="gridbtn" size="small"   icon="md-hammer" @click="createcode">生成代码</Button>
                </div>
        
            <quickqueryform :quickquery="quickquery"  @onsubmitquerydata="onsubmitquerydata"/>
            <!-- 表格组件 -->
                <simpletable
                :datagrid="gridedata"
                @girdpagechanged="girdpagechanged"
                @onOptionclick="onOptionclick"
                @girdcellclick="girdcellclick"
                />
                
                <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
        
             </i-col>
              <i-col span='10'>
                 <div class="toolbarbox">
               
               
                </div>
        
            <quickqueryform :quickquery="quickquery"  @onsubmitquerydata="onsubmitquerydata"/>
            <!-- 表格组件 -->
                <simpletable
                :datagrid="fieldgridedata"
                @girdpagechanged="girdpagechanged"
               @onOptionclick="onOptionclick"
                />
                <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
        
             </i-col>
         </Row>
       
</template>
<script>
import Request from '@/views/plat/smart.js';
import simpletable from "@/components/wxe/simpletable";
import smartForm from "@/components/wxe/smartForm"; //表单
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
import Datautil from "@/utils/datautil";

    export default {
        components: {
            simpletable,
            smartForm,
            quickqueryform
        },
        data () {
            return {
                itemdatas:[
                   {title:"编号",field:"id",ctype:"simpletext",isshowlist:false, defvalue:null,pkcol:true,isquery:true },
                   {title:"实体名称",field:"entryName",ctype:"simpletext", defvalue:null,  },
                   {title:"表单名称",field:"formName",ctype:"simpletext", defvalue:null,  },
                    {title:"字段数量",field:"columncount",ctype:"simpletext", defvalue:null,addcaneidt:false },
                   {title:"是否生成小程序",field:"generateWxxcx",ctype:"dropselect", defvalue:null,  options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                   {title:"列表多选",field:"ischeckbox",ctype:"dropselect",isshowlist:false, defvalue:null, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                   {title:"是否分页",field:"ispagination",ctype:"dropselect",isshowlist:false, defvalue:null, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                   {title:"包名",field:"pagePackge",ctype:"simpletext",isshowlist:false, defvalue:null,  },
                    {title:"基础路径",field:"requestBaseurl",ctype:"simpletext",isshowlist:false, defvalue:'/employapi/', datatype:"*" },
                   {title:"列表路径",field:"listurl",ctype:"simpletext",isshowlist:false, defvalue:'datalist',  },
                   {title:"新增路径",field:"addurl",ctype:"simpletext",isshowlist:false, defvalue:'doAdd',  },
                   {title:"编辑路径",field:"editurl",ctype:"simpletext",isshowlist:false, defvalue:'doUpdate',  },
                   {title:"详情路径",field:"detailurl",ctype:"simpletext",isshowlist:false, defvalue:'getdetaildata',  },
                   {title:"删除路径",field:"delurl",ctype:"simpletext",isshowlist:false, defvalue:'doDelete',  },
                   {title:"导出路径",field:"exporturl",ctype:"simpletext",isshowlist:false, defvalue:null,  },
                   {title:"表名",field:"tablename",ctype:"simpletext", isshowlist:false,defvalue:null,  },
                   {title:"创建时间",field:"createTime",ctype:"dateselect",isshowlist:false,isshowadd:false,isshowedit:false, defvalue:null  },
                   {title:"更新时间",field:"updateTime",ctype:"dateselect",isshowlist:false,isshowadd:false,isshowedit:false, defvalue:null  },
                   {title:"小程序工程路径",field:"wxxcxBaseSpace",ctype:"simpletext",isshowlist:false, defvalue:null,  },
                    {title:"PC工程路径",field:"vueBaseSpace",ctype:"simpletext",isshowlist:false, defvalue:null,  },
                   {title:"描述",field:"description",ctype:"simpletext",isshowlist:false, defvalue:null,  },
                    
                ],
                fileditemdatas:[
                   {title:"编号",field:"id",ctype:"simpletext",isshowlist:false, defvalue:null,pkcol:true,isquery:true },
                   {title:"表单编号",field:"fid",ctype:"simpletext", isshowlist:false,isshowaa:false,ishowdetail:false,isshowedit:false, defvalue:null,canedit:false  },
                    {title:"表单名称",field:"fname",ctype:"simpletext", isshowlist:false, defvalue:null,canedit:false  },
                   {title:"字段备注",field:"content",ctype:"simpletext", defvalue:null,  },
                   {title:"默认值",field:"fielddefault",ctype:"simpletext",isshowlist:false, defvalue:null },
                    {title:"字段名称",field:"fieldname",ctype:"simpletext", defvalue:null },
                    {title:"是否必填",field:"fieldmustinput",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"是否主键",field:"iskey",ctype:"dropselect",isshowlist:false, defvalue:2, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"是否查询",field:"isquery",ctype:"dropselect",isshowlist:false, defvalue:2, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"列表显示",field:"isshowlist",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"新增显示",field:"isshowadd",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"编辑显示",field:"isshowedit",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"详情显示",field:"isshowdetail",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],},
                    {title:"控件类型",field:"showtype",ctype:"dropselect",isshowlist:false, defvalue:'simpletext', options: [
                        {  id: 'simpletext', text: '普通文本' }, { id: 'amountText', text: '金额' },{ id: 'dateselect', text: '日期选择' },{ id: 'simplecode', text: '编码组件' },{ id: 'integernumber', text: '整数' },
                        { id: 'proportionInterger', text: '整数占比' },{ id: 'simplescore', text: '评分' },{ id: 'datetime', text: '时间选择' },{ id: 'simpletextarea', text: '多行文本' },{ id: 'photoupload', text: '图片上传' },
                        { id: 'dropselect', text: '下拉选择' },{ id: 'selectoutlet', text: '门店选择' },{ id: 'selectrole', text: '角色选择' },{ id: 'selectdepart', text: '部门选择' }],},
                    {title:"可编辑",field:"canedit",ctype:"dropselect",isshowlist:false, defvalue:1, options: [{  id: 1, text: '是' }, { id:2, text: '否' }],},
                    {title:"关联字段",field:"targetfiled",ctype:"simpletext",isshowlist:false, defvalue:null },
                    {title:"选项",field:"options",ctype:"simpletextarea",isshowlist:false, defvalue:null,canedit:true },
                    {title:"最大上传",field:"maxlength",ctype:"integernumber",isshowlist:false, defvalue:null },
                    {title:"基础路径",field:"baseurl",ctype:"simpletext",isshowlist:false, defvalue:null },
                    {title:"上传路径",field:"uploadurl",ctype:"simpletext",isshowlist:false, defvalue:null },
                ],
                gridedata:{tid:'formlist', tableColumn:[], datas:[], total:0,optwidth:160,funopt:[
                    {funname:'showitem',icon:'vxe-icon--menu',title:'字段配置'},
                    {funname:'addfiled',icon:'vxe-icon--plus',title:'新增字段'}] },
                fieldgridedata:{tid:'formitemlist', tableColumn:[], datas:[],optwidth:180, total:0,funopt:[
                     {funname:'editformitem',icon:'vxe-icon--edit-outline',title:'编辑'},
                    {funname:'showformitem',icon:'vxe-icon--eye',title:'详情'},
                    {funname:'deleteformitem',icon:'vxe-icon--close',title:'删除'}] },
                quickquery:{formitems:[]},
                formitems:[],
                formconfig: { title: "编辑", modal1: false },
                selectedrow:null,//选中数据
                queryparma:{page:1,rows:20},//查询参数
                filedqueryparma:{page:1,rows:20},//查询参数
            }
        },
        created(){
    
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.gridedata.tableColumn=columndata;
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            this.LoadDatas();
           
           //字段配置列表
            var columndata_=  Datautil.$getGridColumn(this.fileditemdatas);
            this.fieldgridedata.tableColumn=columndata_;
            //this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            
        
        },
        methods:{
            //分页加载数据
            LoadDatas(){
                Request({url:"/smartform/datalist",method:"post",data:this.queryparma}).then(res=>{
                    this.gridedata.datas=Datautil.$formattrgriddatas(res.datas,this.gridedata.tableColumn);
                    this.gridedata.total=res.total;
                    console.log(res.datas)
                });
            },
             openaddview(){
                var columndataadd=  Datautil.$getAddFormItems(this.itemdatas);
                //window.console.log("columndata1:",columndata1);
                this.formitems=columndataadd;
                this.formconfig.option="add";
                this.formconfig.title="新增",
                this.formconfig.tid="formlist";
                this.formconfig.modal1=true;
            },
           
            openEditview(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择数据，请选择数据后再点击编辑按钮');
                }else{
                     var param = {};
                    param.id = this.selectedrow.id;
                    Request({url:"/smartform/getdetaildata",method:"post",data:param}).then(res=>{
                        var columndataedit=  Datautil.$getEditFormItems(this.itemdatas);
                        columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                        this.formconfig.option="edit";
                        this.formitems=columndataedit;
                         this.formconfig.tid="formlist";
                        this.formconfig.title="编辑",
                        this.formconfig.modal1=true;
                    });
                }
            },
            dodelete(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择待删除的数据');
                }else{
                    this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=this.selectedrow.id;
                             Request({url:"/smartform/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            },
            //生成代码
            createcode(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择数据，请选择数据后再点击编辑按钮');
                }else{
                     var param = {};
                     param.id = this.selectedrow.id;
                   
                    Request({url:"/wxgenetate/createcodevue",method:"post",data:param}).then(res=>{
                        console.log(res);
                    });
                  
                }
            },
             girdcellclick(data){
                this.selectedrow=data.selected;
                window.console.log(this.selectedrow);
            },
            openDetailview(){
                if(!this.selectedrow){
                    this.$Message.warning('你未选择数据，请选择数据后再点击编辑按钮');
                }else{
                     var param = {};
                    param.id = this.selectedrow.id;
                    Request({url:"/smartform/getdetaildata",method:"post",data:param}).then(res=>{
                        var columndataedit=  Datautil.$getDetailFormItems(this.itemdatas);
                        columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                        this.formconfig.option="detaill";
                        this.formitems=columndataedit;
                         this.formconfig.tid="formlist";
                        this.formconfig.title="详情",
                        this.formconfig.modal1=true;
                    });
                }
               
            },
           onOptionclick(data){ 
                console.log("selected:",data.selected);
                if(data.tid=='formlist'){
                    //用户点击设置角色
                    if(data.funname=='showitem'){
                       
                        this.filedqueryparma.fid=data.selected.id;
                        this.subtable='showuserrole';
                        this.LoadFiledDatas();
                    }else if(data.funname=='addfiled'){
                        var data_={};
                        data_.fid=data.selected.id;
                        data_.fname=data.selected.formName;
                        var columndataadd=  Datautil.$getAddFormItems(this.fileditemdatas);
                        columndataadd= Datautil.$loadDetailData(columndataadd,data_);
                        this.formitems=columndataadd;
                        this.formconfig.option="add";
                         this.formconfig.tid="formitemlist";
                        this.formconfig.title="新增字段",
                        this.formconfig.modal1=true;
                    }

                    
                }else if(data.tid=="formitemlist"){
                    if(data.funname=='showformitem'){
                    var param = {};
                    param.id = data.selected.id;
                    Request({url:"/smartformitem/getdetaildata",method:"post",data:param}).then(res=>{
                        var columndataedit=  Datautil.$getDetailFormItems(this.fileditemdatas);
                        columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                        this.formconfig.option="detail";
                        this.formitems=columndataedit;
                         this.formconfig.tid="formitemlist";
                        this.formconfig.title="字段详情",
                        this.formconfig.modal1=true;
                    });
                    }else if(data.funname=='editformitem'){
                         var param = {};
                    param.id = data.selected.id;
                    Request({url:"/smartformitem/getdetaildata",method:"post",data:param}).then(res=>{
                        var columndataedit=  Datautil.$getEditFormItems(this.fileditemdatas);
                        columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                        this.formconfig.option="edit";
                        this.formitems=columndataedit;
                         this.formconfig.tid="formitemlist";
                        this.formconfig.title="编辑字段",
                        this.formconfig.modal1=true;
                    });
                    }
                    else if(data.funname=='deleteformitem'){
                        var id_=data.selected.id;
                       this.deleteformitem(id_);
                    }
                }
                console.log(data);
            },
            onsubmitquerydata(data){
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.queryparma=d;
                this.gridedata.cpage=1;//表格从第一页开始
                this.LoadDatas();
               
            },
            girdpagechanged(data){
                this.queryparma.page=data.cpage;
                this.LoadDatas();
                
            },
            onsubmit(data){
               if(data.tid=='formlist'){
                    console.log(data)
                if(data.option=="add"){
                    Request({url:"/smartform/doAdd",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                        this.LoadDatas();
                         this.formconfig.modal1=false;
                    });
                }else if(data.option=="edit"){
                    Request({url:"/smartform/doUpdate",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.LoadDatas();
                         this.formconfig.modal1=false;
                    });
                }
               }else if(data.tid=='formitemlist'){

                if(data.option=="add"){
                    Request({url:"/smartformitem/doAdd",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                        this.LoadFiledDatas();
                         this.formconfig.modal1=false;
                    });
                }else if(data.option=="edit"){
                    Request({url:"/smartformitem/doUpdate",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.LoadFiledDatas();
                         this.formconfig.modal1=false;
                    });
                }
               }
              
                
            },
            //加载字段配置列表
            LoadFiledDatas(){
                Request({url:"/smartformitem/datalist",method:"post",data:this.filedqueryparma}).then(res=>{
                    this.fieldgridedata.datas=res.datas;
                    this.fieldgridedata.total=res.total;
                });
            },
            deleteformitem(id){
              
                if(!this.selectedrow){
                    this.$Message.warning('你未选择待删除的数据');
                }else{
                    this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=id;
                             Request({url:"/smartformitem/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadFiledDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            
            }

        }
    }

</script>

<style>
.rows{
    width: auto;
}
.rows .ivu-form-item{
    min-width: 300px ;
}
/* .query-box{
    padding-top: 10px;
} */
.gridbtn{
    margin: 0 5px;
}

</style>