<template>
       <Row style="display:flex;align-items: stretch;">
           <i-col :span="common.getWidth(viewGrid,'orglist')" v-if="common.showView(viewGrid,'orglist')" style="padding-right:10px;background: #fff;margin-right:4px;">
            <div style="background: #fff;margin-right: 10px;">
                    <OrgTreeView ref="orgtree" exp="orgType#eq#2" menuTitle="用户管理" :hasSubActive="common.getSubViewCount(viewGrid,'orglist')"
                     @onOrgSelected="onOrgSelected" @colseSubView="colseSubView('orglist')"/>
            </div>
            
        </i-col>
        <i-col :span="common.getWidth(viewGrid,'userlist')" v-if="common.showView(viewGrid,'userlist')" style="overflow: hidden; padding-right:10px;background: #fff;margin-right:4px;">
            
           <quickqueryform :quickquery="quickquery"  @onsubmitquerydata="onsubmitquerydata"/>
            <!-- 表格组件 -->
        <div class="gridtoobar">
            <div class="gridbtn">
                <Button type="primary" size="small" icon="md-add" @click="openaddview">新增</Button>
                <Button type="primary" size="small" icon="ios-cloud-download" @click="common.downloadFile('template_sys_user')">模板下载</Button>
                <ExcelImport ActionUrl="/empapi/sys/tbsysemploy/importExcel" />
                
            </div>
            <div class="gridbar">
                <Button-group>
                    <Button type="dashed" size="small" icon="md-refresh" @click="LoadDatas" ></Button>
                    <Button type="dashed" size="small" icon="ios-arrow-round-forward " v-if="common.getSubViewCount(viewGrid,'userlist')" @click="colseSubView('userlist')" ></Button>
                </Button-group>
            </div>
        </div>
            <simpletable
                :datagrid="gridedata"
                @girdpagechanged="girdpagechanged"
                @girdcellclick="girdcellclick"
                @onOptionclick="onOptionclick"
            />
        </i-col>
        <i-col :span="common.getWidth(viewGrid,'rolelist')" v-if="common.showView(viewGrid,'rolelist')"  style="align-self: stretch;background: #fff;margin-right:4px;overflow: hidden; ">
         <!-- 表格组件 -->
            <simpletable
              :datagrid="grideroledata"
              @girdpagechanged="girdpagechanged"
              @onOptionclick="onOptionclick"
            />
            
        </i-col>
      
            <!-- 表单弹框 -->
            <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
         
          
        </Row>
</template>
<script>
import ExcelImport from "@/components/wxe/excelImport"; 
import OrgTreeView from "@/components/wxe/OrgTreeView";
import smartForm from "@/components/wxe/smartForm"; //表单
import Request from '@/utils/baseInterface.js';
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
import Datautil from "@/utils/datautil";
    export default {
        components: {
            simpletable,quickqueryform,smartForm,ExcelImport,OrgTreeView
        },
        data () {
            return {
                itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",showConfig:"edit,detail,changepassword",editConfig:"",pkcol:true},
                    {title:"姓名",field:"realname",ctype:"simpletext",showConfig:"list,add,edit,detail,changepassword",editConfig:"add,edit",queryConfig:"list",datatype:"*",isquery:true},
                    {title:"身份证",field:"idCard",ctype:"simpletext",showConfig:"list,add,edit,detail",editConfig:"add,edit",queryConfig:"",datatype:"*",isquery:true},
                   {title:"手机号",field:"phone",ctype:"simpletext",showConfig:"list,add,edit,detail",editConfig:"add,edit",queryConfig:"list",datatype:"*"},
                   {title:"性别",field:"sex",ctype:"dropselect",showConfig:"list,add,edit,detail",editConfig:"add,edit",datatype:"*",queryConfig:"list",dictCode:"sys_sex",isquery:true},
                   {title:"用户部门",field:"orgName",ctype:"simpletext",showConfig:"list",editConfig:""},
                   {title:"用户密码",field:"password",ctype:"simpletext",showConfig:"changepassword",editConfig:"changepassword",datatype:"*"},
                    {title:"用户部门",field:"orgId",ctype:"selectdepart",showConfig:"add,edit,detail",editConfig:"",datatype:"*",isquery:true,targetfiled:"orgName"},
                    {title:"所属机构",field:"companyId",ctype:"simpletext",showConfig:"list",editConfig:"",datatype:"*",isquery:true,targetfiled:"companyName"},
                    {title:"用户状态",field:"userState",ctype:"dropselect", showConfig:"list,add,edit,detail",editConfig:"add,edit", defvalue:1,dictCode:"sys_userState"},
                
                ],
                 roleitemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:false,},
                    {title:"角色名称",field:"rolename",ctype:"simpletext"},
                ],
                 outletdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:false,},
                    {title:"门店名称",field:"outletname",ctype:"simpletext",isquery:true},
                ],
                gridedata:{tid:'userlist', tableColumn:[], datas:[], total:0,optwidth:360,funopt:[
                    {funname:'showuserrole',icon:'vxe-icon--menu',title:'查看角色'},
                    {funname:'editpassword',icon:'vxe-icon--edit-outline',title:'重置密码'},
                    {funname:'showuserinfo',icon:'vxe-icon--eye',title:'用户详情'},
                    {funname:'edit',icon:'vxe-icon--edit',title:'编辑'},
                    {funname:'del',icon:'vxe-icon--close',title:'删除'}],
                     gridurl:'/empapi/sys/tbsysemploy/datalist',
                    addurl:'/empapi/sys/tbsysemploy/doAdd',
                    editurl:'/empapi/sys/tbsysemploy/doUpdate',
                    detailurl:'/empapi/sys/tbsysemploy/getdetaildata',
                    delurl:'/empapi/sys/tbsysemploy/doDelete', },
                
                grideroledata:{tid:'userrolelist', tableColumn:[], datas:[], total:0,funopt:[
                    {funname:'unsignrole',icon:'vxe-icon--close',title:'取消授权',exp:"erid#ISNOTNULL",buttoncolor:'danger'},
                {funname:'signrole',icon:'vxe-icon--check',title:'授权',exp:"erid#ISNULL",buttoncolor:'success'}]},//用户角色表
                
                gridoutletdata:{tid:'outletlist', tableColumn:[], datas:[], total:0},//用户角色表

                quickquery:{formitems:[]},
                outletquickquery:{formitems:[]},//门店快查列表
                formitems:[],
                formconfig: { title: "编辑", modal1: false },
                selectedrow:null,//选中数据
                queryparma:{page:1,rows:20},//查询参数
                userrolequeryparma:{page:1,rows:20},//角色查询参数
                outletqueryparma:{page:1,rows:20},//角色查询参数
                outletselectconfig:{isshow:true},//门店选择器
                subtable:null,//是否显示子表 默认不显示
                viewGrid:[
                    {viewName:"orglist",isShow:true,proportion:1,index:1,hasSubActive:true},
                    {viewName:"userlist",isShow:true,proportion:2,index:2,hasSubActive:true},
                    {viewName:"rolelist",isShow:true,proportion:1,index:3,hasSubActive:false}
                ],
                selectedOrg:null,//选择机构数据
            }
        },
        created(){
            //加载用户表数据
            var columndata=  Datautil.$getGridColumnExt(this.itemdatas);
            this.gridedata.tableColumn=columndata;
            this.quickquery.formitems=Datautil.$getQueryFormItemsExt(this.itemdatas);
            //this.LoadDatas();
            //加载用户角色表配置
            
            var columndata_=  Datautil.$getGridColumn(this.roleitemdatas);
            this.grideroledata.tableColumn=columndata_;
           
           //加载门店表配置
            var columndata_outlet=  Datautil.$getGridColumn(this.outletdatas);
            this.gridoutletdata.tableColumn=columndata_outlet;
             this.outletquickquery.formitems=Datautil.$getQueryFormItems(this.outletdatas);
           
        },
         mounted(){
                  this.$refs.orgtree.LoadDataspid('');
        },
        methods:{
            //分页加载数据
            LoadDatas(){
         
                 this.queryparma.orgId=this.selectedOrg.id;
                Request({url:"/empapi/sys/tbsysemploy/datalist",method:"post",data:this.queryparma}).then(res=>{
            
                this.gridedata.datas=Datautil.$formattrgriddatas(res.data.datas,this.gridedata.tableColumn);
                this.gridedata.total=res.data.total;
                });
            },
             /**
             * 关闭所有子视图
             */
            colseSubView(data){
              this.viewGrid=  this.common.closeSubView(this.viewGrid,data);
            },
             onOrgSelected(data){
                this.selectedOrg=data.selected;
                this.queryparma.orgId=data.selected.id;
                this.LoadDatas();
                this.viewGrid=this.common.activeView(this.viewGrid,"userlist");
 
            },
             openaddview(){
                 if(this.selectedOrg){
                      var columndataadd=  Datautil.$getAddFormItemsExt(this.itemdatas);
                      var data_={};
                    data_.orgId=this.selectedOrg.id;
                    data_.orgName=this.selectedOrg.departname;
                    columndataadd= Datautil.$loadDetailData(columndataadd,data_);
                    this.formitems=columndataadd;
                    this.formconfig.option="add";
                    this.formconfig.tid="userlist";
                    this.formconfig.title="新增",
                    this.formconfig.modal1=true;
                 }else{
                    this.$Message.error("未选择机构数据");
                }
               
            },
            
            onsubmit(data){
                if(data.tid=="userlist"){
                     if(data.option=="add"){
                        Request({url:this.gridedata.addurl,method:"post",data:data.datas}).then(res=>{
                             if(res.success){
                                    this.$Message.info('操作成功');
                            }else{
                                    this.$Message.error(res.message);
                            }
                            this.LoadDatas();
                            this.formconfig.modal1=false;
                        });
                    }else if(data.option=="edit"){
                        Request({url:this.gridedata.editurl,method:"post",data:data.datas}).then(res=>{
                            if(res.success){
                                    this.$Message.info('操作成功');
                            }else{
                                    this.$Message.error(res.message);
                            }
                            this.formconfig.modal1=false;
                            this.LoadDatas();
                        });
                    }
                    else if(data.option=="editpassword"){
                        Request({url:this.gridedata.editurl,method:"post",data:data.datas}).then(res=>{
                            if(res.success){
                                    this.$Message.info('操作成功');
                            }else{
                                    this.$Message.error(res.message);
                            }
                            this.formconfig.modal1=false;
                            this.LoadDatas();
                        });
                    }
                    
                }
               

            },
           
            girdcellclick(data){
                this.selectedrow=data.selected;
                window.console.log(this.selectedrow);
            },
            onsubmitquerydata(data){
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.queryparma=d;
                this.gridedata.cpage=1;//表格从第一页开始
                this.LoadDatas();
               
            },
            girdpagechanged(data){
                this.queryparma.page=data.cpage;
                this.LoadDatas();
                
            },
           
            onOptionclick(data){ 
                console.log("selected:",data.selected);
                if(data.tid=='userlist'){
                    //用户点击设置角色
                    if(data.funname=='showuserrole'){
                        //this.userrolequeryparma.unionid=data.selected.unionid;
                        this.userrolequeryparma.eid=data.selected.id;
                        //this.subtable='showuserrole';
                        this.LoadUserRoleDatas();
                         this.viewGrid=this.common.activeView(this.viewGrid,"rolelist");
                    }else if(data.funname=='showuserinfo'){
                          var parma={};
                            parma.id=data.selected.id;
                        Request({url:"/empapi/sys/tbsysemploy/detail",method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getDetailFormItems(this.itemdatas);
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data.data);
                            this.formconfig.option="detail";
                            this.formitems=columndataedit;
                            this.formconfig.title="用户详情",
                            this.formconfig.modal1=true;
                        });
                    }else if(data.funname=='edit'){
                          var parma={};
                            parma.id=data.selected.id;
                        Request({url:"/empapi/sys/tbsysemploy/detail",method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getEditFormItemsExt(this.itemdatas);
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data.data);
                            this.formconfig.option="edit";
                             this.formconfig.tid="userlist";
                            this.formitems=columndataedit;
                            this.formconfig.title="编辑用户",
                            this.formconfig.modal1=true;
                        });
                    }else if(data.funname=='editpassword'){
                          var parma={};
                            parma.id=data.selected.id;
                        Request({url:"/empapi/sys/tbsysemploy/detail",method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getEditFormItemsExt(this.itemdatas,"changepassword");
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data.data);
                            this.formconfig.option="editpassword";
                             this.formconfig.tid="userlist";
                            this.formitems=columndataedit;
                            this.formconfig.title="重置密码",
                            this.formconfig.modal1=true;
                        });
                    }
                    
                    else if(data.funname=='setorg'){
                        var parma={};
                        parma.id=data.selected.id;
                        console.log("parma:",parma);
                         Request({url:"/empapi/sys/tbsysemploy/detail",method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getEditFormItems(this.itemdatas);
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data.data);
                        
                            this.formitems=columndataedit;
                            this.formconfig.option="edit";
                            this.formconfig.title="设置用户部门",
                            this.formconfig.modal1=true;
                        });
                        
                    }else if(data.funname=='del'){
                        var p_={}; p_.id=data.selected.id;
                        Request({url:this.gridedata.delurl,method:"post",data:p_}).then(res=>{
                            if(res.code!==0){
                                this.$Message.error(res.message);
                            }
                            this.LoadDatas();
                        });

                    }

                    
                }else if(data.tid=='userrolelist'){
                   
                        var parma={};
                        parma.eid=this.userrolequeryparma.eid;
                        parma.rid=data.selected.id;
                        Request({url:"/empapi/sys/tbsysemploy/resetemployrole",method:"post",data:parma}).then(res=>{
                           if(res['message']=="成功"){
                               this.$Message.info('操作成功');
                           }else{
                               this.$Message.info('操作失败',res.message);
                           }
                            this.LoadUserRoleDatas();
                        });
                    
                }else if(data.tid=='outletlist'){
                   
                        var parma={};
                        parma.unionid=this.outletqueryparma.unionid;
                        parma.outletid=data.selected.id;
                        Request({url:"/employapi/tbManageEmployController/resetuseroutlet",method:"post",data:parma}).then(res=>{
                           if(res.success){
                               var outletid= res.employ.outletid;
                                var exp_1='id#ne#'+data.selected.outletid;
                                var funopt_=[{funname:'joinoutlet',icon:'vxe-icon--check',title:'加入',exp:exp_1,buttoncolor:'success'}];
                                this.gridoutletdata.funopt=funopt_;

                               this.$Message.info('操作成功');
                           }else{
                               this.$Message.info('操作失败',res.message);
                           }
                            this.LoadOutletDatas();
                        });
                    
                }
                console.log(data);
            },
            
            //加载用户角色数据
            LoadUserRoleDatas(){
               
                let  postdb=this.userrolequeryparma;
                postdb['did']=this.selectedOrg.id
                Request({url:"/empapi/sys/tbsysemploy/emproledatalist",method:"post",data:postdb}).then(res=>{
                    this.grideroledata.datas=res.data.datas;
                    this.grideroledata.total=res.data.total;
                });
            },
            //加载门店数据
            LoadOutletDatas(){
                       
                Request({url:"/employapi/outlet/datalist",method:"post",data:this.outletqueryparma}).then(res=>{
                    this.gridoutletdata.datas=res.datas;
                    this.gridoutletdata.total=res.total;
                });
            },
            //检索门店数据
            onsubmitquerydata_outlet(data){

       
                
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.outletqueryparma=d;
                this.gridoutletdata.cpage=1;//表格从第一页开始
                this.LoadOutletDatas();
            }


        },
        
    }

</script>
<style>
.actionbtn{
    margin-left: 5px;
    margin-right: 5px;
}
</style>