<template>
 <div class="formitembox-lg">
     <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <div class="photobox" >
        <div class="uploadimages" :data-canedit="formitem.canedit" :data-con="getCanuploadCount()">
            <div class="imagebox"  v-for="(item1,ind1) in getImagedatas(formitem.defvalue)" :key="ind1">
                <img :src="item1.realurl" :suburl="item1.suburl" />
                <div class="demo-upload-list-cover">
                    <Icon type="ios-eye-outline" @click.native="handleView(item1.realurl)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(item1.suburl)"></Icon>
                </div>
            </div>
        </div>
        <div class="filebox"  @click="handleClick" v-if="getCanuploadCount()>0&&formitem.canedit">
                <input
                ref="input"
                type="file"
                @change="handleChange"
                multiple
                accept="image/gif,image/jpeg,image/jpg,image/png">
                 <div style="width: 60px;height:60px;line-height: 52px;" >
                     <Icon type="ios-add" size="56"></Icon>
                </div>
        </div>
    </div>
        </FormItem>
         <Modal title="查看图片" v-model="showimage">
            <img :src="imageurl" v-if="showimage" style="width: 100%">
        </Modal>
    </div>  
</template>
<script>
 import axios from 'axios';
import validity_ from "@/components/wxe/smartvalidate.js";
import {getlocalStorage} from "@/utils/localStorage.js"

    export default {
    created(){
      this.formitem.maxlength=this.formitem.maxlength?this.formitem.maxlength:5
   },
   updated(){
           this.formitem.maxlength=this.formitem.maxlength?this.formitem.maxlength:5; 
   },
   props: {
        formitem: {
        type: Object,
        value: null,
        uploadurl: {
            type: String,
            value: null,
        },
        },
    },
    data(){
        return{
          showimage:false,
          imageurl:null
        }
    },
   props:{
       formitem:{
           type:Object,
           value:null
       },
      
   },
   
   methods:{
       formattertitle(item){
        var title=item.title;
        if(item.datatype){
            title=title+'*'
        }
        title=title+":";
        return title;
        },
       getImagedatas(defvalue,baseurl='https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/'){
            var arr=[];
            if(defvalue!=null&&defvalue!=undefined&&defvalue!=""){
            var arr_=defvalue.split(",");
            arr_.forEach(function(item,index){
                var temp={};
                temp.realurl=baseurl+item;
                temp.suburl=item;
                arr.push(temp);
            });
            }
            return arr;
        },
       
       handleChange(e){
          const files = e.target.files;

            if (!files) {
                return;
            }
            this.uploadFiles(files);
       },
       handleClick () {
            if (this.disabled) return;
            this.$refs.input.click();
        },
        /**
         * 获取可上传的数量
         */
        getCanuploadCount(){
            var hasup=0;
            if(this.formitem.defvalue){
                var arr=this.formitem.defvalue.split(",");
                if(arr){
                    hasup=arr.length;
                }
            }
            var canup=this.formitem.maxlength-hasup;
            console.log("canup:"+canup);
            return canup;
        },
        //上传文件
        uploadFiles(files){
            //判断数量
            var canup=this.getCanuploadCount();
            if(files.length<=canup){
               for(var i=0;i<files.length;i++){
                   this.imageUpload(files[i]);
               }
                this.$refs.input.value = null;
            }else{
                this.$Message.info({ content: "最多只能上传"+canup+"张图片了", duration: 2,});
                this.$refs.input.value = null;
            }
        },
        imageUpload(file){
            var checktype=this.checkfiletype(file);
            if(!checktype)return;;

            //文件上传
            var formData = new FormData();
            // formData.append('name', 'file');
            formData.append('file',file);

            var apitoken_= getlocalStorage("apitoken");
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'WXXCXTOKEN': apitoken_
            }
          };
          var surl_=this.formitem.uploadurl?this.formitem.uploadurl:'http://ynwxemall.ynwxe.com/ynwxemall/empapi/pro/spuInfo/uploadSkuImg';
          axios.post(surl_,formData,config)
            .then((loadres) => {
                if (loadres.status == 200) {
            console.log("loadres:",loadres.data);
            var result=loadres.data;
          

            if(result.code==0){
              let oldval_=this.formitem.defvalue;
              let newval_=result.data.objname;
              if(oldval_!=null&&oldval_!=""){
                oldval_= oldval_ + (newval_ ?","+ newval_:'');
              }else{
                oldval_= newval_ ? newval_ : '';
              }
              this.formitem.defvalue=oldval_;
            
              var parma={};
              parma.val=oldval_;
              parma.fieldname=this.formitem.fieldname
              parma.deftext=null;
               this.$emit("formitemchange", parma);
             
              }else{
                this.$Message.info({ content: "操作失败:"+result.message, duration: 2,});
              }
            }else if (loadres.status == 500) {
               this.$Message.info({ content: "文件大小超出限制", duration: 2,});
            } else if (loadres.status == 413) {
               this.$Message.info({ content: "文件大小超出限制", duration: 2,});
            } else {
              this.$Message.info({ content: "未知错误", duration: 2,});
            }

            })
        },
        checkfiletype(file){
            var ret=false;
            var imgName = file.name;
            var idx = imgName.lastIndexOf(".");  
            if (idx != -1){
                var ext = imgName.substr(idx+1).toUpperCase();   
                ext = ext.toLowerCase( ); 
                if (ext!='gif' && ext!='jpeg' && ext!='jpg'&& ext!='png'){
                     this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
                }else{
                        ret=true;
                }   
            }else{
                this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
            }
            return ret;
        },
         handleView (name) {
             console.log("name:",name)
             this.imageurl=name;
             this.showimage=true;
        },
        handleRemove (suburl) {
            var val_=this.formitem.defvalue;
            var arr=[];
             var arr_=val_.split(",");
            arr_.forEach(function(item,index){
               if(item!=suburl){
                arr.push(item);
               } 
            });
            this.formitem.defvalue=arr.join(",");
        },
   }
}
</script>
<style   scoped>

.error{
  border: 1px solid #f32323;
}
.photobox{
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
}
.uploadimages{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.imagebox {
     
	margin: 2px;
	padding: 2px;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	 
	position: relative;
	width: 64px;
	height: 64px;
	 
	background-position: center;
	
}
.imagebox img{
	width: 64px;
	height: 64px;
}
.filebox{
    width: 62px;
    height: 62px;
    margin: 2px;
    padding: 2px;
    border: 2px dashed #e2e0e0;
}
.filebox input{
    display: none;
}

.demo-upload-list-cover{
        width: 60px;
        height: 60px;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 2px;
	    padding: 2px;
        background: rgba(0,0,0,.6);
    }
    .imagebox:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>