<template>
  <div class="formitembox-mm">
    <FormItem :label="item.title" :prop="item.field">
    <input
      ref="formitemchange"
      type="text"
      class="iteminpu"
      :class="item.correct == true ? '' : 'error'"
      @input="oninput"
      style="width: 92%"
      :disabled="item.canedit == true ? false : true"
      @change="itemchange"
      v-model="item.defvalue"
      placeholder="请输入评分,0~5之间"
    />
    </FormItem>
  </div>
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
export default {
  data() {
    return {};
  },
  created() {},
  updated() {},
  props: {
    item: {
      type: Object,
      value: null,
    },
  },
  methods: {
    itemchange(e) {
      let d_ = {};
      let filedname = this.item.field;
      let val = e.currentTarget.value; //取值
      
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
    oninput(e) {
      window.console.log("oninput:"+e);
     
      let num = e.currentTarget.value.toString(); //先转换成字符串类型
      num = num.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      num = num.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      num = num.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3'); //只能输入两个小数

      if ( num != ""&&num>=5) {
         num = "5";
      }
      if ( num != ""&&num<1) {
         num = "1.0";
      }

      this.item.defvalue = num;
      if (this.item.validation) {
        var validateret = validity_(this.item.validation, this.item.defvalue);
        if (!validateret.success) {
          this.item.correct = false;
          //console.log(validateret);
        } else {
          this.item.correct = true;
        }
      }
    },
  },
};
</script>
<style   scoped>
.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}
</style>    