<template>
    <div class="" style="margin-bottom:100px">
        <div class="gridtoobar">
                    <div class="gridbtn">
                        
                    </div>
                    <div class="gridbar">
                        <Button-group>
                            <Button type="dashed" size="small" icon="md-refresh" @click="LoadDatas" ></Button>
                            <Button type="dashed" size="small"  icon="md-arrow-down " @click="download" >导出部门数据</Button>
                        </Button-group>
                    </div>
            </div>
       
        <vxe-table
                resizable
                  highlight-current-row
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"
                :tree-config="{lazy: true,labelField: 'departname',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="600"
                :data="datas">
            <vxe-table-column field="id" title="编号"  :visible="false" show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="departname" title="机构名称"  width="240"  tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="description" title="描述"   show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="orgcode" title="机构编码"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="orgType" title="机构类型"  :formatter="formatterOrgType"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="parentdepartid" title="父级编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="con" title="子菜单" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
        <vxe-table-column title="操作" width="230" fixed="right" show-overflow> 
  
        <template #default="{ row }"> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'')" icon="vxe-icon--plus" @click="funoptcilck(row,'addchild')" >添加子集</vxe-button> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'')" icon="vxe-icon--edit-outline" @click="funoptcilck(row,'edit')" >编辑</vxe-button> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'con#eq#0')" icon="vxe-icon--close" @click="funoptcilck(row,'del')" >删除</vxe-button>  
        </template> 
        </vxe-table-column>
        </vxe-table>
     
        <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
    </div>
</template>
<script>
import * as XLSX from "xlsx/xlsx.mjs";
import smartForm from "@/components/wxe/smartForm"; //表单
import Request from '@/utils/baseInterface.js';
import Datautil from "@/utils/datautil";
import {
 getDictItemText
} from "@/utils/localStorage.js";

export default {
        components: {
            smartForm
        },
        data () {
            return {
                    datas:[],
                    selectedrow:null,
                    addformitems:[
                         {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
                         {field:"departname",title:"机构名称",ctype:"simpletext",correct:true,defvalue:null,canedit:true,datatype:"*"},
                         {field:"orgType",title:"组织类型",ctype:"dropselect",correct:true,defvalue:'3',canedit:true,datatype:"*",dictCode:"sys_orgType"},
                         {field:"description",title:"描述",ctype:"simpletext",correct:true,defvalue:null,canedit:true,datatype:"*"},
                         {field:"parentdepartid",title:"父级菜单",ctype:"selectoutlet",targetfiled:'pname',isshowedit:false,correct:true,defvalue:null,canedit:false,datatype:"*"},
                        
                    ],
                     formconfig: { title: "编辑", modal1: false },
                     formitems:[],
                     pid:null,
            }
        },
        created(){
            this.LoadDatas();
        },
        methods:{
             LoadDatas(){
                 var parma={};
                 parma.parentdepartid=this.pid;
                Request({url:"/empapi/sys/tbsysdepart/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                   this.datas=datas;   
                window.console.log(res);        
                });
            },
            formatterOrgType({ cellValue }){
                return getDictItemText("sys_orgType",cellValue)
            },
            download(){
                 Request({url:"/empapi/sys/tbsysdepart/exportExcel",method:"get",data:{}}).then(res=>{
                      if(res.code==0){
                            const workbook = XLSX.utils.book_new();
                            const worksheet = XLSX.utils.json_to_sheet(res.data.datas);
                            var wsrows = [{ hidden: true }];
                            worksheet['!rows'] = wsrows; // ws - worksheet
                            XLSX.utils.book_append_sheet(workbook, worksheet, res.data.sheetName);
                            XLSX.writeFile(workbook, res.data.fileName+".xlsx");
                      }else{
                          this.$Message.error(res.message)
                      }
                });
               
            },
            loadchildData({row}){
                return new Promise(resolve => {
                 var parma={};
                 parma.parentdepartid=row.id;
                Request({url:"/empapi/sys/tbsysdepart/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                  
                   resolve(datas)
                window.console.log(res);        
                });
                
                console.log(row);
                 });
            },
           
           
            
             onsubmit(data){
                 if(data.option=="add"){
                      Request({url:"/empapi/sys/tbsysdepart/doAdd",method:"post",data:data.datas}).then(res=>{
                        if(res.code==0){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                      
                        this.formconfig.modal1=false;
                        this.LoadDatas();
                    });
                }else if(data.option=="edit"){
                    Request({url:"/empapi/sys/tbsysdepart/doUpdate",method:"post",data:data.datas}).then(res=>{
                        if(res.code==0){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.formconfig.modal1=false;
                         this.LoadDatas();
                    });
                }
             },
             showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
            },
            funoptcilck(row,funname){
                if(funname=='addchild'){
                    var additems=Datautil.$getAddFormItems(this.addformitems);
                    additems= Datautil.$loadDetailData(additems,{parentdepartid:row.id,pname:row.departname});
                    this.formitems= additems;
                    this.formconfig.option="add";
                    this.formconfig.title="新增",
                    this.formconfig.modal1=true;
                }else if(funname=='edit'){
                    var additems=Datautil.$getEditFormItems(this.addformitems);
                    additems= Datautil.$loadDetailData(additems,row);
                    this.formitems= additems;
                    this.formconfig.option="edit";
                    this.formconfig.title="编辑",
                    this.formconfig.modal1=true;
                }else if(funname=='del'){
                        this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=row.id;
                             Request({url:"/empapi/sys/tbsysdepart/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.code==0){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            }
        }
    }

</script>

<style  lang="less"   scoped>
::v-deep .content-wrapper
{
overflow: hidden!important; 
}
::v-deep .vxe-table--main-wrapper {

    height:70vh !important; 
}
::v-deep .vxe-table--render-wrapper
{
     margin-bottom:100px;
 height:70vh !important; 
}



</style>