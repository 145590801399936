
<template>
  <div>

    <i-col span='8' style="padding-right:10px;">
        <vxe-table
                 resizable
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"      
                :tree-config="{lazy: true,labelField: 'classifyName',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="700"
                :data="matterdatas">
            <vxe-table-column field="id" title="编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           
            <vxe-table-column field="classifyName" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
          
            <vxe-table-column title="操作" width="360" fixed="right" show-overflow> 
  
        <template #default="{ row }">
           <vxe-button type="text" status="primary"  icon="vxe-icon--menu" @click="addClassify(row)" >添加分类</vxe-button> 
            <vxe-button type="text" status="primary"  icon="vxe-icon--menu" @click="addChildClassify(row)" >添加子分类</vxe-button> 
            <vxe-button type="text" status="primary"  icon="vxe-icon--menu" @click="editClassify(row)" >编辑分类</vxe-button> 
            <vxe-button type="text" status="primary" icon="vxe-icon--menu" @click="showmatter(row)" >查看素材</vxe-button>  
        </template> 
        </vxe-table-column>

        </vxe-table>
         <smart-form :formitems="formitems" :formconfig="matterformconfig" @onsubmit="matteronsubmit"></smart-form>
    </i-col>
    <i-col span='16'>
        <div class="photobox" >
            <div class="uploadimages">
                    <div class="imagebox"  v-for="(item1,ind1) in matter.datas" :key="ind1">
                        <img :src="item1.realurl" :suburl="item1.suburl" />
                        <div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(item1.realurl)"></Icon> &nbsp; &nbsp;

                            <Icon type="md-create" @click.native="editMatter(item1)"></Icon> &nbsp; &nbsp;
                            <Icon type="ios-trash-outline" @click.native="handleRemove(item1.suburl)"></Icon>
                        </div>
                       <div class="imagebox-title">{{item1.imageName}}</div>
                    </div>
                    
                </div>

                <div class="filebox"  @click="handleClick">
                <input
                ref="input"
                type="file"
                @change="handleChange"
                multiple
                accept="image/gif,image/jpeg,image/jpg,image/png">
                 <div style="width: 60px;height:60px;line-height: 52px;" >
                     <Icon type="ios-add" size="240"></Icon>
                </div>
        </div>
        </div>
         <Modal title="查看图片" v-model="showimage">
            <img :src="imageurl" v-if="showimage" style="width: 100%">
        </Modal> 
        <smart-form :formitems="photoformitems" :formconfig="photoformconfig" @onsubmit="photoonsubmit"></smart-form>
    </i-col>

    <selectPhoto />
  </div>
</template>
<script>
import smartForm from "@/components/wxe/smartForm";
import Request from "@/utils/baseInterface.js";
import selectPhoto from "@/components/select/selectPhoto";
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform";
import Datautil from "@/utils/datautil";
import {getlocalStorage} from "@/utils/localStorage.js";
import axios from 'axios';
export default {
  components: {
    simpletable,
    quickqueryform,
    smartForm,
    selectPhoto
  },
  data() {
    return {
     
      matterdatas:[],
      matterformitems:[
          {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
          {field:"classifyName",title:"分类名称",ctype:"simpletext",isshowadd:true,correct:true,defvalue:null,canedit:true,datatype:"*"},
          {field:"pid",title:"父级编号",ctype:"simpletext",correct:true,isshowadd:true,isshowdetail:true,defvalue:'0',canedit:false,datatype:"*"},
      ],

      matteritems:[
          {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
          {field:"imageName",title:"图片名称",ctype:"simpletext",isshowadd:true,correct:true,defvalue:null,canedit:true,datatype:"*"},
          
      ],
        matterformconfig: { title: "编辑", modal1: false },
        formitems:[],
        photoformitems:[],
        photoformconfig:{ title: "编辑", modal1: false },
      cid:null,//
      imageurl:null,
      showimage:false,
      matter:{
          datas:[]
      },
      groupqueryparma: { page: 1, rows: 20 },//分组查询参数
    };
  },
  created() {
    this.loadMatterdatas();
    

  },
  methods: {
    //加载分类
    loadMatterdatas(){
       var parma={};
        parma.id=0;
        Request({url:"/empapi/pro/matter/classifyDatalist",method:"post",data:parma}).then(res=>{
          var datas_=res.data.datas;
            datas_.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                }
            });
            this.matterdatas=datas_;
        });
      
    },
    //加载子分类
     loadchildData({row}){
        return new Promise(resolve => {
          var parma={};
          parma.pid=row.id;
        Request({url:"/empapi/pro/matter/classifyDatalist",method:"post",data:parma}).then(res=>{
            var datas=res.data.datas;
            datas.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                    item.childs=[];
                }
            });
          
            resolve(datas)
        window.console.log(res);        
        });
        
        console.log(row);
          });
    },

    loadMatter(){
        var parma={};
        parma.classifyId=this.cid;
        Request({url:"/empapi/pro/matter/matterDatalist",method:"post",data:parma}).then(res=>{
          var datas=[];
          var datas_=res.data.datas;
          datas_.forEach(item=>{
            var temp={};
            temp.realurl="https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/"+item.imageUrl;
            temp.suburl=item.imageUrl;
            temp.imageName=item.imageName;
            temp.id=item.id;
            datas.push(temp);
          });
             this.matter.datas=datas;
        });
    },
    handleView (name) {
          console.log("name:",name)
          this.imageurl=name;
          this.showimage=true;
    },
    showoptionbutton(row, exp) {
      if (exp) {
        var arr = exp.split("#");
        if (arr && arr.length >= 2) {
          var coleval = row[arr[0]];
          var operation = arr[1];
          var val = arr[2];
          if (operation == "eq") {
            return coleval == val;
          } else if (operation == "ne") {
            return coleval != val;
          } else if (operation == "ISNOTNULL") {
            return coleval ? true : false;
          } else if (operation == "ISNULL") {
            return coleval ? false : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    addClassify(row){
       var additems=Datautil.$getAddFormItems(this.matterformitems);
      additems= Datautil.$loadDetailData(additems,{pid:0});
      this.formitems= additems;
      this.matterformconfig.option="add";
      this.matterformconfig.title="新增",
      this.matterformconfig.modal1=true;
    },
    addChildClassify(row){
      var additems=Datautil.$getAddFormItems(this.matterformitems);
      additems= Datautil.$loadDetailData(additems,{pid:row.id});
      this.formitems= additems;
      this.matterformconfig.option="add";
      this.matterformconfig.title="新增",
      this.matterformconfig.modal1=true;
    },
    editClassify(row){
        var additems=Datautil.$getEditFormItems(this.matterformitems);
        additems= Datautil.$loadDetailData(additems,row);
        this.formitems= additems;
        this.matterformconfig.option="edit";
        this.matterformconfig.title="编辑",
        this.matterformconfig.modal1=true;
    },
    showmatter(row){
      this.cid=row.id;
      this.loadMatter();
      console.log(row);
    },

    photoonsubmit(data){
         if(data.option=="edit"){
          Request({url:"/empapi/pro/matter/matterDoUpdate",method:"post",data:data.datas}).then(res=>{
              if(res.code==0){
                      this.$Message.info('操作成功');
              }else{
                      this.$Message.error(res.message);
              }
                this.photoformconfig.modal1=false;
                this.loadMatter();
          });
      }
    },
    matteronsubmit(data){
      if(data.option=="add"){
          Request({url:"/empapi/pro/matter/classifyDoAdd",method:"post",data:data.datas}).then(res=>{
              if(res.code==0){
                      this.$Message.info('操作成功');
              }else{
                      this.$Message.error(res.message);
              }
            
              this.matterformconfig.modal1=false;
              this.loadMatterdatas();
          });
      }else if(data.option=="edit"){
          Request({url:"/empapi/pro/matter/classifyDoUpdate",method:"post",data:data.datas}).then(res=>{
              if(res.code==0){
                      this.$Message.info('操作成功');
              }else{
                      this.$Message.error(res.message);
              }
                this.matterformconfig.modal1=false;
                this.loadMatterdatas();
          });
      }
      },
    handleClick () {
      //if (this.disabled) return;
      if(!this.cid){
         this.$Message.info({ content: "您未选择分类，请先选择分类", duration: 2,});
         this.$refs.input.value = null;
      }else{
        this.$refs.input.click();
      }
     
    },
    handleChange(e){
      const files = e.target.files;

        if (!files) {
            return;
        }
        for(var i=0;i<files.length;i++){
           this.imageUpload(files[i]);
        }
       
        console.log(files)
    },
     //编辑图片
    editMatter(data){
      //
      console.log(data);
      var additems=Datautil.$getEditFormItems(this.matteritems);
        additems= Datautil.$loadDetailData(additems,data);
        this.photoformitems= additems;
        this.photoformconfig.option="edit";
        this.photoformconfig.title="编辑",
        this.photoformconfig.modal1=true;
    },
    checkfiletype(file){
      var ret=false;
      var imgName = file.name;
      var idx = imgName.lastIndexOf(".");  
      if (idx != -1){
          var ext = imgName.substr(idx+1).toUpperCase();   
          ext = ext.toLowerCase( ); 
          if (ext!='gif' && ext!='jpeg' && ext!='jpg'&& ext!='png'){
                this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
          }else{
                  ret=true;
          }   
      }else{
          this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
      }
      return ret;
    },
    /**
     * 文件上传
     */
    imageUpload(file) {
            var checktype=this.checkfiletype(file);
            if(!checktype)return;;

            //文件上传
            var formData = new FormData();
            formData.append('cid',this.cid);
            formData.append('file',file);

            var apitoken_= getlocalStorage("apitoken");
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'WXXCXTOKEN': apitoken_
              }
            };
            var surl_='http://127.0.0.1:8080/empapi/pro/matter/classifyDoAdd';
            axios.post(surl_,formData,config).then((loadres) => {
                  if (loadres.status == 200) {
                    console.log("loadres:",loadres.data);
                    var result=loadres.data;
                    if(result.code==0){
                      this.loadMatter();
                      console.log(result);
                    }else{
                      this.$Message.info({ content: "操作失败:"+result.message, duration: 2,});
                    }
              
                  }else if (loadres.status == 500) {
                    this.$Message.info({ content: "文件大小超出限制", duration: 2,});
                  } else if (loadres.status == 413) {
                    this.$Message.info({ content: "文件大小超出限制", duration: 2,});
                  } else {
                    this.$Message.info({ content: "未知错误", duration: 2,});
                  }

            });
      }
       
  
  
  
  }
  
};
</script>
<style>
.photobox{
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
}
.uploadimages{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.imagebox {
     
	margin: 2px;
	padding: 2px;
	display: inline-flex;
	
	justify-content: center;

	position: relative;
	width: 240px;
	height: 240px;
	border: 2px dashed #e2e0e0; 
	background-position: center;
	
}
.imagebox img{
  margin-top: 2px;
  max-width: 220px;
  max-height: 190px;
}
.filebox{
    width: 240px;
    height: 240px;
    margin: 2px;
    padding: 2px;
    border: 2px dashed #e2e0e0;
}
.filebox input{
    display: none;
}
.demo-upload-list-cover{
        width: 240px;
        height: 240px;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 2px;
	    padding: 2px;
        background: rgba(0,0,0,.6);
    }
    .imagebox:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
    .imagebox-title{
        position: absolute;
        width: 240px;
        bottom: 0px;
        height: 25px;
        padding: 2px;
        
    }
</style>