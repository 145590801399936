<template>
  <div class="formitembox-mm">
     <FormItem :label="formattertitle(item)" :prop="item.field">
    <input
      ref="formitemchange"
      type="text"
      class="iteminpu"
      :class="item.correct == true ? '' : 'error'"
      @input="oninput"
      style="width: 92%"
      :disabled="item.canedit == true ? false : true"
      @change="itemchange"
      v-model="item.defvalue"
    />
     </FormItem>
  </div>
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
export default {
  data() {
    return {};
  },
  created() {},
  updated() {},
  props: {
    item: {
      type: Object,
      value: null,
    },
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    itemchange(e) {
      let d_ = {};
      let filedname = this.item.field;
      let val = e.currentTarget.value; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
    oninput(e) {
      //window.console.log(e);
      let num = e.currentTarget.value.toString(); //先转换成字符串类型

      num = num.replace(/[^\d-]/g, ""); //清除“数字”和“-”以外的字符
      let minusFlag = false;
      if (num.startsWith("-")) {
        //负数
        minusFlag = true;
      }
      num = num.replace(/-/g, ""); //去除全部-

      if (minusFlag) num = "-" + num; //补回负数符号
      this.item.defvalue = num;
      if (this.item.validation) {
        var validateret = validity_(this.item.validation, this.item.defvalue);
        if (!validateret.success) {
          this.item.correct = false;
          //console.log(validateret);
        } else {
          this.item.correct = true;
        }
      }
    },
  },
};
</script>
<style   scoped>

</style>