<template>
  <div class="formitembox-lg">
     <FormItem :label="formattertitle(item)" :prop="item.field">
    <textarea
      ref="formitemchange"
      class="iteminpu"
      :class="item.correct==true?'':'error'"
      @input="oninput"
       :disabled="item.canedit==true?false:true"
      @change="itemchange"
      v-model="item.defvalue"
      rows="3"
      placeholder="请输入内容"
       style="min-width:300px;"
    />
     </FormItem>
  </div>
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
export default {
  data() {
    return {
      
    };
  },
  created() {
   
  },
  updated(){
    
  },
  props: {
    item: {
      type: Object,
      value: null
    }
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    itemchange(e) {
      let d_ = {};
      let filedname = this.item.field;
      let val = e.currentTarget.value; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
    oninput(e) {
      //window.console.log(e);
      if(this.item.validation){
         var validateret= validity_(this.item.validation,this.item.defvalue);
        if(!validateret.success){
          this.item.correct=false;
          //console.log(validateret);
        }else{
          this.item.correct=true;
        }
      }
    
    }
  }
};
</script>
<style   scoped>
.iteminpu {
  resize:none;/*文本域拖拽*/
  display: inline-block;
  width: 100%;
  min-height: 60px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
 .iteminpu:focus {
   display: inline-block;
   outline: none;
border-color: #33aacc !important;box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}.error{
  border: 1px solid #f32323;
}
</style>