<template>
  <div>
    <span style="padding: 10px; color: #fff" v-if="userinfo.realname"
      >[{{ userinfo.realname }}]</span
    >
    <Dropdown>
      <Avatar :src="userinfo.headimg" />
      <Icon :size="18" type="md-arrow-dropdown" style="color: #ffff"></Icon>
      <DropdownMenu slot="list">
        <DropdownItem name="message" v-if="1 == 2">个人资料</DropdownItem>
        <!-- <DropdownItem name="message">我的消息</DropdownItem>
            <DropdownItem name="message">我的订单</DropdownItem> -->

        <DropdownItem name="modpsw"
          ><div @click="showModal">修改密码</div></DropdownItem
        >
        <DropdownItem name="logout"
          ><div @click="logout">退出登录</div></DropdownItem
        >
      </DropdownMenu>
    </Dropdown>

    <div>
      <Modal
        v-model="isModalVisible"
        title="修改密码"
        @on-ok="handleOk"
        @on-cancel="handleCancel"
      >
        <Form ref="formCustom" :label-width="100">
          <FormItem label="当前操作员">
            <div v-if="userinfo.nickname">
              {{ userinfo.nickname }}
            </div>
            <div v-else-if="userinfo.realname">
              {{ userinfo.realname }}
            </div>
            <div v-else-if="userinfo.phone">
              {{ userinfo.phone }}
            </div>
          </FormItem>
 
          <FormItem label="请输入旧密码">
            <Input
              type="password"
              v-model="oldPassword"
              placeholder="请输入旧密码"
            />
          </FormItem>

          <FormItem label="请输入新密码">
            <Input
              type="password"
              v-model="newPassword"
              placeholder="请输入新密码"
            />
          </FormItem>

          <FormItem label="请确认新密码">
            <Input
              type="password"
              v-model="confirmPassword"
              placeholder="请确认新密码"
            />
          </FormItem>
        </Form>
      </Modal>
    </div>
  </div>
</template>
<script>
import Request from "@/utils/baseInterface.js";
import store from "@/store"
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
  removeAllLocalStorage,
} from "@/utils/localStorage.js";
export default {
  data() {
    return {
      userinfo: null,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",

      isModalVisible: false,
    };
  },
  created() {
    var userinfo_ = getlocalStorage("userinfo");
    if (userinfo_) {
      var temp = JSON.parse(userinfo_);
      this.userinfo = temp;
    }
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    handleOk() {
      if (!this.oldPassword) {
        this.$Message.warning("旧密码不能为空");
        return;
      }
      if (!this.newPassword) {
        this.$Message.warning("新密码不能为空");
        return;
      }
      if (!this.confirmPassword) {
        this.$Message.warning("确认密码不能为空");
        return;
      }
      if (this.newPassword.length < 6) {
        this.$Message.warning("密码长度不能小于6位数！");
        return;
      }

      if (!this.oldPassword == this.newPassword) {
        this.$Message.warning("两次输入密码不一致！");
        return;
      }
      var parma = {};
      parma.oldPassWord = this.oldPassword;
      parma.newPassWord = this.newPassword;
      parma.id = this.userinfo.id;

      Request({
        url: "/empapi/sys/tbsysemploy/changePassword",
        method: "post",
        data: parma,
      })
        .then((res) => {
          if (res.code == 0) {
            window.console.log(res);

            this.$Message.info("修改成功！");

            this.oldPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("请求出错:", error);
        });

    },
    handleCancel() {

    },
    confirm() {
      this.$Modal.confirm({
        title: "Title",
        content: "<p>Content of dialog</p><p>Content of dialog</p>",
        onOk: () => {
          this.$Message.info("Clicked ok");
        },
        onCancel: () => {
          this.$Message.info("Clicked cancel");
        },
      });
    },
    //退出登录
    logout() {
      console.log("logout");

       store.commit('setshowleftmenu',true);
       this.collapsed =  false;


       removelocalStorage("userinfo"); 
       removelocalStorage("apitoken");
       removelocalStorage("menus");
      //   removelocalStorage("hasLoad");
      removeAllLocalStorage();

      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style></style>
