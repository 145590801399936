<template>
  <div>
    <button @click="submitForm">Submit</button>
  </div>
</template>
 
<script>
import axios from 'axios';
 
export default {
  methods: {
    submitForm() {




       fetch('http://www.egjkj.com/test.php')
                .then(response => {
                    if (response.ok) {
                        return response.text(); // 获取文本内容
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(html => {
                    that.rawHtml = html;
                    return html

                })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                });




    }
  }
};
</script>