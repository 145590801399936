
<template>
  <div>

    <i-col span='6' style="padding-right:10px;">
        <vxe-table
                 resizable
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"      
                :tree-config="{lazy: true,labelField: 'categoryTitle',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="700"
                :data="categorydatas">
            <vxe-table-column field="id" title="编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           
            <vxe-table-column field="categoryTitle" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
          
            <vxe-table-column title="操作" width="120" fixed="right" show-overflow> 
  
        <template #default="{ row }"> 
          
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'con#ISNULL')" icon="vxe-icon--menu" @click="showgroup(row)" >查看分组</vxe-button>  
        </template> 
        </vxe-table-column>

        </vxe-table>
    </i-col>
    <i-col span='18'>

       
    <quickqueryform
      :quickquery="quickquery"
      @onsubmitquerydata="onsubmitquerydata"
    />
    <div class="gridtoobar">
      <div class="gridbtn">
        <Button
          type="primary"
          v-if="gridedata.gridurl"
          size="small"
          icon="md-add"
          @click="openaddview"
          >新增</Button
        > 
      </div>
      <div class="gridbar">
        <Button-group>
          <Button
            type="dashed"
            size="small"
            icon="md-refresh"
            @click="reloadgrid"
          ></Button>
          <Button
            type="dashed"
            size="small"
            v-if="gridedata.excelurl"
            icon="md-arrow-down "
            @click="exportexcle"
          ></Button>
        </Button-group>
      </div>
    </div>
    <simpletable
      :datagrid="gridedata"
      @girdpagechanged="girdpagechanged"
      @onOptionclick="onOptionclick"
    />
    <smart-form
      :formitems="formitems"
      :formconfig="formconfig"
      @onsubmit="onsubmit"
    ></smart-form>
    </i-col>
  </div>
</template>
<script>
import smartForm from "@/components/wxe/smartForm";
import Request from "@/utils/baseInterface.js";
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform";
import Datautil from "@/utils/datautil";
export default {
  components: {
    simpletable,
    quickqueryform,
    smartForm,
  },
  data() {
    return {
      itemdatas: [
        { title: "编号", field: "id", ctype: "simpletext",isshowedit: true,correct: true,isquery: false,isshowadd: false,isshowdetail: true,isshowlist: false,datatype: "*",canedit: false,pkcol: true,},
        {title:"组名",field:"attrGroupName",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,validation:"*",isquery:false},
        {title:"排序",field:"sort",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,validation:"*",isquery:false},
        {title:"描述",field:"descript",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,validation:"*",isquery:false},
        {title:"分类编号",field:"catelogId",ctype:"simpletext",isshowlist:true,isquery:false,canedit:false},
      ],
      categorydatas:[],
      gridedata: {
        tid: "spuinfolist",
        tableColumn: [],
        datas: [],
        total: 0,
        optwidth: 200,
        funopt: [
          { funname: "detail", icon: "vxe-icon--menu", title: "详情" },
          { funname: "edit", icon: "vxe-icon--edit-outline", title: "编辑" },
          { funname: "del", icon: "vxe-icon--edit-outline", title: "删除" },
        ],
        gridurl: "/empapi/pro/attrgroup/datalist",
        addurl: "/empapi/pro/attrgroup/doAdd",
        editurl: "/empapi/pro/attrgroup/doUpdate",
        detailurl: "/empapi/pro/attrgroup/getdetail",
        delurl: "/empapi/pro/attrgroup/doDelete",
        excelurl: null,
      },
      quickquery: { formitems: [] },
      formitems: [],
      formconfig: { title: "", modal1: false },
      queryparma: { page: 1, rows: 20 },
      selectedCategory:null,//选中分类
    };
  },
  created() {
    this.loadCategorydatas();
    var columndata = Datautil.$getGridColumn(this.itemdatas);
    this.gridedata.tableColumn = columndata;
    this.quickquery.formitems = Datautil.$getQueryFormItems(this.itemdatas);
    // this.LoadDatas();
  },
  methods: {
    loadCategorydatas(){
       var parma={};
        parma.id=0;
        Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
          var datas_=res.data.datas;
            datas_.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                }
            });
            this.categorydatas=datas_;
        });
      
    },
     loadchildData({row}){
        return new Promise(resolve => {
          var parma={};
          parma.id=row.id;
        Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
            var datas=res.data.datas;
            datas.forEach(item=>{
                if(item.con&&item.con>0){
                    item.hasChild=true;
                    item.childs=[];
                }
            });
          
            resolve(datas)
        window.console.log(res);        
        });
        
        console.log(row);
          });
    },
    LoadDatas() {
      Request({
        url: this.gridedata.gridurl,
        method: "post",
        data: this.queryparma,
      }).then((res) => {
        this.gridedata.datas = Datautil.$formattrgriddatas(
          res.data.datas,
          this.gridedata.tableColumn
        );
        this.gridedata.total = res.data.total;
      });
    },
    openaddview() {
      if(this.selectedCategory){
        var d={};
        d.catelogId=this.selectedCategory.id;
        d.catelogName=this.selectedCategory.categoryTitle;
      var columndataadd = Datautil.$getAddFormItems(this.itemdatas);
      columndataadd = Datautil.$loadDetailData(columndataadd, d);
      this.formitems = columndataadd;
      
      this.formconfig.option = "add";
      this.formconfig.title = "新增";
      this.formconfig.modal1 = true;
      }else{
         this.$Message.error('请选择分类');
      }
     
    },
    onsubmitquerydata(data) {
      var d = data;
      d.page = 1;
      d.rows = 20;
      this.queryparma = d;
      this.gridedata.cpage = 1;
      this.LoadDatas();
    },
    girdpagechanged(data) {
      this.queryparma.page = data.cpage;
      this.LoadDatas();
    },
    onsubmit(data) {
      if (data.option == "add") {
        Request({
          url: this.gridedata.addurl,
          method: "post",
          data: data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      } else if (data.option == "edit") {
        Request({
          url: this.gridedata.editurl,
          method: "post",
          data: data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      }
    },
    reloadgrid() {
      this.LoadDatas();
    },
    exportexcle() {},
    onOptionclick(data) {
      console.log("selected:", data.selected);
      if (data.tid == "spuinfolist") {
        if (data.funname == "detail") {
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getDetailFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "detail";
            this.formitems = columndataedit;
            (this.formconfig.title = "详情"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "edit") {
          //
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getEditFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "edit";
            this.formitems = columndataedit;
            (this.formconfig.title = "编辑"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "del") {
          var p_ = {};
          p_.id = data.selected.id;
          Request({
            url: this.gridedata.delurl,
            method: "post",
            data: p_,
          }).then((res) => {
            this.LoadDatas();
          });
        }
      }
    },
    //树形结构点击事件
    showgroup(row){
      this.selectedCategory=row;

        var d = {};
      d.page = 1;
      d.rows = 20;
      d.catelogId=row.id;
      this.queryparma = d;
      this.gridedata.cpage = 1;
      this.LoadDatas();
    },
    showoptionbutton(row, exp) {
      if (exp) {
        var arr = exp.split("#");
        if (arr && arr.length >= 2) {
          var coleval = row[arr[0]];
          var operation = arr[1];
          var val = arr[2];
          if (operation == "eq") {
            return coleval == val;
          } else if (operation == "ne") {
            return coleval != val;
          } else if (operation == "ISNOTNULL") {
            return coleval ? true : false;
          } else if (operation == "ISNULL") {
            return coleval ? false : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
</style>