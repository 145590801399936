import Vue from 'vue'
import App from './App.vue'
import router from './router'
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'
import '@/components/wxe/css/smartform.css';
import '@/css/yzmxgrid.css';
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import store from './store';
import * as common from '@/utils/common.js' 

Vue.prototype.common=common

Vue.prototype.$video = Video
Vue.config.productionTip = false
Vue.use(iView)
Vue.use(VXETable)



Vue.prototype.$Message.config({
  top: 120,
  duration:2
});

let myVue=new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

console.log(myVue.$store)