<template>
  <div class="formitembox-sm">
     <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
      <input 
      ref="formitemchange"
      type="text"
      class="iteminpu"
      :class="formitem.correct==true?'':'error'"
       disabled
      @change="itemchange"
      v-model="formitem.defvalue"
    />
      <Button type="info" size="small" v-if="formitem.canedit" style="margin-right:5px;"  @click="handleClick">{{formitem.defvalue?'重新上传':'上传'}}</Button>
      

      <Button type="info" size="small" v-if="formitem.defvalue"  @click="openvideo">预览</Button>
    </FormItem>
     <input
        ref="inputmp4"
        type="file"
        @change="handleChange"
        accept="video/mp4,audio/mp4" style="display:none;">
     
  </div>
</template>
<script>
 import axios from 'axios';
import Datautil from "@/utils/datautil";
import {getlocalStorage} from "@/utils/localStorage.js"
export default {
  components: {
    
  },
  data() {
    return {
    
    };
  },
  created() {

  },
  updated() {},
  props: {
    formitem: {
      type: Object,
      value: null,
      uploadurl: {
            type: String,
            value: null,
      },
    },
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
  
    itemchange(e) {
      
      let d_ = {};
      let filedname = this.formitem.field;
      let val = this.formitem.defvalue; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
     handleClick () {
        this.$refs.inputmp4.click();
     },
     checkfiletype(file){
        var ret=false;
        var imgName = file.name;
        var idx = imgName.lastIndexOf(".");  
        if (idx != -1){
            var ext = imgName.substr(idx+1).toUpperCase();   
            ext = ext.toLowerCase( ); 
            if (ext!='mp4'){
                  this.$Message.info({ content: "只支持MP4格式的文件，请勿选择其它类型", duration: 2,});
            }else{
                    ret=true;
            }   
        }else{
            this.$Message.info({ content: "只支持MP4格式的文件，请勿选择其它类型", duration: 2,});
        }
        return ret;
     },
     checkfilesize(file){
       var ret=false;
            if (file.size>52428800 ){
                  this.$Message.info({ content: "视频必须小于50M", duration: 2,});
            }else{
                ret=true;
            }   
       
        return ret;
     },
     handleChange(e){
       console.log("e:",e);
          const files = e.target.files;

            if (!files) {
                return;
            }
            
            var checktype=this.checkfiletype(files[0]);
            if(!checktype)return;;

            var checksize=this.checkfilesize(files[0]);
            if(!checksize)return;;
            
             var formData = new FormData();
            // formData.append('name', 'file');
            formData.append('video',files[0]);

            var apitoken_= getlocalStorage("apitoken");
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'apitoken': apitoken_
            }
          };
          var surl_=this.formitem.uploadurl?this.formitem.uploadurl:'https://xmyx.ynyzmx.com/xmyx/employapi/TbManageWorksController/uploadvideo';
          axios.post(surl_,formData,config)
            .then((loadres) => {
                if (loadres.status == 200) {
            console.log("loadres:",loadres.data);
            var result=loadres.data;
            if(result.success){
              let newval_=result.obj;
              this.formitem.defvalue=newval_;
              var parma={};
              parma.val=oldval_;
              parma.fieldname=this.formitem.fieldname
              parma.deftext=null;
               this.$emit("formitemchange", parma);
              }else{
                this.$Message.info({ content: "操作失败:"+result.message, duration: 2,});
              }
              }else if (loadres.status == 500) {
                this.$Message.info({ content: "文件大小超出限制", duration: 2,});
              } else if (loadres.status == 413) {
                this.$Message.info({ content: "文件大小超出限制", duration: 2,});
              } else {
                this.$Message.info({ content: "未知错误", duration: 2,});
              }

            })
           // this.uploadFiles(files);
       },
      openvideo(e){
       var url= window.location.origin +'/video?obj='+this.formitem.defvalue;
        window.open(url,'_blank');
      }
    
  },
};
</script>
<style   scoped>
  .ivu-form-item-content{
    width: 100%;
    display: flex;
    align-items: center;
  }
  .iteminpu{
    width: 200px;
    margin-right: 10px;
  }
</style>