<template>
  <div ref="girdselectchanged">
    <!-- 表格组件 -->
    <div class="tables" style="margin-top:0px  !important;">
      
      <vxe-table
        border="full"
        size="mini"
        align="center"
        :height="datagrid.height?datagrid.height:600"
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        resizable
        :edit-config="{trigger: 'dblclick', mode: 'cell'}"
        stripe
        class="Tables"
        show-overflow
        :data="datagrid.datas"
        @checkbox-change="selectChangeEvent"
        @cell-click="clickrow"
        
      >
        <!-- <vxe-table-column type="checkbox" width="30" fixed="left" style=" height: 28px !important;line-height: 17px !important;"></vxe-table-column> -->
        <vxe-table-column type="seq" width="50" fixed="left"></vxe-table-column>
        <vxe-table-column
          v-for="(item,index) in datagrid.tableColumn"
          :key="index"
          :field="item.field"
          :title="item.title"
          :width="item.colwidth"
          :visible="item.visible"
          show-header-overflow
          show-overflow="title"
          show-footer-overflow
        ></vxe-table-column>

<vxe-table-column title="操作" :width="datagrid.optwidth?datagrid.optwidth:120" fixed="right" show-overflow v-if="datagrid.funopt&&datagrid.funopt.length>0"> 
  
  <template #default="{ row }"> 
    <i v-for="(item,ind) in datagrid.funopt" :key="row.id+ind">
      <vxe-button type="text" :status="item.buttoncolor?item.buttoncolor:'primary'" v-if="showoptionbuttonExt(row,item.exp)" :icon="item.icon" @click="funoptcilck(row,item.funname)" >{{item.title}}</vxe-button> 
    </i>
  </template> 
</vxe-table-column>


      </vxe-table>
    </div>
    <div class="page_box" v-if="datagrid.showpage!=false">
      <vxe-pager
        :current-page="datagrid.cpage?datagrid.cpage:1"
        :page-size="datagrid.rows?datagrid.rows:20"
        :total="datagrid.total?datagrid.total:0"
        :page-sizes="[20]"
        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'Total']"
        @page-change="pagechanged"
      ></vxe-pager>

      <!-- <Page v-show="datagrid.rows" :total="datagrid.rows?datagrid.rows:0" :current-page="datagrid.pidx?datagrid.pidx:1" :page-size="datagrid.plen?datagrid.plen:20" @page-change="pagechanged"/> -->
    </div>
  </div>
</template>
<script>

export default {
  props: {
    datagrid: {
      type: Object,
      value: null,
    },
    tratio: null,
  },
  data() {
    return {
      selectedrows: [], //选中数据
    };
  },
  created() {
    
     
    
  },

  updated() {},
  
  methods: {
    pagechanged({ currentPage }) {
        var data_ = {};
     data_.tid = this.datagrid.tid;
      data_.cpage = currentPage;
      //data_.queryparmas = this.datagrid.queryparmas;
      this.$emit("girdpagechanged", data_);
      this.datagrid.cpage=currentPage;
     window.console.log("cpage:",currentPage)
    },
    selectChangeEvent({ checked, records }) {
        let data_ = {};
      data_.selected = records;
      this.$emit("girdselectchanged", data_);
      window.console.log(checked,records)
    },
    clickrow({ row }) {
         let data_ = {};
      data_.selected = row;
     data_.tid=this.datagrid.tid;
      this.$emit("girdcellclick", data_);
       window.console.log(row)
    },
    funoptcilck(row,funname){
         let data_ = {};
      data_.selected = row;
      data_.funname=funname;
      data_.tid=this.datagrid.tid;
      this.$emit("onOptionclick", data_);
      
    },
    
    // 格式化高度的方法
    oPoint(percent) {
      var str = percent.replace("%", "");
      str = str / 100;
      return str;
    },
    showoptionbutton(row,exp){
      if(exp){
        var arr=  exp.split("#");
        if(arr&&arr.length>=2){
          var coleval=row[arr[0]];
          var operation=arr[1];
          var val=arr[2];
          if(operation=="eq"){
            return coleval==val;
          }else if(operation=="ne"){
            return coleval!=val;
          }else if(operation=="ISNOTNULL"){
            return coleval?true:false;
          }else if(operation=="ISNULL"){
            return coleval?false:true;
          }else{
            return false
          }
        }else{
          return false;
        }
      }else{
        return true;
      }
    },
    //升级配方  支持多条件
    showoptionbuttonExt(row,exp){
      
        if(exp){
          var arr=exp.split("&&");
          for(let i=0;i<arr.length;i++){
           var ret= this.showoptionbutton(row,arr[i]);
           if(!ret){console.log("false:",arr[i])
             return false;
           }
          }
        }
        return true;
    }
    
  }
};
</script>
<style  scoped>
.ivu-page-item-active {
  background: #0095bf !important;
}
.page_box {
  text-align: right;
  padding: 0 !important;
  margin: 0px 0px !important;
}

.Tables{
        bottom:0px !important;
        height:auto;
        background:#fff;
}
.rows {
  border: 1px solid red;
}
</style>