<template>
    <div>
        <vxe-table
                 resizable
                keep-source
                border="full"
                size="mini"
                ref="xTree"
                row-id="id"
                :tree-config="{lazy: true,labelField: 'categoryTitle',hasChild: 'hasChild',loadMethod: loadchildData,children: 'childs'}"
               max-height="600"
                :data="datas">
            <vxe-table-column field="id" title="编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
           
            <vxe-table-column field="categoryTitle" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="icon" title="logo地址" width="100"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="categorySort" title="排序" width="60"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="pid" title="父级编号" :visible="false" show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="con" title="子分类数量"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="createTime" title="创建时间"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="createBy" title="创建人编号 "  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="createName" title="创建人姓名 "  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="updateTime" title="更新时间"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="updateBy" title="更新人编号 "  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column field="updateByName" title="更新人姓名 "  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
            <vxe-table-column title="操作" width="300" fixed="right" show-overflow> 
  
        <template #default="{ row }"> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'pid#eq#0')" icon="vxe-icon--plus" @click="funoptcilck(row,'addfirst')" >新增分类</vxe-button> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'pid#eq#0')" icon="vxe-icon--plus" @click="funoptcilck(row,'addchild')" >新增子分类</vxe-button> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'')" icon="vxe-icon--edit-outline" @click="funoptcilck(row,'edit')" >编辑</vxe-button> 
            <vxe-button type="text" status="primary" v-if="showoptionbutton(row,'con#eq#0')" icon="vxe-icon--close" @click="funoptcilck(row,'del')" >删除</vxe-button>  
        </template> 
        </vxe-table-column>

        </vxe-table>

        <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
    </div>
</template>
<script>
import smartForm from "@/components/wxe/smartForm"; //表单
import Request from '@/utils/baseInterface.js';
import Datautil from "@/utils/datautil";
export default {
        components: {
            smartForm
        },
        data () {
            return {
                    datas:[],
                    selectedrow:null,
                    gridformitems:[
                        {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
                        {field:"categoryTitle",title:"分类标题",ctype:"simpletext",correct:true,defvalue:null,canedit:true,datatype:"*"},
                        {field:"pid",title:"父级编号",ctype:"simpletext",correct:true,isshowadd:true,isshowdetail:true,defvalue:'0',canedit:false,datatype:"*"},
                        {field:"icon",title:"PC图标",ctype:"simpletext",correct:true,defvalue:null,canedit:true,datatype:"*"},
                        {field:"categorySort",title:"排序",ctype:"integernumber",correct:true,defvalue:1,canedit:true,datatype:"*"},
                        {field:"categoryState",title:"状态",ctype:"dropselect",correct:true,defvalue:"1",deftext:'启用',canedit:true,options:[{id:"1",text:"启用"},{id:"2",text:"停用"}],},
                       
                    ],
                     formconfig: { title: "编辑", modal1: false },
                     formitems:[]
            }
        },
        created(){
            this.LoadDatas();
        },
        methods:{
             LoadDatas(){
                 var parma={};
                 parma.id=0;
                Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
                  var datas_=res.data.datas;
                   datas_.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                       }
                   });
                    this.datas=datas_;
                });
            },
             loadchildData({row}){
                return new Promise(resolve => {
                 var parma={};
                 parma.id=row.id;
                Request({url:"/empapi/pro/category/datalist",method:"post",data:parma}).then(res=>{
                   var datas=res.data.datas;
                   datas.forEach(item=>{
                       if(item.con&&item.con>0){
                           item.hasChild=true;
                           item.childs=[];
                       }
                   });
                  
                   resolve(datas)
                window.console.log(res);        
                });
                
                console.log(row);
                 });
            },
             onsubmit(data){
                 if(data.option=="add"){
                    Request({url:"/empapi/pro/category/doAdd",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                      
                        this.formconfig.modal1=false;
                        this.LoadDatas();
                    });
                }else if(data.option=="edit"){
                    Request({url:"/empapi/pro/category/doUpdate",method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.formconfig.modal1=false;
                         this.LoadDatas();
                    });
                }
             },
              showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
            },
            funoptcilck(row,funname){
                if(funname=='addchild'){
                    var additems=Datautil.$getAddFormItems(this.gridformitems);
                    additems= Datautil.$loadDetailData(additems,{pid:row.id,pname:row.categoryTitle});
                    this.formitems= additems;
                    this.formconfig.option="add";
                    this.formconfig.title="新增",
                    this.formconfig.modal1=true;
                }else if(funname=='addfirst'){
                     var additems=Datautil.$getAddFormItems(this.gridformitems);
                    this.formitems= additems;
                    this.formconfig.option="add";
                    this.formconfig.title="新增",
                    this.formconfig.modal1=true;
                }
                else if(funname=='edit'){
                    var additems=Datautil.$getEditFormItems(this.gridformitems);
                    additems= Datautil.$loadDetailData(additems,row);
                    this.formitems= additems;
                    this.formconfig.option="edit";
                    this.formconfig.title="编辑",
                    this.formconfig.modal1=true;
                }else if(funname=='del'){
                        this.$Modal.confirm({
                        title: '确定要删除吗',
                        content: '<p>删除后将无法恢复</p>',
                        onOk: () => {
                            var p_={};
                            p_.id=row.id;
                             Request({url:"/empapi/pro/category/doDelete",method:"post",data:p_}).then(res=>{
                                    if(res.success){
                                            this.$Message.info('操作成功');
                                    }else{
                                            this.$Message.error(res.message);
                                    }
                                    this.LoadDatas();
                            });
                        },
                        onCancel: () => {
                           
                        }
                    });
                }
            }
        }
    }

</script>

<style>

</style>