import Vue from 'vue'
import Router from 'vue-router' //vue路由
import Login from '@/views/login'//登录
import Register from "@/views/register"//注册
import Home from '@/views/home'//首页
import Main from '@/components/mainparent/main.vue'//首页
import DBCenter from '@/views/dbcenter/dbcenter'
import Workbenches from '@/views/workbenches/workbenches'
import Videoplayer from "@/views/videoplayer"
import test from "@/views/test"
import loading from "@/views/loading"
import store from "@/store"
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
} from "@/utils/localStorage.js";
import VueRouter from 'vue-router'
import {removeAllLocalStorage} from "@/utils/localStorage.js"






const LOGIN_PAGE_NAME = 'login';
const homeName="/";
Vue.use(Router)
const routes=[
  {
    path: '/',
    name:'_workbenches',
    redirect:'/workbenches',
    component: Main,
    children:[
      {
        path: '/workbenches',
        name: 'workbenches',
        meta: {
          hideInMenu: true,
          title: '首页',
          notCache: true,
          icon: 'md-home'
        },
        component:Workbenches
      }
    ]
  },
  {
    path: '/workbenches',
    name: 'workbenches',
    component: Main
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },

  {
    path: '/test',
    name: 'test',
    component: test
  },


  {
    path: '/loading',
    name: 'loading',
    component: Main
  },
  {
    path: '/text',
    name: 'text',
    component:Main
  },
  {
    path: '/video',
    name: 'video',
    component:Videoplayer
  },
];

const router = new Router({
  routes,
  mode:"history"//去除#号
})

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (location, resolve, reject) {
        if (resolve && reject) {
          console.log('11111111111');
          originPush.call(this, location, resolve, reject);
        } else {
          console.log('222222222');
          console.log(location);
          console.log(this);


          originPush.call(this, location, () => { }, () => { });
        }

}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
      originReplace.call(this, location, resolve, reject);
  } else {
      originReplace.call(this, location, () => {}, () => {});
  }
}

router.beforeEach((to, from, next) => {

  console.info(to.name)
  const token = getlocalStorage("apitoken");
  if (!token && to.name !== LOGIN_PAGE_NAME) {
    // 未登录且要跳转的页面不是登录页
    next({
      name: LOGIN_PAGE_NAME // 跳转到登录页
    })
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    next() // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    //todo 正确做法应该是检查令牌合法性 合法就跳转 不合法才登录
    var redirectUrl=to.query.redirectUrl;
    if(redirectUrl){
      next() // 跳转
    }else{
      removeAllLocalStorage();
      // 已登录且要跳转的页面是登录页
      next({
        name: homeName // 跳转到homeName页
      })
    }
   
  } else {
   
    var hasLoad=getlocalStorage("hasLoad");
    var menus_str=getlocalStorage("menus");
    var menus=JSON.parse(menus_str);
    var ros_=[];
    // if(!store.state.hasInit){
    //   store.commit('ResetHasInit',true);
    //   store.commit('InitRouter',[1,2,3]);
    //   console.log(store.state.hasInit)
    // }
    console.log("arr:",store.state.menus)


    if(!store.state.hasInit&&menus&&menus.length>0){

       var newroutes=routerChildren(menus);
       newroutes.forEach(item=>{
         console.log(item)
        router.addRoute(item);
       });
       store.commit('ResetHasInit',true);
      setlocalStorage("hasLoad",true);
      next({...to,replace:true});//重新进入一次  不保留历史
    }
    else if (!router.getRoutes().find(item => item.name === to.name)){
      console.log('WU LOYU ');
      var newroutes=routerChildren(menus);
      newroutes.forEach(item=>{
        console.log(item)
       router.addRoute(item);
      });
      store.commit('ResetHasInit',true);
     setlocalStorage("hasLoad",true);
     next({...to,replace:true});//重新进入一次  不保留历史
    }


  

    
    else{
      next();
    }
    console.log(router.getRoutes())


   
  }
});


function routerCom(path){
  console.log("path:",path)
  return ()=>import('@/views/'+path+"");
}

function routerChildren(children){
  var rs_=[];
  children.forEach(v=>{
      var r_={};
      r_.path=v.path;
      r_.name=v.name;
      r_.meta=v.meta;

      if(v.component=="main"){
        r_.component=Main;
      }else{
        r_.component=routerCom(v.component);
      }
      
      if(v.children&&v.children.length>0){
        r_.children=routerChildren(v.children);
      }
     rs_.push(r_);
  });
  return rs_;
}
export default router