<template>
  <Layout class="layout-div">
    <Sider
      ref="side1"
      hide-trigger
      collapsible
      :collapsed-width="78"
      :width="260"
      v-model="isCollapsed"
      :class="themeType === 'light' ? 'themeLight' : 'themeDark'"
    >
      <div class="logo">
        <img v-if="!isCollapsed" :src="logoimglarge" alt />

        <!-- <img v-if="isCollapsed" :src="logoimgsmall" alt /> -->
      </div>

      <Menu active-name="1-2" theme="light" width="auto" :open-names="['1']">
        <div class="layout-logo-left"></div>
        <Submenu
          :name="item.id"
          v-for="(item, index) in menudatas"
          :key="index"
        >
          <template slot="title">
            <Icon type="ios-navigate"></Icon>

            <span v-if="!isCollapsed">{{ item.mname }}</span>
          </template>
          <!-- :class="!isCollapsed?'shrink':''"  -->
          <Menu-item
            
            :name="item1.id"
            v-for="(item1, ind1) in item.childs"
            :key="ind1"
            :data-linkurl="item1.linkurl"
            :data-menudata="item1"
            @click.native="opentab(item1)"
            >{{ item1.mname }}</Menu-item>
        </Submenu>
      </Menu>
    </Sider>
    <Layout>
      <Header class="header flexR">
        <div class="header_left flexR">
          <span>
            <Icon
              @click.native="collapsedSider"
              :class="rotateIcon"
              type="md-menu"
              size="24"
            ></Icon>
          </span>
          <span>云南维希尔电商后台管理系统</span>
        </div>
        <div class="header_right flexR">
          <!-- 放大 -->

          <!-- 客服 -->

          <span> </span>

          <span>
            <!-- 关于 -->
            <Tooltip content="我的取号" placement="bottom">
              <Icon type="ios-apps" size="24" />
            </Tooltip>
          </span>
          <span>
            <Tooltip content="我的消息" placement="bottom">
              <Icon type="ios-notifications" size="24" />
            </Tooltip>
          </span>
          <span>
            <userAvatar />
          </span>
        </div>
      </Header>
      <!--多页标签-->
      <div class="tabsNav flexR" ref="main1">
        <!-- <div class="xiala pointer" >
            <Icon type="ios-arrow-back" size="16" />
          </div> -->
        <div>
          <!-- <Tag type="dot" closable >主页</Tag> -->
          <Tag
            type="border"
            v-for="(item, index) in tagdatas"
            :key="index"
            class="tag_box"
            :closable="item.isClose"
            checkable
            :color="item.active ? tabsColor : ''"
            @on-change="selectTabs(item)"
            @on-close="handleClose(item)"
            >{{ item.title }}</Tag
          >
        </div>
        <!-- <div class="xiala pointer" >
            <Icon type="ios-arrow-forward" size="16" />
          </div> -->
        <div class="right">
          <Dropdown style="margin-left: 5px">
            <div class="xiala pointer">
              <Icon type="ios-arrow-down" />
            </div>
            <DropdownMenu slot="list">
              <DropdownItem name="qt"
                ><div @click="closeother">关闭其他</div></DropdownItem
              >
              <DropdownItem name="qb"
                ><div @click="closealltab">全部关闭</div></DropdownItem
              >
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="layout-content">
        <div class="layout-content-main">
          <div
            :class="item.active ? '' : 'hidetabcontent'"
            v-for="item in tagdatas"
            :key="item.mid"
          >
            <HomeIndex v-if="item.pcmodel == 'homeindex'" />
            <UserList v-if="item.pcmodel == 'tbmanegeemploy'" />
            <RoleList v-if="item.pcmodel == 'tbmanagerole'" />
            <MenuList v-if="item.pcmodel == 'tbmanagemenu'" />
            <Smartform v-if="item.linkurl == '/pages/plat/smartform'" />
            <DepartList v-if="item.pcmodel == 'tbmanagedepart'" />
            <Tbmanageoutlet v-if="item.pcmodel == 'tbmanageoutlet'" />
            <!--产品 -->
            <BrandList v-if="item.pcmodel == 'TbBrand'" />
            <CategoryList  v-if="item.pcmodel == 'TbCategory'"/>
            <SpuinfoList v-if="item.pcmodel == 'TbSpuInfo'"/>
            <AttrGroup v-if="item.pcmodel == 'TbAttrGroup'" />
            <AttrList v-if="item.pcmodel == 'TbAttr'" />
            <TbMatter v-if="item.pcmodel == 'TbMatter'" />
          </div>
        </div>
      </div>
    </Layout>
  </Layout>
</template>

<script>
//组件
import userAvatar from "@/components/main/user/user.vue";

//页面
import Request from "@/utils/baseInterface.js";
import UserList from "@/views/system/user/userlist";
import RoleList from "@/views/system/role/rolelist";
import MenuList from "@/views/system/menu/menulist";
import Smartform from "@/views/plat/smartform";
import DepartList from "@/views/system/depart/departlist";
import HomeIndex from "@/views/system/home/home";
import Tbmanageoutlet from "@/views/system/outlet/tbmanageoutlet";

//静态资源
import darkImg from "@/assets/main/logo-unut.png";
import smallImg from "@/assets/main/logo-small.png";

//产品
import BrandList from "@/views/product/brand/brandlist";
import CategoryList from "@/views/product/category/categorylist";
import SpuinfoList from "@/views/product/spuinfo/spuinfolist";
import AttrGroup from "@/views/product/attrgroup/attrgrouplist";
import AttrList from "@/views/product/attr/attrlist"
import TbMatter from "@/views/product/tbmatter/tbmatter"
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
} from "@/utils/localStorage.js";
import {formatterAsycMenu} from "@/libs/util.js"
export default {
  name: "home",
  computed: {
    // 收缩的图标
    rotateIcon() {
      return ["menu-icon", this.isCollapsed ? "rotate-icon" : ""];
    },
    // 导航收缩
    menuitemClasses() {
      return ["menu-item", this.isCollapsed ? "collapsed-menu" : ""];
    },
    iconSize() {
      return this.spanLeft === 5 ? 14 : 24;
    },
  },
  data() {
    return {
      tabsColor: "primary",
      tagdatas: [], //也签数据
      menudatas: [],
      isCollapsed: false, // 是否收缩
      themeType: "light", // 主题风格
      logoimglarge: darkImg, //头像 大图
      logoimgsmall: smallImg, //小图
      spanLeft: 5,
      spanRight: 19,
    };
  },
  components: {
    userAvatar,
    UserList,
    RoleList,
    HomeIndex,
    MenuList,
    Smartform,
    DepartList,
    Tbmanageoutlet,
    BrandList,
    CategoryList,
    SpuinfoList,
    AttrGroup,
    AttrList,
    TbMatter
  },
  mounted() {},
  created() {
    var tab = {};
    tab.mid = "0001";
    tab.title = "首页";
    tab.pcmodel = "homeindex";
    tab.linkurl = "/pages/system/home/home";
    tab.active = true;
    tab.isClose = false; //是否可以关闭
    this.tagdatas.push(tab);

    var parma = {};
    Request({
      url: "/empapi/sys/tbsysemploy/getpcemploymenudatas",
      method: "post",
      data: parma,
    }).then((res) => {
      this.menudatas = res.data.datas;
      var marr=formatterAsycMenu(res.data.datas);
      setlocalStorage("menus", marr);
      // window.console.log("menudatas:", this.menudatas);
    });
  },
  methods: {
    //
    // 收缩切换
    collapsedSider() {
      // this.$refs.side1.toggleCollapse();
    },
    opentab(data) {
      var tab = {};
      tab.mid = data.id;
      tab.title = data.mname;
      tab.linkurl = data.linkurl;
      tab.pcmodel = data.pcmodel;
      tab.active = true;
      tab.isClose = true; //是否可以关闭
      if (this.tagdatas) {
        var haseexist = false;
        //判断页签是否存在  如果不存在则加入，存在则点亮
        this.tagdatas.forEach((item) => {
          if (item.mid == tab.mid) {
            item.active = true;
            haseexist = true;
          } else {
            item.active = false;
          }
        });
        //不存在
        if (!haseexist) {
          this.tagdatas.push(tab);
          // console.log(this.tabsList);
        }
      }
       window.console.log("click:",tab);
    },
    handleClose(data) {
      if (this.tagdatas) {
        var ind_ = 0;
        var actived = false; //判断当前页签是否被选中，如果未被选中直接移除即可
        this.tagdatas.forEach((item, index) => {
          if (item.mid == data.mid) {
            ind_ = index;
            if (item.active) {
              actived = true;
            }
          }
        });
        this.tagdatas.splice(ind_, 1);
        if (actived) {
          //未被选中直接移除，选中了要重新定位选中项
          if (ind_ > 1) {
            this.tagdatas[ind_ - 1].active = true;
          } else {
            this.tagdatas[0].active = true;
          }
        }
      }
    },
    selectTabs(data) {
      // window.console.log(data);
      if (this.tagdatas) {
        this.tagdatas.forEach((item) => {
          if (item.mid == data.mid) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
      }
    },
    //关闭全部
    closealltab() {
      var tagdatas_ = [];
      var tab = {};
      tab.mid = "0001";
      tab.title = "首页";
      tab.pcmodel = "homeindex";
      tab.linkurl = "/pages/system/home/home";
      tab.active = true;
      tab.isClose = false; //是否可以关闭
      tagdatas_.push(tab);
      this.tagdatas = tagdatas_;
    },
    //关闭其他
    closeother() {
      var tagdatas_ = [];
      var currenttab = this.tagdatas.find((t) => t.active == true);
      if (currenttab.mid == "0001") {
        tagdatas_.push(currenttab);
      } else {
        var tab = {};
        tab.mid = "0001";
        tab.title = "首页";
        tab.pcmodel = "homeindex";
        tab.linkurl = "/pages/system/home/home";
        tab.active = false;
        tab.isClose = false; //是否可以关闭
        tagdatas_.push(tab);
        tagdatas_.push(currenttab);
      }
      this.tagdatas = tagdatas_;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  border-bottom: 1px solid #0095bf;
  height: 64px;
  line-height: 64px;
  text-align: center;
  overflow: hidden;
  background: #0095bf;
}
.logo img {
  height: 80%;
  vertical-align: middle;
}
.header {
  background: #0095bf;
  -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 64px;
  line-height: 64px;
  color: #fff;
  padding: 0;
 
}
.header_left {
  color: #fff;
  min-width: 300px;
  width: auto;
  padding-left: 15px;
}
.header_right {
  min-width: 240px;
  padding-right: 40px;
}
.header_right span {
  margin: 5px;
}
.flexR {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.tabsNav.flexR {
  padding: 10px 20px;
}

.themeLight {
  background: #ffffff;
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
}
.layout-breadcrumb {
  padding: 10px 15px 0;
  text-align: left;
}
.layout-content {
  min-height: 800px;
  margin: 15px;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  /* height: calc(100vh - 200px); */
  overflow-y: auto;
}
.layout-content-main {
  padding: 10px;
}
.layout-copy {
  text-align: center;
  padding: 10px 0 20px;
  color: #9ea7b4;
}
.layout-menu-left {
  /* background: #464c5b; */
}
.layout-header {
  height: 60px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-align: left;
  line-height: 55px;
  padding-left: 30px;
}
.layout-logo-left {
  width: 90%;
  height: 30px;
  /* background: #5b6270; */
  border-radius: 3px;
  margin: 15px auto;
}
.ivu-layout-sider {
  min-height: 100vh;
}
.hidetabcontent {
  display: none;
}
.shrink{
  display: none;
}
</style>
