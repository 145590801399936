<template>
    <div class="formitembox-mm">
         <FormItem :label="formattertitle(item)" :prop="item.field">
            <DatePicker ref="formitemchange"  :class="item.correct==true?'':'error'" :type="item.datetype" :disabled="!item.canedit"
         :format="item.dataformat" @on-change="selecteddate" :value="item.defvalue" placeholder="请选择日期"  style="width:92%;"></DatePicker>
         </FormItem>
    </div> 
</template>
<script>
    export default {
    data(){
        return{
           
        }
    },
    created(){
      
    },
   props:{
       item:{
           type:Object,
           value:null
       }
   },
   methods:{
   formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    selecteddate(e){
        let e_={};
        e_.filedname=this.item.field;
         e_.val=e;
         this.item.defvalue=e;
        this.$emit("formitemchange",e_);
       }
   }
}
</script>
<style  scoped>
</style>