/**
 * 表单验证规则
 * @param {*} rule 
 * @param {*} value 
 */
function validity_(rule,value){
    console.log("validity_.value:",value);
    var reg={rule:rule};
    var ret={};
    if(reg.rule=="*"){
        if(value!=null&&value!=undefined&&!(value==="")){  ret.success=true; }
        else{
            reg.msg="不允许为空，请填写数据";
             ret.success=false; ret.msg=reg.msg; }
    }else if(reg.rule.indexOf("s")==0){
        var fw=reg.rule.slice(1);
        var arr=fw.split("_");
        if(arr.length!=2){
           var st=parseInt(arr[0]);var en=parseInt(arr[1]);
           if(st&&en&&st!=NaN&&en!=NaN){
            if(!value){
                { ret.success=false; ret.msg=reg.msg; }
            }else if(!(value.length<en&&value.length>=st)){
                { ret.success=false; ret.msg=reg.msg; }
            }else{
                ret.success=true;
            }
           }else{
            ret.success=false; ret.msg="未知的校验规则:"+ret.rule;
           }
        }else{
            ret.success=false; ret.msg="未知的校验规则:"+ret.rule;
        }
    }else if(reg.rule.indexOf("i")==0){
       // console.log("55555");
        var fw=reg.rule.slice(1);
        var arr=fw.split("_");
        if(arr.length!=2){
           var st=parseInt(arr[0]);var en=parseInt(arr[1]); var val=parseInt(vaue);

           if(st&&en&&val&&st!=NaN&&en!=NaN&&val!=NaN){
            ret.rule="请填写范围在"+st+"到"+en+"直接的整数";
            if(!val){
                { ret.success=false; ret.msg=reg.msg; }
            }else if(!(val<=en&&val>=st)){
                { ret.success=false; ret.msg=reg.msg; }
            }else{
                ret.success=true;
            }
           }else{
            ret.success=false; ret.msg="未知的校验规则:"+ret.rule;
           }
        }else{
            ret.success=false; ret.msg="未知的校验规则:"+ret.rule;
        }
        
    }
    // console.log(ret);
    return ret;
}

export default validity_;