<template>
  <div class="formitembox-mm">
    <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <input type="hidden" v-model="formitem.defvalue" />
    <Input
      icon="md-funnel"
      @on-blur="itemchange"
      @on-enter="opendataselect"
      @on-click="opendataselect"
      v-model="formitem.deftext"
       :disabled="formitem.canedit == true ? false : true"
      style="width:92%;height:29px"
    />
     </FormItem>
    <Modal
      v-model="isshow"
      width="55vw"
      title="角色数据选择器"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="modalcontent">
           <div class="flex" >
              <div span="4">已选择数据：</div>
              
            <div  v-for="(item,index) in selectdatas" :key="index" :data-id="item.id">
            <Tag closable @on-close="removerow(item)">{{item.text}}</Tag>
            </div>
        </div>
         
        <div class="tables">
        <vxe-table
        border="full"
        size="mini"
        align="center"
       
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        resizable
        stripe
        class="Tables"
          :data="datagrid.datas"
        >
          <vxe-table-column  type="seq" width="60" ></vxe-table-column>
         
          <vxe-table-column
            v-for="(item,index) in datagrid.tableColumn"
            :key="index"
            :field="item.field"
            :title="item.title"
            :width="item.colwidth?item.colwidth:120"
            show-header-overflow
            show-overflow="title"
            show-footer-overflow
          ></vxe-table-column>
          <vxe-table-column title="操作" width="150" show-overflow align="center">
             <template slot-scope="scope">
                <vxe-button status="success" v-if="!existrowselected(scope.row)" size="mini" type="text" icon="checkmark-round" @click="selectrow(scope.row)">选择</vxe-button>
                <vxe-button status="danger" v-if="existrowselected(scope.row)" size="mini" type="text" icon="close-round" @click="removerow(scope.row)">移除</vxe-button>
              </template>
          </vxe-table-column>

        </vxe-table>

        

         <vxe-pager
            :current-page="datagrid.cpage?datagrid.cpage:0"
            :page-size="datagrid.rows?datagrid.rows:10"
            :total="datagrid.total?datagrid.total:0"
           
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']"
            @page-change="pagechanged"
        ></vxe-pager>
        </div>
      </div>
    </Modal>
   
  </div>
</template>
<script>
import Datautil from "@/utils/datautil";
import Request from '@/utils/baseInterface.js';
export default {
  components: {
    
  },
  data() {
    return {
     
        isshow: false,
        datagrid: {tableColumn:[], datas:[], total:0,optwidth:240},
        quickquery:{formitems:[]},
        queryparma:{page:1,rows:10},//查询参数
     
       itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,caneidt:false},
                    {title:"门店名称",field:"outletname",ctype:"simpletext",isshowlist:true,isquery:false},
                    {title:"门店地址",field:"outletaddress",ctype:"simpletext",isshowlist:true,defvalue:null,isquery:false},
                    {title:"联系电话",field:"outletphone",ctype:"simpletext",isshowlist:false,defvalue:null,isquery:false},
                    {title:"门店状态",field:"ostate",ctype:"dropselect", defvalue:null, options: [{  id: 1, text: '启用' }, { id: 2, text: '停用' }],},
                ],
                selectdatas:[]
    };
  },
  created() {
     //加载用户表数据
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.datagrid.tableColumn=columndata;
            this.model=1;//设置单选
            this.textfiled='outletname';
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            this.LoadDatas();
  },
  updated() {},
  props: {
    formitem: {
      type: Object,
      value: null,
    },
  },
  methods: {
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    opendataselect(){
        this.isshow = true;
    },
    itemchange(e) {
      
      let d_ = {};
      let filedname = this.formitem.field;
      let val = this.formitem.defvalue; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
  
    LoadDatas(){
      /*
        Request({url:"/employapi/outlet/datalist",method:"post",data:this.queryparma}).then(res=>{
    
        this.datagrid.datas=Datautil.$formattrgriddatas(res.datas,this.datagrid.tableColumn);
        this.datagrid.total=res.total;
        });
*/
    },
     selectrow(row){
      var i={};
        i.id=row.id;
        i.text=row[this.textfiled];
        // if(this.selecttable.model=="1"){
            this.selectdatas=[];
            this.selectdatas.push(i);
        // }else if(this.selecttable.model=="2"){
            // var t_= this.selectdatas.filter( t=> t.id==row.id);
            // if(t_.length==0){
            //     this.selectdatas.push(i);
            // }
        //}
    },
    removerow(row){
      //1. 查找下标
        if(this.selectdatas){
            this.selectdatas.forEach((item, index) => {
                if(item.id==row.id){
                    this.selectdatas.splice(index,1);
                }
            });
        }
    },
    /**
     * 查询当前数据化是否被选中
     */
    existrowselected(row){
        var t_= this.selectdatas.filter( t=> t.id==row.id);
        if(t_.length==0){
            return false;
        }else{
            return true;
        }
    },
    pagechanged({ currentPage }){
      
        this.queryparma.page=currentPage;
        this.LoadDatas();
        
    },
    ok() {
      if (!this.selectdatas || this.selectdatas.length < 1) {
        this.$Message.info({
          content: "您没有选择数据",
          duration: 2,
        });
      } else {

       if(this.selectdatas){
           var ids_=[];
           var texts_=[];
           this.selectdatas.forEach(item=>{
               ids_.push(item.id);
               texts_.push(item.text);
           });
           this.formitem.defvalue=ids_.join(",");
           this.formitem.deftext=texts_.join(",");
          
       }
       
      }
    },
    cancel() {},
    //加载默认配置数据
    loaddefaultdata(){
      if(this.formitem.deftext){
        var selectdatas_=[];
        var deftext_=this.formitem.deftext.split(",");
        var defvalue_=this.formitem.defvalue.split(",");
        deftext_.forEach((item,index)=>{
          var tem_={};
          tem_.id=defvalue_[index];
          tem_.text=deftext_[index];
          selectdatas.push(tem_);
        });
        this.selectdatas=selectdatas_;
      }
    },
  },
};
</script>
<style   scoped>
.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}
</style>