<template>

    <div style="width: 1200px; height: 600px; position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);">
     <video
        id="myVideo"
        class="video-js"
        >
        <source
            :src="baseUrl"
            type="video/mp4"
        >
        </video>
    </div>

</template>

<script>

import Request from '@/utils/baseInterface.js';

export default {
    data() {
      return {
        baseUrl:'https://qxask.oss-cn-chengdu.aliyuncs.com/',
       
      }
    },
    mounted() { 
        this.initVideo();
    },
    components: {
    
    },
    created(){
      this.baseUrl=this.baseUrl+this.$route.query.obj;
       console.log("baseUrl:"+this.baseUrl)
        
        // window.console.log("code:",this.$route.query.code);
        // let parma={code:this.$route.query.code};
        // // parma.code=this.$route.query.code;
        // // parma.nickname="123";
        // Request({url:"/employapi/tbManageEmployController/pclogin",method:"post",data:parma}).then(res=>{
        //   window.console.log(res);
        // });
    },
    methods:{
       initVideo() {
        
        //初始化视频方法
        let myPlayer = this.$video(myVideo, {
            //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
            controls: true,
            //自动播放属性,muted:静音播放
            autoplay: "muted",
            //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",
            //设置视频播放器的显示宽度（以像素为单位）
            width: '1200px',
            //设置视频播放器的显示高度（以像素为单位）
            height: '600px'
        });
        },

    }
  }
</script>