<template>
  <div class="formitembox-mm">
      <FormItem :label="formattertitle(item)" :prop="item.field">
    <input 
      ref="formitemchange"
      type="text"
      class="iteminpu"
      :class="item.correct==true?'':'error'"
      @input="oninput"
       :disabled="item.canedit==true?false:true"
      @change="itemchange"
      v-model="item.defvalue"
    />
     </FormItem>
  </div>
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
export default {
  data() {
    return {
      
    };
  },
  created() {
   
  },
  updated(){
    
  },
  props: {
    item: {
      type: Object,
      value: null
    }
  },
  methods: {
    //格式化标题
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    itemchange(e) {
      let d_ = {};
      let filedname = this.item.field;
      let val = e.currentTarget.value; //取值
      d_.filedname = filedname;
      d_.val = val;
      this.$emit("formitemchange", d_);
    },
    oninput(e) {
      //window.console.log(e);
      if(this.item.datatype){
         var validateret= validity_(this.item.datatype,this.item.defvalue);
        if(!validateret.success){
          this.item.correct=false;
          //console.log(validateret);
        }else{
          this.item.correct=true;
        }
      }
    
    }
  }
};
</script>
<style  scoped>

</style>