import {
    getDictOption,getlocalStorage
  } from "@/utils/localStorage.js";
 
var datautil={
    /**
     * 根据配置信息拿到grid配置列
     * @param {*} items 
     */
    $getGridColumn(items){
        var columns=[];
        items.forEach((item) => {
            item.isshowlist = this.getdefault( item.isshowlist);
            if(item.isshowlist){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                
                if(item.options){
                    c.options =item.options;
                }
                columns.push(c);
            }
        });
        return columns;
    },
      /**
     * 根据配置信息拿到grid配置列
     * @param {*} items 表单数据
     * @param {*} opt 操作数据 默认list
     * 
     */
    $getGridColumnExt(items,opt){
        var columns=[];
        items.forEach((item) => {
            opt=opt?opt:'list';
           var showConfig=item.showConfig?item.showConfig:"";
            if((showConfig).indexOf(opt)!=-1){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.dictCode){
                    c.dictCode=item.dictCode
                }
                if(item.targetfiled){
                    c.targetfiled=item.targetfiled
                }
              
                columns.push(c);
            }
        });
        return columns;
    },
     /**
     * 根据配置信息拿到grid配置列
     * @param {*} items iview专用
     */
    $getGridColumnIview(items){
        var columns=[];
        items.forEach((item) => {
            item.isshowlist = this.getdefault( item.isshowlist);
            if(item.isshowlist){
                var c={};
                c.key=item.field;
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.pkcol){
                    c.width= 60;
                    c.type="selection";
                }
                if(item.options){
                    c.options =item.options;
                }
                columns.push(c);
            }
        });
        return columns;
    },
     /**
     * 获取编辑的表单列
     * @param {*} items 
     */
    $getAddFormItemsExt(items,opt){
        opt=opt?opt:"add";
        var columns=[];
        items.forEach((item) => {
            var showConfig =item.showConfig?item.showConfig:"";
            var editConfig =  item.editConfig?item.editConfig:"";
            item.pkcol = item.pkcol?item.pkcol:false;
            if(showConfig.indexOf(opt)!=-1){
                if(!item.pkcol){
                    var c={};
                    c.field=item.field;
                    c.title=item.title;
                    c.ctype=item.ctype;
                    c.correct=item.correct?item.correct:true;
                    c.defvalue=item.defvalue;
                    c.datatype = item.datatype;
                    c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                    c.canedit =  editConfig.indexOf(opt)!=-1?true:false;//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                    c.targetfiled=item.targetfiled?item.targetfiled:null;
                    if(item.ctype == 'dropselect'){
                        var options_=getDictOption(item.dictCode);
                        c.options=options_;
                    }
                   
                    if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                        c.maxlength = item.maxlength;
                    }
                    if(item.ctype == 'videoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                    }
                    if(c.ctype == 'dateselect'){
                        c.datetype='date'
                        c.dataformat='yyyy-MM-dd'
                    }
                    else if(c.ctype == 'datetime'){
                        c.datetype='datetime'
                        c.dataformat='yyyy-MM-dd HH:mm:ss'
                    }else if(c.ctype == 'selectdicttable'){
                        c.dictTable=item.dictTable;
                    }
                    c.validation=item.validation;
                    columns.push(c);
                }
               
            }
        });
        return columns;
    },
    /**
     * 获取编辑的表单列
     * @param {*} items 
     */
    $getAddFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.isshowadd =  this.getdefault(item.isshowadd);
            item.pkcol = item.pkcol?item.pkcol:false;
            if(item.isshowadd){
                if(!item.pkcol){
                    var c={};
                    c.field=item.field;
                    c.title=item.title;
                    c.ctype=item.ctype;
                    c.correct=item.correct?item.correct:true;
                    c.defvalue=item.defvalue;
                    c.datatype = item.datatype;
                    c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                    c.canedit = this.getdefault(item.canedit);//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                    c.targetfiled=item.targetfiled?item.targetfiled:null;
                    if(item.ctype == 'dropselect'){
                        var options_=getDictOption(item.dictCode);
                        c.options=options_;
                    }
                   
                    if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                        c.maxlength = item.maxlength;
                    }
                    if(item.ctype == 'videoupload'){
                        c.baseurl = item.baseurl;
                        c.uploadurl = item.uploadurl;
                    }
                    if(c.ctype == 'dateselect'){
                        c.datetype='date'
                        c.dataformat='yyyy-MM-dd'
                    }
                    else if(c.ctype == 'datetime'){
                        c.datetype='datetime'
                        c.dataformat='yyyy-MM-dd HH:mm:ss'
                    }else if(c.ctype == 'selectdicttable'){
                        c.dictTable=item.dictTable;
                    }
                    c.validation=item.validation;
                    columns.push(c);
                }
               
            }
        });
        return columns;
    },
    $getEditFormItemsExt(items,opt){
        opt=opt?opt:"edit";
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            var showConfig =  item.showConfig?item.showConfig:"";
            var editConfig =  item.editConfig?item.editConfig:"";
            if(showConfig.indexOf(opt)!=-1){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.correct=item.correct?item.correct:true;
                c.defvalue=item.defvalue;
                c.canedit = editConfig.indexOf(opt)!=-1?true:false;//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                c.validation=item.validation;
                c.datatype = item.datatype;
                c.targetfiled=item.targetfiled?item.targetfiled:null;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
                columns.push(c);
               
            }
        });
        return columns;
    },
    $getEditFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowedit =  this.getdefault(item.isshowedit);
            
            if(item.isshowedit){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.correct=item.correct?item.correct:true;
                c.defvalue=item.defvalue;
                c.canedit =  this.getdefault(item.canedit);//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                c.validation=item.validation;
                c.datatype = item.datatype;
                c.targetfiled=item.targetfiled?item.targetfiled:null;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
                columns.push(c);
               
            }
        });
        return columns;
    },
    $getAuditFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowaudit =  this.getdefault(item.isshowaudit);
            if(item.isshowaudit){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.correct=item.correct?item.correct:true;
                c.defvalue=item.defvalue;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.canedit =  this.getdefaultext(item.auditcanedit);//如果新增模式下已设置是否可编辑，那么使用配置里面的数值，默认可编辑
                c.validation=item.validation;
                c.datatype = item.datatype;
                c.targetfiled=item.targetfiled?item.targetfiled:null;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }
                columns.push(c);
               
            }
        });
        return columns;
    },
    $getDetailFormItemsExt(items,opt){
        opt=opt?opt:"detail";
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            var showConfig =item.showConfig?item.showConfig:"";
           
            if(showConfig.indexOf(opt)!=-1){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.correct=true;
                c.defvalue=item.defvalue;
                c.canedit=false;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.datatype=item.datatype;
                c.targetfiled=item.targetfiled;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
                columns.push(c);
            }
        });
        return columns;
    },
    $getDetailFormItems(items){
        var columns=[];
        items.forEach((item) => {
            item.pkcol = item.pkcol?item.pkcol:false;
            item.isshowdetail = this.getdefault(item.isshowdetail);
            if(item.isshowdetail){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.correct=true;
                c.defvalue=item.defvalue;
                c.canedit=false;
                c.isshow=true;//这里已经是表单里面显示了，所以该参数直接赋值true
                c.datatype=item.datatype;
                c.targetfiled=item.targetfiled;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(item.ctype == 'imageupload'||item.ctype == 'photoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                    c.maxlength = item.maxlength;
                }
                if(item.ctype == 'videoupload'){
                    c.baseurl = item.baseurl;
                    c.uploadurl = item.uploadurl;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetime'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
                columns.push(c);
            }
        });
        return columns;
    },
    /**
     * 获取查询表单
     * @param {} items 
     * @returns 
     */
    $getQueryFormItemsExt(items,opt){
        opt=opt?opt:"list";
        var columns=[];
        items.forEach((item) => {
            var queryConfig=item.queryConfig?item.queryConfig:"";
            if(queryConfig.indexOf(opt)!=-1){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='daterange'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetimerange'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
               
                c.correct=true;
                c.canedit=true;
               // console.log("c:",c);
                columns.push(c);
            }
        });
        return columns;
    },
    /**
     * 获取查询表单
     * @param {} items 
     * @returns 
     */
    $getQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='daterange'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetimerange'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'selectdicttable'){
                    c.dictTable=item.dictTable;
                }
               
                c.correct=true;
                c.canedit=true;
               // console.log("c:",c);
                columns.push(c);
            }
        });
        return columns;
    },
    $getChartQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;
                c.defvalue=item.defvalue;
                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='date'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'monthselect'){
                    c.datetype='month'
                    c.dataformat='yyyy-MM'
                    c.defvalue=this.getCurrentMonth();
                }else if(c.ctype == 'yearselect'){
                    c.datetype='year'
                    c.dataformat='yyyy'
                    c.defvalue=this.getCurrentYear();
                }
               
                c.correct=true;
                c.canedit=true;
               // console.log("c:",c);
                columns.push(c);
            }
        });
        return columns;
    },

    
    $resetQueryFormItems(items){
        var columns=[];
        items.forEach((item) => {
            if(item.isquery){
                var c={};
                c.field=item.field;
                c.title=item.title;
                c.ctype=item.ctype;

                if(item.ctype == 'dropselect'){
                    var options_=getDictOption(item.dictCode);
                    c.options=options_;
                }
                if(c.ctype == 'dateselect'){
                    c.datetype='daterange'
                    c.dataformat='yyyy-MM-dd'
                }
                else if(c.ctype == 'datetime'){
                    c.datetype='datetimerange'
                    c.dataformat='yyyy-MM-dd HH:mm:ss'
                }else if(c.ctype == 'monthselect'){
                    c.datetype='month'
                    c.dataformat='yyyy-MM'
                    c.defvalue=this.getCurrentMonth();
                }else if(c.ctype == 'yearselect'){
                    c.datetype='year'
                    c.dataformat='yyyy'
                    c.defvalue=this.getCurrentYear();
                }
               
                c.defvalue=null;
               c.deftext=null;
                c.correct=true;
                c.canedit=true;
                console.log("c:",c);
                columns.push(c);
                
            }
        });
        return columns;
    },
    $loadDetailData(items,data){
      
        items.forEach((item) => {
            if(data[item.field]){
                item.defvalue=data[item.field]+"";
            }
            //选项是从上一步传递过来的  不需要再次加载
            // if(item.ctype == 'dropselect'){
            //     var options_=getDictOption(item.dictCode);
            //     item.options=options_;
            // }
        //    if(item.ctype=='dropselect'){
        //         var arr=item.options;
        //         var deftexts_=[];
        //         if(arr){
        //             arr.forEach(i=>{
        //             var dev=item.defvalue+"";
        //             var itemval=i.id+"";
        //             // console.log("dev:",dev,"itemval:",itemval);
        //             if(dev&&dev.indexOf(itemval)!=-1){
        //                 deftexts_.push(i.text);
        //             }
        //             });
        //             item.deftext=deftexts_.join(",");
        //         }
        //     }
            if(item.targetfiled){
                item.deftext=data[item.targetfiled]?data[item.targetfiled]:null;
            }
            // console.log(item);
        });
        return items;
    },
    
    $getimglength(defvalue){
        if(defvalue!=null&&defvalue!=undefined&&defvalue!=""){
            var arr=defvalue.split(",");
            return arr.length;
        }else{
            return 0;
        }
    },
    /**
     * 根据键和值查询数组对象中的对象（只返回一个）
     * @param {*} data 
     * @param {*} key 
     * @param {*} val 
     */
    finditem(data, key, val) {
        let ret = null;
        if (data) {
            data.forEach(item => {
                if (item[key] == val) {
                    ret = item;
                }
            });
        }
        return ret;
    },
    /**
     * 获取当前年份
     */
    getCurrentYear(){
        let yy = new Date().getFullYear();
        return yy+"";
    },
     /**
     * 获取当前月份
     */
    getCurrentMonth(){
        let yy = new Date().getFullYear();
        let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
        return yy +"-"+ mm;
    },
    $formattrgriddatasExt(data,columns){
        var datas_ = data;
        datas_.forEach(item1=>{

            columns.forEach(item=>{
                
                if(item.ctype == 'dropselect'){
                    var text_=[];
                    var options_ = getDictOption(item.dictCode);
                    var temp_=item1[item.field]?item1[item.field]+"":"";
                    if(options_){
                        options_.forEach(o=>{
                            if(temp_.indexOf(o.id)!=-1){
                                text_.push(o.text);
                            }
                        });
                        item1[item.field]=text_.join(",")
                    }
                }
            })
        })
        
        return datas_;
    },
    /**
     * 格式化列表数据
     * @param {*} data  原始数据
     * @param {*} columns 表格列
     */
    $formattrgriddatas(data,columns){
        var datas_ = data;
        datas_.forEach(item1=>{

            columns.forEach(item=>{
               
                if(item.targetfiled){
                    item1[item.field]=item1[item.targetfiled];
                }else{
                    if(item.ctype == 'dropselect'){
                        var options_ = getDictOption(item.dictCode);;
                        if(options_){
                            var oldval =item1[item.field];
                            var arr =options_.filter(item=>item.id==oldval);
                            if(arr&&arr.length>0&&arr[0].text){
                                item1[item.field] = arr[0].text 
                            }
                        }
                    }
                }
            })
        })
        
        return datas_;
    },

    getdefault(val){
        if(val == null||val==undefined||val==true){
            return true;
        }else{
            return false;
        }
    },

    getdefaultext(val){
        if(val == null||val==undefined){
            return false;
        }else{
            return true;
        }
    },
    isadminrole(){
        let roletype = getlocalStorage("roletype");
        if (roletype=="p")
        {
           return true;
        }else{
            return false;
        }
    }
};
export default datautil
