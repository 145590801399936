<template>
  <div>
    <Modal
      v-model="selecttable.isshow"
      width="70vw"
      :title="selecttable.title"
      :loading="loading"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="modalcontent">
          <div class="flex" >
              <div span="4">已选择数据：</div>
              
            <div  v-for="(item,index) in selectdatas" :key="index" :data-id="item.id">
            <Tag closable @on-close="removeitem(item)">{{item.text}}</Tag>
            </div>
        </div>
         
        <div class="tables">

        <vxe-table
        border="full"
        size="mini"
        align="center"
        highlight-current-row
        highlight-hover-row
        highlight-current-column
        highlight-hover-column
        resizable
        stripe
        class="Tables"
        @cell-click="selectrow"
          :data="selecttable.datagrid.datas"
        >
          <vxe-table-column v-if="selecttable.mode==2" type="checkbox" width="60" fixed="left"></vxe-table-column>
         
          <vxe-table-column
            v-for="(item,index) in selecttable.datagrid.tableColumn"
            :key="index"
            :field="item.field"
            :title="item.title"
            min-width="120"
            show-header-overflow
            show-overflow="title"
            show-footer-overflow
          ></vxe-table-column>
          

        </vxe-table>

         <vxe-pager
            :current-page="selecttable.datagrid.cpage?selecttable.datagrid.cpage:0"
            :page-size="selecttable.datagrid.rows?selecttable.datagrid.rows:0"
            :total="selecttable.datagrid.total?selecttable.datagrid.total:0"
           
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']"
            @page-change="pagechanged"
        ></vxe-pager>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
 import Request from '@/utils/baseInterface.js';
export default {
  components: {
    quickqueryform
  },
  data() {
    return {
      selectdatas: [],
      loading: false,
      queryparma:{},
    };
  },
  created() {
      // if(this.selecttable.requesturl){
      //   this.queryparma.page=this.cpage;
      //   this.queryparma.rows=this.rows;
      //   this.LoadDatas(this.selecttable.requesturl);
      // }
  },
  updated() {
    
  },
  props: {
    selecttable: {
      type: Object,
      value: null,
    },
  },
 

  methods: {
    ok() {
      if (!this.selectdatas || this.selectdatas.length < 1) {
        this.$Message.info({
          content: "您没有选择数据",
          duration: 2,
        });
      } else {
        
        var retdata = {};


       if(this.selectdatas){
           var ids_=[];
           var texts_=[];
           this.selectdatas.forEach(item=>{
               ids_.push(item.id);
               texts_.push(item.text);
           });
           retdata.ids=ids_;
           retdata.texts=texts_;
       }
        this.$emit("tableselectedcallback", retdata);
        this.loading = false;
      }
    },
    cancel() {},
    
    pagechanged({ currentPage }){
       if(this.selecttable.requesturl){
            this.queryparma.page=currentPage;
            this.LoadDatas(this.selecttable.requesturl);
         }
    },
    selectrow({row}){
        var i={};
        i.id=row.id;
        i.text=row[this.selecttable.textfiled];
        console.log("I:",i);
        if(this.selecttable.model=="1"){
            this.selectdatas=[];
            this.selectdatas.push(i);
        }else if(this.selecttable.model=="2"){
            var t_= this.selectdatas.filter( t=> t.id==row.id);
            if(t_.length==0){
                this.selectdatas.push(i);
            }
        }
    
    },
    removerow(row){
        //1. 查找下标
        if(this.selectdatas){
            this.selectdatas.forEach((item, index) => {
                if(item.id==row.id){
                    this.selectdatas.splice(index,1);
                }
            });
        }
    },
    existrowselected(row){
        var t_= this.selectdatas.filter( t=> t.id==row.id);
        if(t_.length==0){
            return false;
        }else{
            return true;
        }
    },
    removeitem(data){
         if(this.selectdatas){
            this.selectdatas.forEach((item, index) => {
                if(item.id==data.id){
                    this.selectdatas.splice(index,1);
                }
            });
        }
    },
    onsubmitquerydata(e){
         if(this.selecttable.requesturl){
            this.queryparma=e;
            this.queryparma.page=1;
            this.queryparma.rows=this.rows;
            this.LoadDatas(this.selecttable.requesturl);
         }
    },
    LoadDatas(url_){
     
        Request({url:url_,method:"post",data:this.queryparma}).then(res=>{
        this.selecttable.datagrid.datas=res.datas;
        //this.selecttable.datagrid.total=res.total;
        this.selecttable.datagrid.total=5;
        window.console.log(res);
        });
    },
  },
};
</script>
<style >
.tables {
  margin-top: 8px;
}
.ivu-page-item-active {
  background: #0095bf !important;
}
.page_box {
  
  text-align: right;
  margin: 8px 0px;
}

.modalcontent {
  overflow: auto;
}
.rows {
  display: inline-block;
  margin-left: 10px;
}
.query-box {
  display: inline-block;
  /* min-width: 70px; */
  height: auto;
  /* padding-left: 8px; */
 
}
.vxe-table .vxe-table--empty-content {
  left: 50% !important;
  top: 50% !important;
}
.flex{
    display: flex;
}
</style>