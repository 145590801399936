<template>
  <div  class="formitembox-lg">
<FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <div class="photobox1" >
        <div class="uploadimages1" :data-canedit="formitem.canedit" >
            <div class="imagebox1"  v-for="(item,ind) in getImagedatas(formitem.defvalue)" :key="ind">
                <img :src="item.realurl" :suburl="item.suburl" />
                <div class="demo-upload-list-cover1">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.realurl)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(item.suburl)"></Icon>
                </div>
            </div>
        </div>
        <div class="filebox1"  @click="openMatterSelect">
                 <div style="width: 60px;height:60px;line-height: 52px;" >
                     <Icon type="ios-add" size="56"></Icon>
                </div>
        </div>
    </div>
        </FormItem>
      <Modal
      v-model="isshow"
      width="68vw"
      title="素材选择器"
      :mask-closable="false"
        >
        <div class="modalcontent">
            <Row style="height: 60px;border-bottom: 1px solid #e1e1e8;margin-bottom: 12px;line-height: 20px;">

                <Alert>点击左侧树形表格的“素材”按钮可以根据分类快速定位图片素材</Alert>
                
            </Row>
            <Row>
                <Col span="6">
                    <vxe-table
                            resizable keep-source border="full" size="mini" ref="xTree" row-id="id"   
                            :tree-config="{lazy: true,labelField: 'classifyName',hasChild: 'hasChild',loadMethod: loadchildClassfyData,children: 'childs'}"
                        max-height="700"
                            :data="classfyDatas">
                        <vxe-table-column field="id" title="编号" :visible="false"  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                        <vxe-table-column field="classifyName" title="分类标题" tree-node  show-header-overflow show-overflow="title"  show-footer-overflow ></vxe-table-column>
                         <vxe-table-column title="操作" width="80" fixed="right" show-overflow> 
                            <template #default="{ row }">
                                <vxe-button type="text" status="primary" icon="vxe-icon--menu" @click="showmatter(row)" v-if="showoptionbutton(row,'hasChild#ne#true')">素材</vxe-button>  
                            </template> 
                            </vxe-table-column>
                    </vxe-table>
                </Col>
                <Col span="18">
                    <div >
                        <CheckboxGroup class="matters" v-model="selectmatters" @on-change="matterSelectChange">
                        <Card class="matter-box" v-for="(item1,ind1) in matterdatas" :key="ind1" >
                            
                                
                                <div class="matter-box-thumb" @click="showPhoto(item1.realurl)">
                                    <img :src="item1.realurl" :suburl="item1.suburl" />
                                </div>
                                <div class="matter-title">
                                   <Checkbox :label="item1.suburl" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"></Checkbox>
                                </div> 
                        </Card>
                        </CheckboxGroup>
                    </div>
                     <vxe-pager
                        :current-page="matter.cpage?matter.cpage:1"
                        :page-size="matter.rows?matter.rows:20"
                        :total="matter.total?matter.total:0"
                        :page-sizes="[20]"
                        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'Total']"
                        @page-change="matterpagechanged"
                    ></vxe-pager>
                </Col>
            </Row>
             
        </div>
        <div slot="footer">
                <Button type="default" @click="cancel"> 取消 </Button>
                <Button type="primary"  @click="handleClick" >上传素材</Button>
               

                <Button type="primary" @click="ok" >选好了</Button>
        </div>
         <input ref="input" type="file" @change="handleChange" multiple accept="image/gif,image/jpeg,image/jpg,image/png" style="display: none">
         <Modal title="查看图片" v-model="showimage" :mask-closable="false" :footer-hide=true > 
            <img :src="imageurl" v-if="showimage" style="width: 100%">
        </Modal>

      </Modal>
  </div>
</template>

<script>
import Request from "@/utils/baseInterface.js";
import {getlocalStorage} from "@/utils/localStorage.js";
import axios from 'axios';

export default {
    data(){
        return{
            isshow: false,
            classfyDatas:[],
            selectCid:null,//选中分类编号
            matterdatas:[],//素材列表
            selectmatters:[],//选中素材
            selectClassfy:null,//选中分类
            matter:{
                cpage:1,
                rows:20,
                total:0
            },
            showimage:false,//是否显示图片放大镜
            imageurl:null,//放大的图片
            
        }
    },
     props:{
       formitem:{
           type:Object,
           value:null
       },
   },
    created(){
        //加载分类
        this.loadClassfydatas();
        //加载素材
        this.loadMatter(this.selectCid);
    },
    methods:{
        formattertitle(item){
            var title=item.title;
            if(item.datatype){
                title=title+'*'
            }
            title=title+":";
            return title;
        },
        ok(){
          var selectEdImgs= this.selectmatters.join(",");
          if(selectEdImgs){
              let oldval_=this.formitem.defvalue;
              if(oldval_!=null&&oldval_!=""){
                oldval_= oldval_ + (selectEdImgs ?","+ selectEdImgs:'');
              }else{
                oldval_= selectEdImgs ? selectEdImgs : '';
              }
              this.formitem.defvalue=oldval_;
          }
          this.isshow=false;
        },
        cancel(){
            this.isshow=false;
        },
        //加载分类
        loadClassfydatas(){
            var parma={};
            parma.id=0;
            Request({url:"/empapi/pro/matter/classifyDatalist",method:"post",data:parma}).then(res=>{
            var datas_=res.data.datas;
                datas_.forEach(item=>{
                    if(item.con&&item.con>0){
                        item.hasChild=true;
                    }
                });
                this.classfyDatas=datas_;
            });
        
        },
        //加载子分类
        loadchildClassfyData({row}){
            return new Promise(resolve => {
            var parma={};
            parma.pid=row.id;
            Request({url:"/empapi/pro/matter/classifyDatalist",method:"post",data:parma}).then(res=>{
                var datas=res.data.datas;
                datas.forEach(item=>{
                    if(item.con&&item.con>0){
                        item.hasChild=true;
                        item.childs=[];
                    }
                });
            
                resolve(datas)
            window.console.log(res);        
            });
            
            console.log(row);
            });
        },
        loadMatter(cid){
            var parma={};
            parma.classifyId=cid;
            parma.page=this.matter.cpage;
            parma.rows=20;
            Request({url:"/empapi/pro/matter/matterDatalist",method:"post",data:parma}).then(res=>{
            var datas=[];
            var datas_=res.data.datas;
            datas_.forEach(item=>{
                var temp={};
                temp.realurl="https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/"+item.imageUrl;
                temp.suburl=item.imageUrl;
                temp.imageName=item.imageName;
                temp.id=item.id;
                datas.push(temp);
            });
                this.matterdatas=datas;
                this.matter.total=res.data.total;
            });
        },
       
        matterSelectChange(e){
            console.log(e);
        },
        showmatter(e){
            this.loadMatter(e.id);
            this.selectCid=e.id;
            console.log(e);
        },
        matterpagechanged({ currentPage }){

        },
        //图片放大
        showPhoto(url){
            this.showimage=true;
            this.imageurl=url;
        },
        handleClick(e){
            console.log("this.selectCid:"+this.selectCid);
            if(!this.selectCid){
                this.$Message.info({ content: "您还没有选择分类，请先选择分类", duration: 2,});
                 return;
            }
             this.$refs.input.click();
        },
        handleChange(e){
            
          const files = e.target.files;

            if (!files) {
                return;
            }
            for(var i=0;i<files.length;i++){
                this.imageUpload(files[i]);
            }
            this.$refs.input.value = null;
            
       },
       imageUpload(file){
            var checktype=this.checkfiletype(file);
            if(!checktype)return;;

            //文件上传
            var formData = new FormData();
             formData.append('cid',this.selectCid);
            formData.append('file',file);

            var apitoken_= getlocalStorage("apitoken");
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'WXXCXTOKEN': apitoken_
            }
          };
          var surl_='http://127.0.0.1:10016/empapi/pro/matter/classifyDoAdd';
          axios.post(surl_,formData,config)
            .then((loadres) => {
                if (loadres.status == 200) {
            console.log("loadres:",loadres.data);
            var result=loadres.data;
            if(result.code==0){
             
             this.loadMatter(this.selectCid);
            }
            else{
            this.$Message.info({ content: "操作失败:"+result.message, duration: 2,});
            }
            }else if (loadres.status == 500) {
               this.$Message.info({ content: "文件大小超出限制", duration: 2,});
            } else if (loadres.status == 413) {
               this.$Message.info({ content: "文件大小超出限制", duration: 2,});
            } else {
              this.$Message.info({ content: "未知错误", duration: 2,});
            }

            });
        },
        checkfiletype(file){
            var ret=false;
            var imgName = file.name;
            var idx = imgName.lastIndexOf(".");  
            if (idx != -1){
                var ext = imgName.substr(idx+1).toUpperCase();   
                ext = ext.toLowerCase( ); 
                if (ext!='gif' && ext!='jpeg' && ext!='jpg'&& ext!='png'){
                     this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
                }else{
                        ret=true;
                }   
            }else{
                this.$Message.info({ content: "只支持GIF、JPEG、JPG、PNG类型的图片，请勿选择其它类型", duration: 2,});
            }
            return ret;
        },
        openMatterSelect(){
            this.isshow=true;
        },
        getImagedatas(defvalue,baseurl='https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/'){
            var arr=[];
            if(defvalue!=null&&defvalue!=undefined&&defvalue!=""){
            var arr_=defvalue.split(",");
            arr_.forEach(function(item,index){
                var temp={};
                temp.realurl=baseurl+item;
                temp.suburl=item;
                arr.push(temp);
            });
            }
            return arr;
        },
         handleView (name) {
            this.showimage=true;
            this.imageurl=name;
        },
        handleRemove (suburl) {
            var val_=this.formitem.defvalue;
            var arr=[];
             var arr_=val_.split(",");
            arr_.forEach(function(item,index){
               if(item!=suburl){
                arr.push(item);
               } 
            });
            this.formitem.defvalue=arr.join(",");
        },
        showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
        },
    }
}
</script>

<style>
.error{
  border: 1px solid #f32323;
}
.photobox1{
    width: 60vw;
    display: flex;
    flex-wrap: wrap;
}
.uploadimages1{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.filebox1{
    width: 62px;
    height: 62px;
    margin: 2px;
    padding: 2px;
    border: 2px dashed #e2e0e0;
}
.imagebox1 {
     
	margin: 2px;
	padding: 2px;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 64px;
	height: 64px;
	background-position: center;
	
}
.imagebox1 img{
	width: 64px;
	height: 64px;
}
.matter-box{
   
    display: flex;
    
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: 8px 12px 8px;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    margin: 2px;
}
.matter-box-thumb{
    flex-shrink: 0;
    position: relative;
   
}
.matter-box-thumb img{
    max-width: 80px;
    max-height: 80px;
}
.matter-title{
    width: 80px;
    flex-shrink: 0;
    font-size: 28px;
    color: #333;
    font-weight: 400;
    display: -webkit-box;
    height: 36px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
    line-height: 36px;
}
.matters{
    min-height: 400px;
    max-height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.demo-upload-list-cover1{
        width: 60px;
        height: 60px;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 2px;
	    padding: 2px;
        background: rgba(0,0,0,.6);
    }
    .imagebox1:hover .demo-upload-list-cover1{
        display: block;
    }
    .demo-upload-list-cover1 i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>