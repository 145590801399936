
  import Request from '@/utils/baseInterface.js';

//export const baseUrl="http://119.62.114.235:8081/"
//   export const baseUrl="http://www.ynmhkj.cn:8081/" 
 export const baseUrl="http://119.62.114.235:8081/";



  export const downloadFile = code =>{
      var parma={};
      parma.templateCode=code;
         Request({url:"/empapi/sys/template/queryTemplateByCode",method:"post",data:parma}).then(res=>{
            
            if(res.code==0){
                window.open(res.data.templateUrl);
            }else{
                this.$Message.error(res.message);
            }
        });
    
  }
/**
 * 根据视图集合和当前视图名称计算当前视图宽度
 * @param {*} viewGrids 
 * @param {*} viewName 
 */
export const getWidth =(viewGrids,viewName)=>{
    //激活的总比
    var count=0;
    var current=0;
    viewGrids.forEach(ele => {
       
        if(ele.isShow){
            count=count+ele.proportion;
        }
        if(ele.viewName==viewName&&ele.isShow){
            current=ele.proportion;
        }
    });
    if(current>0){
        return Math.trunc(24*(current/count))
    }else{
        return 0;
    }

}
/**
 * 获取视图是否存在子视图
 * @param {*} viewGrids 
 * @param {*} viewName 
 */
export const getSubViewCount =(viewGrids,viewName)=>{
    //激活的总比
        return viewGrids.find(item=>item.viewName==viewName).hasSubActive;
    

}
/**
 * 是否显示组件
 * @param {*} viewGrids 
 * @param {*} viewName 
 */
export const showView =(viewGrids,viewName)=>{
    var temp=viewGrids.find(item=>item.viewName==viewName);
    if(temp){
        return temp.isShow;
    }else{
        return false;
    }

}
/**
 * 激活或
 * @param {*} viewGrids 
 * @param {*} viewName 
 */
export const activeView =(viewGrids,viewName)=>{
    //当前视图需要激活 上级视图 需要设置有子视图
    var c=viewGrids.find(item=>item.viewName==viewName).index;
    var viewGrids_=viewGrids;
    viewGrids_.forEach(ele => {
       
        if(ele.viewName==viewName){
            ele.isShow=true;
        }
        if(c>1){
            if(ele.index==(c-1)){
                ele.hasSubActive=true;
            }
        }
        
    });
    return viewGrids_;

}
/**暂时不关闭视图  只用关闭所有子视图
 * 
 * @param {*} viewGrids 
 * @param {*} viewName 
 */
export const closeView =(viewGrids,viewName)=>{
    var viewGrids_=viewGrids;
    viewGrids_.forEach(ele => {
       
        if(ele.viewName==viewName){
            ele.isShow=true;
        }
    });

}
/**
 * 关闭子视图
 * @param {*} viewGrids 
 * @param {*} viewName 父级视图名称
 */
export const closeSubView =(viewGrids,viewName)=>{
    var viewGrids_=viewGrids;
    var c=viewGrids.find(item=>item.viewName==viewName).index;
    viewGrids_.forEach(ele => {
        if(ele.viewName==viewName){
            ele.hasSubActive=false
        }
        if(ele.index>c){
            ele.isShow=false;
        }
       
    });
    return viewGrids_;

}