<template>
    <div class="formitembox-mm">
         <FormItem :label="formattertitle(item)" :prop="item.field">
            <DatePicker ref="formitemchange" :type="item.datetype" :class="item.correct==true?'':'error'"  :disabled="!item.canedit"
         :format="item.dataformat" @on-change="selecteddate" :value="item.defvalue" placeholder="请选择日期"  style="width:92%;"></DatePicker>
         </FormItem>
    </div> 
</template>
<script>
    export default {
    data(){
        return{
           
        }
    },
    created(){
      
    },
   props:{
       item:{
           type:Object,
           value:null
       }
   },
   methods:{
       formattertitle(item){
        var title=item.title;
        if(item.datatype){
            title=title+'*'
        }
        title=title+":";
        return title;
        },
    selecteddate(e){
           let e_={};
           e_.filedname=this.item.field;
           e_.val=e;
          //  if(this.item.validation){
          //       // var validateret= validity_(this.item.validation,this.item.defvalue);
          //       if(!validateret.success){
          //       this.item.correct=false;
          //       //console.log(validateret);
          //       }else{
          //       this.item.correct=true;
          //       }
          //   }
            this.$emit("formitemchange",e_);
       }
   }
}
</script>
<style  scoped>
</style>