
<template>
  <div>
    <simpletable
      :datagrid="gridedata"
      @girdpagechanged="girdpagechanged"
      @onOptionclick="onOptionclick"
    />
    <spuSmartForm
      :steps="steps"
      :formconfig="formconfig"
      @onsubmit="onsubmit"
    ></spuSmartForm>

  </div>
</template>
<script>
import spuSmartForm from "@/components/select/spuSmartForm";
import Request from "@/utils/baseInterface.js";
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform";
import Datautil from "@/utils/datautilexp";
export default {
  components: {
    simpletable,
    spuSmartForm,
  },
  data() {
    return {
      itemdatas: [
        {title:"名称",field:"skuName",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"标题",field:"skuTitle",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"副标题",field:"skuSubtitle",ctype:"selectCategory",targetfiled:"catalogName",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"价格",field:"price",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"descar",field:"descar",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
      ],
      gridedata: {
        tid: "skulist",
        tableColumn: [],
        datas: [],
        total: 0,
        optwidth: 200,
        funopt: [ 
          { funname: "edit", icon: "vxe-icon--edit-outline", title: "编辑" },
        ]
      },
      formconfig: { title: "", modal1: false },
      queryparma: { page: 1, rows: 20 },

      isshowgroupselect:true
    };
  },
  created() {
    var columndata = Datautil.$getGridColumn(this.itemdatas);
    this.gridedata.tableColumn = columndata;
    this.quickquery.formitems = Datautil.$getQueryFormItems(this.itemdatas);
   //  this.LoadDatas();
  },
  methods: {
    LoadDatas() {
      Request({
        url: this.gridedata.gridurl,
        method: "post",
        data: this.queryparma,
      }).then((res) => {
        this.gridedata.datas = Datautil.$formattrgriddatas(
          res.data.datas,
          this.gridedata.tableColumn
        );
        this.gridedata.total = res.data.total;
      });
    },
    openaddview() {
      var columndataadd = Datautil.$getAddFormItems(this.itemdatas);
      this.steps = columndataadd;
      this.formconfig.option = "add";
      this.formconfig.title = "新增";
       this.formconfig.modal1 = true;
    },
    onsubmitquerydata(data) {
      var d = data;
      d.page = 1;
      d.rows = 20;
      this.queryparma = d;
      this.gridedata.cpage = 1;
      this.LoadDatas();
    },
    girdpagechanged(data) {
      this.queryparma.page = data.cpage;
      this.LoadDatas();
    },
    onsubmit(data) {
      if (data.option == "add") {
        Request({
          url: this.gridedata.addurl,
          method: "post",
          data: data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      } else if (data.option == "edit") {
        Request({
          url: this.gridedata.editurl,
          method: "post",
          data: data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      }
    },
    reloadgrid() {
      this.LoadDatas();
    },
    exportexcle() {},
    onOptionclick(data) {
      console.log("selected:", data.selected);
      if (data.tid == "spuinfolist") {
        if (data.funname == "detail") {
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getDetailFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "detail";
            this.formitems = columndataedit;
            (this.formconfig.title = "详情"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "edit") {
          //
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getEditFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "edit";
            this.formitems = columndataedit;
            (this.formconfig.title = "编辑"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "del") {
          var p_ = {};
          p_.id = data.selected.id;
          Request({
            url: this.gridedata.delurl,
            method: "post",
            data: p_,
          }).then((res) => {
            this.LoadDatas();
          });
        }
      }
    },
    showoptionbutton(row, exp) {
      if (exp) {
        var arr = exp.split("#");
        if (arr && arr.length >= 2) {
          var coleval = row[arr[0]];
          var operation = arr[1];
          var val = arr[2];
          if (operation == "eq") {
            return coleval == val;
          } else if (operation == "ne") {
            return coleval != val;
          } else if (operation == "ISNOTNULL") {
            return coleval ? true : false;
          } else if (operation == "ISNULL") {
            return coleval ? false : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
</style>