
<template>
  <div>
    <quickqueryform
      :quickquery="quickquery"
      @onsubmitquerydata="onsubmitquerydata"
    />
    <div class="gridtoobar">
      <div class="gridbtn">
        <Button
          type="primary"
          v-if="gridedata.gridurl"
          size="small"
          icon="md-add"
          @click="openaddview"
          >新增</Button
        > 
      </div>
      <div class="gridbar">
        <Button-group>
          <Button
            type="dashed"
            size="small"
            icon="md-refresh"
            @click="reloadgrid"
          ></Button>
          <Button
            type="dashed"
            size="small"
            v-if="gridedata.excelurl"
            icon="md-arrow-down "
            @click="exportexcle"
          ></Button>
        </Button-group>
      </div>
    </div>
    <simpletable
      :datagrid="gridedata"
      @girdpagechanged="girdpagechanged"
      @onOptionclick="onOptionclick"
    />
    <spuSmartForm
      :steps="steps"
      :formitems="formitems"
      :formconfig="formconfig"
      @onsubmit="onsubmit"
    ></spuSmartForm>

  </div>
</template>
<script>
import spuSmartForm from "@/components/select/spuSmartForm";
import Request from "@/utils/baseInterface.js";
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform";
import Datautil from "@/utils/datautilexp";
export default {
  components: {
    simpletable,
    quickqueryform,
    spuSmartForm,
  },
  data() {
    return {
      itemdatas: [
        { title: "编号", field: "id", ctype: "simpletext",show: "list,add,edit,detail",canedit:"add,edit", pkcol: true,step:"基本信息"},
        {title:"名字",field:"spuName",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"描述",field:"spuDescription",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"分类",field:"catalogId",ctype:"selectCategory",targetfiled:"catalogName",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"分类名称",field:"catalogName",ctype:"simpletext",show: "list",step:"基本信息"},

        {title:"品牌",field:"brandId",ctype:"selectbrand",targetfiled:"brandName",show: "list,add,edit,detail",step:"基本信息"},
        {title:"品牌名称",field:"brandName",ctype:"simpletext",show:"list",isquery:true,step:"基本信息"},
        {title:"重量",field:"weight",ctype:"simpletext",show: "list,add,edit,detail",step:"规格属性",groupname:"基本分类",datatype:"edit"},
        {title: "状态", field: "publishStatus",ctype: "dropselect",show: "list",options: [{ id: "1", text: "上架" }, { id: "2", text: "下架" },] ,step:"规格属性",groupname:"基本分类"},
      
       {title: "商品描述", field: "decript",ctype: "selectPhoto",show: "add,edit,detail",canedit:"add,edit",uploadurl:'http://182.254.222.253:8080/empapi/pro/spuInfo/uploadSkuImg',
        baseurl: "https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/",datatype:"edit",defvalue:'product/sku/20220504/IMG_7419e1df-cd1f-4c20-a480-7e9876e0b3e5.jpg'},

       {title: "商品图集", field: "images",ctype: "selectPhoto",show: "add,edit,detail",canedit:"add,edit",uploadurl:'http://182.254.222.253:8080/empapi/pro/spuInfo/uploadSkuImg', 
       baseurl: "https://ynwxeamll.oss-cn-chengdu.aliyuncs.com/",datatype:"edit",defvalue:'product/sku/20220504/IMG_7419e1df-cd1f-4c20-a480-7e9876e0b3e5.jpg'},
      
    

      ],
      gridedata: {
        tid: "spuinfolist",
        tableColumn: [],
        datas: [],
        total: 0,
        optwidth: 200,
        funopt: [
          { funname: "detail", icon: "vxe-icon--menu", title: "详情" },
          { funname: "edit", icon: "vxe-icon--edit-outline", title: "编辑" },
          { funname: "del", icon: "vxe-icon--edit-outline", title: "删除" },
        ],
        gridurl: "/empapi/pro/spuInfo/datalist",
        addurl: "/empapi/pro/spuInfo/doAdd",
        editurl: "/empapi/pro/spuInfo/doUpdate",
        detailurl: "/empapi/pro/spuInfo/getdetail",
        delurl: "/empapi/pro/spuInfo/doDelete",
        excelurl: null,
      },
      quickquery: { formitems: [] },
      steps: [],//编辑的步骤
       formitems:[],
      formconfig: { title: "", modal1: false },
      queryparma: { page: 1, rows: 20 },

      isshowgroupselect:true
    };
  },
  created() {
    var columndata = Datautil.$getGridColumn(this.itemdatas);
    this.gridedata.tableColumn = columndata;
    this.quickquery.formitems = Datautil.$getQueryFormItems(this.itemdatas);
    this.LoadDatas();
  },
  methods: {
    LoadDatas() {
      Request({
        url: this.gridedata.gridurl,
        method: "post",
        data: this.queryparma,
      }).then((res) => {
        this.gridedata.datas = Datautil.$formattrgriddatas(
          res.data.datas,
          this.gridedata.tableColumn
        );
        this.gridedata.total = res.data.total;
      });
    },
    openaddview() {
      var columndataadd = Datautil.$getAddFormItems(this.itemdatas);
      this.formitems = columndataadd;

      this.formconfig.option = "add";
      this.formconfig.title = "新增";
       this.formconfig.modal1 = true;
    },
    onsubmitquerydata(data) {
      var d = data;
      d.page = 1;
      d.rows = 20;
      this.queryparma = d;
      this.gridedata.cpage = 1;
      this.LoadDatas();
    },
    girdpagechanged(data) {
      this.queryparma.page = data.cpage;
      this.LoadDatas();
    },
    onsubmit(data) {
      console.log(data.datas);
      console.log(JSON.stringify(data.datas));
      if (data.option == "add") {
        Request({
          url: this.gridedata.addurl,
          method: "post",
          data:data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      } else if (data.option == "edit") {
        Request({
          url: this.gridedata.editurl,
          method: "post",
          data: data.datas,
        }).then((res) => {
          this.LoadDatas();
          this.formconfig.modal1 = false;
        });
      }
    },
    reloadgrid() {
      this.LoadDatas();
    },
    exportexcle() {},
    onOptionclick(data) {
      console.log("selected:", data.selected);
      if (data.tid == "spuinfolist") {
        if (data.funname == "detail") {
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getDetailFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "detail";
            this.formitems = columndataedit;
            (this.formconfig.title = "详情"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "edit") {
          //
          var parma = {};
          parma.id = data.selected.id;
          Request({
            url: this.gridedata.detailurl,
            method: "post",
            data: parma,
          }).then((res) => {
            var columndataedit = Datautil.$getEditFormItems(this.itemdatas);
            columndataedit = Datautil.$loadDetailData(columndataedit, res.data.data);
            this.formconfig.option = "edit";
            this.formitems = columndataedit;
            (this.formconfig.title = "编辑"), (this.formconfig.modal1 = true);
          });
        } else if (data.funname == "del") {
          var p_ = {};
          p_.id = data.selected.id;
          Request({
            url: this.gridedata.delurl,
            method: "post",
            data: p_,
          }).then((res) => {
            this.LoadDatas();
          });
        }
      }
    },
    showoptionbutton(row, exp) {
      if (exp) {
        var arr = exp.split("#");
        if (arr && arr.length >= 2) {
          var coleval = row[arr[0]];
          var operation = arr[1];
          var val = arr[2];
          if (operation == "eq") {
            return coleval == val;
          } else if (operation == "ne") {
            return coleval != val;
          } else if (operation == "ISNOTNULL") {
            return coleval ? true : false;
          } else if (operation == "ISNULL") {
            return coleval ? false : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style>
</style>