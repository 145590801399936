import { render, staticRenderFns } from "./departlist.vue?vue&type=template&id=47038152&scoped=true"
import script from "./departlist.vue?vue&type=script&lang=js"
export * from "./departlist.vue?vue&type=script&lang=js"
import style0 from "./departlist.vue?vue&type=style&index=0&id=47038152&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47038152",
  null
  
)

export default component.exports