/**
 *   @description 接口请求拦截
 *   @author Mao Xiang Dong
 *   @date 2020/5/14
 */
import axios from 'axios'
import Qs from 'qs'
import { setlocalStorage, getlocalStorage, removeAllLocalStorage, removelocalStorage } from "@/utils/localStorage.js"





import router from './../router';
import { Message } from 'iview';
import { Alert } from 'iview';
import { zip } from 'xe-utils';


export var baseuploadUrl="http://www.ynmhkj.cn/";


// 创建一个axios实例
const service = axios.create({
  //baseURL: "/",
  // baseURL: 'http://www.ynmhkj.cn:8081',

 baseURL: 'http://www.ynmhkj.cn:8081',
   // baseURL :'http://119.62.114.235:8081',
  // baseURL:'http://47.109.47.93:8081',
  // baseURL :'http://192.168.1.5:8081',
  //baseURL :'https://wxemall.hpnice.com/wxemall/',
  timeout: 10000, // 请求超时时间 默认30秒
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'content-type': 'application/x-www-form-urlencoded'
  }
})
// 请求拦截器
service.interceptors.request.use(config => {
  if (config.data) {
    config.data = Qs.stringify(config.data)
  }
  //var apitoken_= "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyYzllODA4MzcyMDIzY2IxMDE3MjAyN2M0MDZkM0EwMSIsInN1YiI6IjJjOWU4MDgzNzIwMjNjYjEwMTcyMDI3YzQwNmQzQTIzIiwiaWF0IjoxNjQ4NTYyMjI3fQ.rEjC9RrL7GV9yst05GhDXwhByJvKAltLnWgVVqlYWdA";
  var apitoken_ = getlocalStorage("apitoken");
  if (apitoken_) {
    config.headers['WXXCXTOKEN'] = apitoken_

  }
  var onedeviceid_ = getlocalStorage("onedeviceid");

  if (onedeviceid_) {
    config.headers['deviceId'] = onedeviceid_
  }

  return config
},
  error => {
    // debug

    window.console.log('接口异常', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.msg || 'Error'))
    } else {


      return response.data
    }


  },
  error => {

    let errString = JSON.stringify(error);


    //console.log(error);

    if (errString.indexOf("Network Error") || errString.indexOf("Error: Request failed with status code 404")) {
      const token = getlocalStorage("apitoken");
      if (token) {
        let exitsign = localStorage.getItem("exitsign");
        if (exitsign) {

          if (exitsign >= 111) {
            removelocalStorage("exitsign");
            console.log("网络连接失败，请检测网络1");
            removeAllLocalStorage();
            router.push({
              path: "/",
            });
          }
          else {
            setlocalStorage("exitsign", exitsign + 1);
          }
        }
        else {
          setlocalStorage("exitsign", 1);
        }
      }

      Message.error({
        background: true,
        content: '网络连接失败，请检测网络!',
        duration: 3
      });
      return Promise.reject(error)
      //return Promise.reject(error)
    }
    else if (errString.indexOf("Request failed with status code 401")) {

      Message.error({
        background: true,
        content: '网络连接失败，请检测网络!',
        duration: 3
      });
      removelocalStorage("exitsign");
      console.log("网络连接失败，请检测网络");
      removeAllLocalStorage();
      router.push({
        path: "/",
      });
    }
    else if (errString.indexOf("ERR_CONNECTION_REFUSED")) {


      Message.error({
        background: true,
        content: '网络连接失败，请检测防火墙设置!',
        duration: 3
      });
      return Promise.reject(error)
      //  return Promise.reject(error)
    }
    else if (error.code === 'ECONNABORTED') {
      console.log('请求超时处理');

      Message.error({
        background: true,
        content: '请求连接超时！',
        duration: 3
      });
      return Promise.reject(error)
      // return Promise.reject(error)
    }



    else if (error.response.status == 401) {
      removeAllLocalStorage();
      alert("登录过期，即将为你跳转登录页面")
      return router.replace('/login');
    } else if (error.response.status == 404) {
      return Promise.reject(error)
    }
    else {
      // alert("未知错误，请刷新重试")
      return Promise.reject(error)
    }

  }
)

export default service
