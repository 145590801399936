<template>
      <div>
           <quickqueryform :quickquery="quickquery"  @onsubmitquerydata="onsubmitquerydata" @resetquerydata="resetquerydata"/>
            <div class="gridtoobar">
                <div class="gridbtn">
                    <Button type="primary" v-if="gridedata.gridurl" size="small" icon="md-add" @click="openaddview">新增</Button>
                </div>
                <div class="gridbar">
                    <Button-group>
                        <Button type="dashed" size="small" icon="md-refresh" @click="reloadgrid" ></Button>
                        <Button type="dashed" size="small" v-if="gridedata.excelurl" icon="md-arrow-down " @click="exportexcle" ></Button>
                    </Button-group>
                </div>
            </div>
            <simpletable
                :datagrid="gridedata"
                @girdpagechanged="girdpagechanged"
                @onOptionclick="onOptionclick"
            />
            <smart-form :formitems="formitems" :formconfig="formconfig" @onsubmit="onsubmit"></smart-form>
        </div>
</template>
<script>
import smartForm from "@/components/wxe/smartForm";  
import Request from '@/utils/baseInterface.js';
import simpletable from "@/components/wxe/simpletable";
import quickqueryform from "@/components/wxe/quickqueryform"; 
import Datautil from "@/utils/datautil";
    export default {
        components: {
            simpletable,quickqueryform,smartForm
        },
        data () {
            return {
                itemdatas:[
                    {title:"编号",field:"id",ctype:"simpletext",isshowlist:true,isshowadd:false,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,pkcol:true,canedit:false},
                    {title:"门店名称",field:"outletname",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*",isquery:true},
                    {title:"门店照片",field:"outletimage",ctype:"photoupload",isshowlist:false,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*",maxlength:9,uploadurl:'http://192.168.0.100:10008/employapi/commenslideshow/uploadslideimage', baseurl: "https://qxask.oss-cn-chengdu.aliyuncs.com/"},
                    {title:"门店地址",field:"outletaddress",ctype:"simpletext",isshowlist:false,isshowadd:false,isshowdetail:false,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*"},
                    {title:"组织编号",field:"orgid",ctype:"selectdepart",isshowlist:false,targetfiled:'orgname',correct:true,defvalue:null,canedit:true,isquery:false},
                    {title:"联系电话",field:"outletphone",ctype:"simpletext",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*",isquery:true},
                    {title:"门店评分",field:"outletscore",ctype:"simplescore",isshowlist:true,isshowadd:true,isshowdetail:true,isshowedit:true,correct:true,defvalue:null,canedit:true,datatype:"*"},
                    {title:"播报设备",field:"imappid",ctype:"simpletext", canedit:false,defvalue:null}, 
                    {title:"门店状态",field:"ostate",ctype:"dropselect", defvalue:null, options: [{  id: 1, text: '启用' }, { id: 2, text: '停用' }],datatype:"*"}, 
                    {title:"是否新店",field:"isnewoutlet",ctype:"dropselect", defvalue:null, options: [{  id: 1, text: '是' }, { id: 2, text: '否' }],datatype:"*"}, 
                    {title:"标签",field:"labels",ctype:"simpletext",isshowlist:false,isshowadd:true,isshowdetail:true,isshowedit:true,defvalue:null,canedit:true},
                
                ],
                gridedata:{tid:'outletlist', tableColumn:[], datas:[], total:0,optwidth:340,funopt:[
                    {funname:'detail',icon:'vxe-icon--menu',title:'详情'},
                    {funname:'edit',icon:'vxe-icon--edit-outline',title:'编辑'},
                    {funname:'del',icon:'vxe-icon--edit-outline',title:'删除'}],
                    gridurl:'/employapi/outlet/datalist',
                    addurl:'/employapi/outlet/doAdd',
                    editurl:'/employapi/outlet/doUpdate',
                    detailurl:'/employapi/outlet/getdetail',
                    delurl:'/employapi/outlet/doDelete',
                    excelurl:null },
                quickquery:{formitems:[]},
                formitems:[],
                formconfig: { title: "编辑", modal1: false },
                queryparma:{page:1,rows:20},//查询参数
            }
        },
        created(){
            var columndata=  Datautil.$getGridColumn(this.itemdatas);
            this.gridedata.tableColumn=columndata;
            this.quickquery.formitems=Datautil.$getQueryFormItems(this.itemdatas);
            this.LoadDatas();
        },
        methods:{
            LoadDatas(){
                Request({url:this.gridedata.gridurl,method:"post",data:this.queryparma}).then(res=>{
                this.gridedata.datas=Datautil.$formattrgriddatas(res.datas,this.gridedata.tableColumn);
                this.gridedata.total=res.total;
                });
            },
            openaddview(){
                var columndataadd=  Datautil.$getAddFormItems(this.itemdatas);
                this.formitems=columndataadd;
                this.formconfig.option="add";
                this.formconfig.title="新增",
                this.formconfig.modal1=true;
            },
            onsubmitquerydata(data){
                //重新加载数据
                var d=data;
                d.page=1;
                d.rows=20;
                this.queryparma=d;
                this.gridedata.cpage=1;//表格从第一页开始
                this.LoadDatas();
               
            },
            girdpagechanged(data){
                this.queryparma.page=data.cpage;
                this.LoadDatas();
                
            },
            onsubmit(data){
                if(data.option=="add"){
                    Request({url:this.gridedata.addurl,method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                        this.LoadDatas();
                        this.formconfig.modal1=false;
                    });
                }else if(data.option=="edit"){
                    Request({url:this.gridedata.editurl,method:"post",data:data.datas}).then(res=>{
                        if(res.success){
                                this.$Message.info('操作成功');
                        }else{
                                this.$Message.error(res.message);
                        }
                         this.LoadDatas();
                          this.formconfig.modal1=false;
                    });
                }
                
            },
            reloadgrid(){
                this.LoadDatas();
            },
            exportexcle(){

            },
            onOptionclick(data){ 
                console.log("selected:",data.selected);
                if(data.tid=='outletlist'){
                    if(data.funname=='detail'){
                         var parma={};
                            parma.id=data.selected.id;
                        Request({url:this.gridedata.detailurl,method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getDetailFormItems(this.itemdatas);
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                            this.formconfig.option="detail";
                            this.formitems=columndataedit;
                            this.formconfig.title="用户详情",
                            this.formconfig.modal1=true;
                        });
                    }else if(data.funname=='edit'){//
                          var parma={};
                            parma.id=data.selected.id;
                        Request({url:this.gridedata.detailurl,method:"post",data:parma}).then(res=>{
                            var columndataedit=  Datautil.$getEditFormItems(this.itemdatas);
                            columndataedit= Datautil.$loadDetailData(columndataedit,res.data);
                            this.formconfig.option="edit";
                            this.formitems=columndataedit;
                            this.formconfig.title="编辑",
                            this.formconfig.modal1=true;
                        });
                    }else if(data.funname=='del'){
                            this.$Modal.confirm({
                            title: '确定要删除吗',
                            content: '<p>删除后将无法恢复</p>',
                            onOk: () => {
                                var p_={};
                                p_.id=data.selected.id;
                                Request({url:this.gridedata.delurl,method:"post",data:p_}).then(res=>{
                                        if(res.success){
                                                this.$Message.info('操作成功');
                                        }else{
                                                this.$Message.error(res.message);
                                        }
                                        this.LoadDatas();
                                });
                            },
                            onCancel: () => {
                            
                            }
                        });
                    }

                    
                }
               
            },
            showoptionbutton(row,exp){
                if(exp){
                    var arr=  exp.split("#");
                    if(arr&&arr.length>=2){
                    var coleval=row[arr[0]];
                    var operation=arr[1];
                    var val=arr[2];
                    if(operation=="eq"){
                        return coleval==val;
                    }else if(operation=="ne"){
                        return coleval!=val;
                    }else if(operation=="ISNOTNULL"){
                        return coleval?true:false;
                    }else if(operation=="ISNULL"){
                        return coleval?false:true;
                    }else{
                        return false
                    }
                    }else{
                    return false;
                    }
                }else{
                    return true;
                }
            },
            resetquerydata(){
                var d = {};
                d.page = 1;
                d.rows = 20;
                this.queryparma = d;
                this.gridedata.cpage = 1;
                this.LoadDatas();
                this.quickquery.formitems = Datautil.$resetQueryFormItems(this.itemdatas);
            }
           
        },
        
    }

</script>
<style>
</style>