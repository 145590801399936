<template>
  <div ref="onsubmit">
    <Modal v-model="formconfig.modal1" width="68vw" :title="formconfig.title">
        
      <div class="modalcontent">
          <!-- <Steps :current="stepindex">
                :step-title="step.title"
                 >
                 <span v-for="step in steps" :key="step.title">
                    <Step :title="step.title" v-if="step.iscreate==2" ></Step>
                 </span>
                 
          </Steps> -->

         <Steps :current="stepindex">
              <Step title="商品信息" ></Step>
              <Step title="规格参数" ></Step>
              <Step title="销售属性" ></Step>
              <Step title="SKU" ></Step>
          </Steps>

          <div v-show="stepindex==0" style="margin-top:20px">
             <Form
                class="modalcontent-form"
                ref="formValidate"
                :label-width="68"
                inline
                style="padding-bottom: 35px"
              >
                <template
                  :data-tyep="item.ctypeid"
                  v-for="item in formitems"
                
                >
                    <simpletext
                      :item="item"
                      v-if="item.ctype == 'simpletext'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <firstmenuselect
                      :item="item"
                      v-if="item.ctype == 'firstmenuselect'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <amountText
                      :item="item"
                      v-if="item.ctype == 'amountText'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <dateselect
                      :item="item"
                      v-if="item.ctype == 'dateselect'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <datetime
                      :item="item"
                      v-if="item.ctype == 'datetime'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <simplecode
                      :item="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'simplecode'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <integernumber
                      :item="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'integernumber'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    
                    <proportionInterger
                      :item="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'proportionInterger'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <imageupload
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'imageupload'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <simplescore
                      :item="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'simplescore'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  
                    <simpletextarea
                      :item="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'simpletextarea'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <photoupload
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'photoupload'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                      class="photoupload"/>
                    <selectPhoto
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectPhoto'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                      class="photoupload"
                    />
                    <dropselect
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'dropselect'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    
                    <selectoutlet
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectoutlet'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <selectchannel
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectchannel'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <selectchanneldeduction
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectchanneldeduction'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    
                  
                      <selectrole
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectrole'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                  <selectdepart
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectdepart'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 
                    <selectservice
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectservice'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 
                    <multiselectoutlet
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'multiselectoutlet'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 
                      <multiselectservice
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'multiselectservice'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 

                    <videoupload
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'videoupload'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 
                    <selectbrand 
                    :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectbrand'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    /> 

                    <selectCategory
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'selectCategory'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    <muititext
                      :formitem="item"
                      :data-type="item.ctype"
                      v-if="item.ctype == 'muititext'"
                      :key="item.field"
                      @formitemchange="formitemchange"
                    />
                    
                </template>
              </Form>
          </div>
           <div v-show="stepindex==1"  style="margin-top:20px">
             <Form
                    class="modalcontent-form"
                    ref="formValidate"
                    :label-width="68"
                    inline
                    style="padding-bottom: 35px"
                  >
             <Card v-for=" attr in baseAttr" :key="attr.id" style="    width: 100%;">
               <p>{{attr.attrGroupName}}</p>
                  
                   <template
                      :data-tyep="item.ctypeid"
                      v-for="item in attr.formitems"
                    
                    >
                      <muititext
                        :formitem="item"
                        :data-type="item.ctype"
                        v-if="item.ctype == 'muititext'"
                        :key="item.field"
                        @formitemchange="attrformitemchange"
                      />
                   </template>
                 
             </Card>
              </Form>
          </div>
           <div v-show="stepindex==2"  style="margin-top:20px">
            
            <Form
                    class="modalcontent-form"
                    ref="formValidate"
                    :label-width="68"
                    inline
                    style="padding-bottom: 35px"
                  >
                   <Card  style="    width: 100%;">
                   <template
                      :data-tyep="item.ctypeid"
                      v-for="item in saleAttr"
                    
                    >
                      <muititext
                        :formitem="item"
                        :data-type="item.ctype"
                        v-if="item.ctype == 'muititext'"
                        :key="item.field"
                        @formitemchange="attrformitemchange"
                      />
                   </template>
                 
                </Card>
              </Form>
              
          </div>
          <div v-show="stepindex==3"  style="margin-top:20px">
             <simpletable
                :datagrid="gridedata"
                @onOptionclick="onOptionclick"
              />
               <smart-form
                :formitems="skuformitems"
                :formconfig="skuformconfig"
                @onsubmit="skuonsubmit"
              ></smart-form>
          </div>
        
      </div> 
      <div slot="footer">
        <Button type="default" @click="cancel"> 取消 </Button>
        
        <Button
          type="primary"
          v-if="showPre()"
          :disabled="disabledsubmit"
          @click="prestep"
          >上一步</Button>
          <Button
          type="primary"
          v-if="showNext()"
          :disabled="disabledsubmit"
          @click="nextstep"
          >下一步</Button>
           <Button
          type="primary"
          v-if="showSubmit()"
          :disabled="disabledsubmit"
          @click="ok"
          >确定</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import Request from "@/utils/baseInterface.js";

// import checkbox from "@/components/form/checkbox.vue";
import validity_ from "@/components/wxe/smartvalidate.js";
import simpletext from "@/components/wxe/simpletext.vue";
import firstmenuselect from "@/components/wxe/firstmenuselect"; //一级菜单
import amountText from "@/components/wxe/amountText";
import dateselect from "@/components/wxe/dateselect";
import simplecode from "@/components/wxe/simplecode";
import integernumber from "@/components/wxe/integernumber";
import proportionInterger from "@/components/wxe/proportionInterger";
import imageupload from "@/components/wxe/imageupload";
import simplescore from "@/components/wxe/simplescore";
import datetime from "@/components/wxe/datetime";
import simpletextarea from "@/components/wxe/simpletextarea"
import photoupload from "@/components/wxe/photoupload"

import selectPhoto from "@/components/select/selectPhoto";

import dropselect from "@/components/wxe/dropselect"
import selectoutlet from "@/components/wxe/selectoutlet"
import selectchannel from "@/components/wxe/selectchannel"
import selectchanneldeduction from "@/components/wxe/selectchanneldeduction"
import selectrole from "@/components/wxe/selectrole"
import selectdepart from "@/components/wxe/selectdepart"
import selectservice from "@/components/wxe/selectservice"
import multiselectoutlet from "@/components/wxe/multiselectoutlet"
import multiselectservice from "@/components/wxe/multiselectservice"
import selectbrand from "@/components/wxe/selectbrand"

import selectCategory from "@/components/select/selectcategory"

import selectsku from "@/components/select/selectsku"

import videoupload from "@/components/wxe/videoupload"
import muititext from "@/components/wxe/muititext"

import simpletable from "@/components/wxe/simpletable";
import Datautil from "@/utils/datautil";
import smartForm from "@/components/wxe/smartForm";
export default {
  components: {
    simpletext,//单行文本
    firstmenuselect,//一级菜单选择
    amountText,//金额
    dateselect,//日期选择
    simplecode,//编码
    integernumber,//整数
    proportionInterger,//整数占比
    imageupload,//图片上传
    simplescore,//评分
    datetime,//时间选择
    simpletextarea,//多行文本
    photoupload,
    dropselect,//下拉选择
    selectoutlet,//门店选择
    selectrole,//角色设置
    selectdepart,
    selectservice,//选择服务
    multiselectoutlet,//门店多选
    multiselectservice,//服务多选
    videoupload,
    selectchannel,
    selectchanneldeduction,
    selectbrand,//选择品牌
    selectCategory,//选择分类
    muititext,//多输入
    selectsku,//
    simpletable,
    smartForm,
    selectPhoto //图片选择器
  },
  data() {
    return {
      modal1: false,
      submitdata: {},
      disabledsubmit: false,
      stepindex:0,//步骤
     
      baseAttr:[],//基础属性
      saleAttr:[],//销售属性
      skuitems:[
        {title:"名称",field:"skuName",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"标题",field:"skuTitle",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"副标题",field:"skuSubtitle",ctype:"selectCategory",targetfiled:"catalogName",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"价格",field:"price",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"descar",field:"descar",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},
        {title:"图片",field:"images",ctype:"selectPhoto",show: "edit,detail",canedit:"add,edit",step:"基本信息"},

      ],//sku配置信息 
      gridedata: {
        tid: "skulist",
        tableColumn: [],
        datas: [],
        total: 0,
        optwidth: 80,
        showpage:false,//是否分页
        funopt: [ 
          { funname: "edit", icon: "vxe-icon--edit-outline", title: "编辑" },
        ]
      },
      baseAttrData:[],//基本属性的值
      dakaers:[],//笛卡尔集合
      saleFiled:[],//销售属性字段集合  其实就是id
      baseInfo:null,//基本信息
      skuformitems: [],
      skuformconfig: { title: "", modal1: false },
    };
  },
  created() {
    // console.log(this.formconfig);
    var columndata = Datautil.$getGridColumn(this.skuitems);
    this.gridedata.tableColumn = columndata;

    this.modal1 = this.formconfig.modal1;
  },
  updated() {
    // console.log('哈哈哈表数据',this.formconfig);
  },
  props: {
    formitems: {
      type: Array,
      value: null,
    },//第一步表单配置
    steps: {
      type: Array,
      value: null,
    },
    formconfig: {
      type: Object,
      value: null,
    },
  },
  methods: {
    ok() {
      //this.disabledsubmit=true;//防止重复提交
      var d_ = this.getsubmitdata();
      //this.$emit("onsubmit", d_);
      //先校验表单数据
      var check = this.validiteFormItems();
      if (check == true) {
        var data_ = {};
        data_.datas = this.getsubmitdata();
        data_.option = this.formconfig.option;
        data_.tid=this.formconfig.tid;
        this.$emit("onsubmit", data_);
      } else {
        this.$Message.info({
          content: "表单校验不通过，请检查",
          duration: 2,
        });
        return;
      }
    },
    nextstep(){
      if(this.stepindex==0){//如果是基本信息
        var check = this.validiteFormItems();
        if (check == true) {
          this.baseInfo= this.getsubmitdata();
          this.stepindex=this.stepindex+1;
        } else {
          this.$Message.info({
            content: "表单校验不通过，请检查",
            duration: 2,
          });
          return;
        }
      }else if(this.stepindex==1){//基本属性
          var data = [];
            this.baseAttr.forEach((item) => {
              var groupformitems=  item.formitems;
              groupformitems.forEach((attr) => {
                  if (attr.defvalue) {
                    var attr_={};
                      attr_.attrId=attr.field;
                      attr_.attrValues=attr.defvalue;
                    data.push(attr_);
                  }
              });
            });
            window.console.log("s:", data);
            //基本属性集合
            this.baseAttrData=data;
           this.stepindex=this.stepindex+1;
      }
      else if(this.stepindex==2){//销售属性 
        this.dakaers=[];
        let hasSaleAttr=false;
        var props=[];
        var saleAttr_=  this.saleAttr;
        this.saleFiled=[];//重新操作需要情况数据，不然会数组越界
        saleAttr_.forEach(item=>{
          var defvalue_=item.defvalue;
          if(defvalue_){
            hasSaleAttr=true;
            props.push(defvalue_.split(","));

            //添加销售属性集合编号
            this.saleFiled.push(item.field);
          }
        });

        //判断是否有销售属性
        if(hasSaleAttr){
          props.forEach(item=>{
            let temp=this.descartes(this.dakaers,item);
            this.dakaers=temp;
          });
          //
          this.getGridData();

           this.stepindex=this.stepindex+1;

        }else{
          this.$Message.error('您还没有填写销售属性');
        }
      } 

     

    },
    /**
     * 获取表格数据
     */
    getGridData(){

      //列表数据
      var g_datas=[];
      //获取笛卡尔积
      var d_= this.dakaers;
      var baseInfo_=this.baseInfo;
      d_.forEach(item=>{
        var row={};
        row.descar=item;
        row.skuName=baseInfo_.spuName;
        row.skuTitle=baseInfo_.spuName+" "+item;
        row.skuSubtitle=baseInfo_.spuName+" "+item;
        row.price='0.00';
        row.images=this.baseInfo.images;
          var temp=item.split(",");
          this.saleFiled.forEach((f,index)=>{
            row[f]=temp[index];
          });

          g_datas.push(row);
      });
      console.log(this.gridedata);
      this.gridedata.datas=g_datas;
    },
    prestep(){
      this.stepindex=this.stepindex-1;
    },
    cancel() {
     
      this.formconfig.modal1 = false;
    },
    subEvent(e) {
      //window.console.log(e);
    },
    formitemchange(data) {
      var data_={};
      this.disabledsubmit = false; //数据校验不通过，数据改变时回复提交按钮
      // console.log("formitemchange:",data);
      //1. 先取出绑定的字段名称
      let filedname = data.filedname;
      let val = data.val; //取值
      data_.fieldname=data.filedname;
      data_.defvalue=data.val;
      data_.deftext=data.deftext;
      //把数据加入到待提交数据中
      this.submitdata[filedname] = val;
      // console.log("formitemchange:"+e.currentTarget.value);
      //this.pinying=spell.chineseToPinYin(e.currentTarget.value);
      if(data.filedname=="catalogId"){
       this.loadGroupAttrData(data.val);
       this.loadSaleAttrData(data.val);
      }
       this.$emit("formitemchnage", data_);
    },
//表单校验
    validiteFormItems() {
      var ret = true;
      this.formitems.forEach((item) => {
        if (item.datatype) {
          var validateret = validity_(item.datatype, item.defvalue);
          if (!validateret.success) {
            item.correct = false;
            ret = false;
          } else {
            item.correct = true;
          }
        }
      });
      return ret;
    },
    attrformitemchange(data){
      // if(data){
      //   let temp=this.descartes(this.dakaers,data.val.split(","));
      //   this.dakaers=temp;
      //   console.log( this.dakaers);
      // }

      console.log(data);
    },

    getsubmitdata() {
      var data = {};
      this.formitems.forEach((item) => {
        if (item.defvalue) {
          data[item.field] = item.defvalue;
        }
        if (item.targetfiled) {
          data[item.targetfiled] = item.deftext;
        }
      });
      window.console.log("s:", data);
      return data;
    },
    //表单校验
    validiteFormItems() {
      var ret = true;
      this.formitems.forEach((item) => {
        if (item.datatype) {
          var validateret = validity_(item.datatype, item.defvalue);
          if (!validateret.success) {
            item.correct = false;
            ret = false;
          } else {
            item.correct = true;
          }
        }
      });
      return ret;
    },

    /**
     * 显示下一步
     */
    showNext(){
      if((this.formconfig.option == 'add' || this.formconfig.option == 'edit')&&this.stepindex<3){
        return true;
      }
      else{
        return false;
      }
    },
    //显示提交按钮
    showSubmit(){
      if((this.formconfig.option == 'add' || this.formconfig.option == 'edit')&&this.stepindex==3){
        return true;
      }
      else{
        return false;
      }
    },
    
    showPre(){
      if((this.formconfig.option == 'add' || this.formconfig.option == 'edit')&&this.stepindex>0){
        return true;
      }
      else{
        return false;
      }
    },

    /**
     * 计算笛卡尔集
     *  @param pre 笛卡尔数组
     *  @param suf 集合元素
     * 
     */
    descartes(pre,suf) {
      if(!pre || pre.length < 1){
			  return suf;
		  }
	    let result = [];
	    pre.forEach(o1 => {
	        suf.forEach(o2 => {
	            result.push(o1 + ',' + o2);
	        })
	    })
	    return result;
    },

  /**
   * 通过分类编号获取销售分组属性
     * @param categoryId
   */
    loadSaleAttrData(categoryId){

        Request({
          url: "/empapi/pro/attr/querysaleattr",
          method: "post",
          data: {catelogId:categoryId},
        }).then((res) => {
          if(res.code==0){
            var datas_=[];
            var resdatas=res.data.datas;
            if(resdatas&&resdatas.length>0){
              resdatas.forEach((item)=>{
               
                    //{title:"可选值列表",field:"valueSelect",ctype:"muititext",isshowlist:true,isquery:false,canedit:true,datatype: "*"},

                    var attritem={};
                    attritem.title=item.attrName;
                    attritem.field=item.id;
                    attritem.ctype="muititext";
                    attritem.canedit=true;
                    attritem.datatype="*";
                    attritem.defvalue=item.valueSelect;
                    attritem.correct=true;

                //{title:"标题",field:"skuTitle",ctype:"simpletext",show: "list,add,edit,detail",canedit:"add,edit",step:"基本信息"},

                var skuitem_={};
                skuitem_.title=item.attrName;
                skuitem_.field=item.id;
                skuitem_.ctype="simpletext";
                skuitem_.show="list";
                skuitem_.isattr=true;
                this.skuitems.unshift(skuitem_);
                
                datas_.push(attritem);
              });
            }
            this.saleAttr=datas_;

            //更新表格
             var columndata = Datautil.$getGridColumn(this.skuitems);
             this.gridedata.tableColumn = columndata;

          }else{
             console.log("message:",res.message);
          }
         
        });
    },
    /** 通过分类编号获取spu分组属性
     * @param categoryId
     */
    loadGroupAttrData(categoryId){
        Request({
          url: "/empapi/pro/attrgroup/getAttrGroupWithAttrs",
          method: "post",
          data: {catelogId:categoryId},
        }).then((res) => {
          if(res.code==0){
            var datas_=[];
            var resdatas=res.data.datas;
            if(resdatas&&resdatas.length>0){
              resdatas.forEach((item)=>{
                var group_={};
                group_.id=item.id;
                group_.attrGroupName=item.attrGroupName;
                group_.formitems=[];

                var attrs=item.attrs;
                if(attrs){
                  var items_=[];
                  attrs.forEach(attr=>{
                    //{title:"可选值列表",field:"valueSelect",ctype:"muititext",isshowlist:true,isquery:false,canedit:true,datatype: "*"},

                    var attritem={};
                    attritem.title=attr.attrName;
                    attritem.field=attr.id;
                    attritem.ctype="muititext";
                    attritem.canedit=true;
                    attritem.datatype="*";
                    attritem.defvalue=attr.valueSelect;
                    attritem.correct=true;
                    items_.push(attritem);
                  });
                  group_.formitems=items_;
                }
                datas_.push(group_);
              });
            }
            this.baseAttr=datas_;
          }else{
             console.log("message:",res.message);
          }
         
        });
    },
    onOptionclick(data){
      console.log(data);
        if(data.tid=="skulist"){  
          if (data.funname == "edit") {
            
            var row=data.selected;
            var columndataedit = Datautil.$getEditFormItems(this.skuitems);
            columndataedit = Datautil.$loadDetailData(columndataedit, row);
            this.skuformconfig.option = "edit";
            this.skuformitems = columndataedit;
            this.skuformconfig.title = "编辑";
            this.skuformconfig.modal1 = true;
          }
        }
    } ,
    skuonsubmit(){

    },
    //点击确认按钮
    ok(){
        //1.获取SPU基本信息
        var spuinfo=this.baseInfo;
        //2.获取SPU基本属性
        var baseAttrData_=this.baseAttrData;
        
        spuinfo.baseAttrs=JSON.stringify(baseAttrData_);
        //3.获取SKU信息
        var skus_=this.getSkuGridData();
        spuinfo.skus=JSON.stringify(skus_);

       //todo 校验数据
        var data_ = {};
        data_.datas = spuinfo;
        data_.option = this.formconfig.option;
        data_.tid=this.formconfig.tid;
        this.$emit("onsubmit", data_);

        console.log("spuinfo:",spuinfo);
    },

  /**
   * 读取SKU列表数据
   */
    getSkuGridData(){
      var skus=[];//SKU集合数据
      //SKU配置列
      var skuitems_= this.skuitems;
      //SKU数据

        var skugriddata= this.gridedata.datas;
        skugriddata.forEach(item=>{
            var attrs_=[];//SKU销售属性
            var sku_={};//组装SKU数据
            skuitems_.forEach(skuformitem=>{
              if(skuformitem.isattr){
                var attr_={};
                attr_.attrId=skuformitem.field;
                attr_.attrName=skuformitem.title;
                attr_.attrValue=item[skuformitem.field];
                attrs_.push(attr_);
              }else{
                sku_[skuformitem.field]=item[skuformitem.field];
              }
            });
            sku_.attr=attrs_;

            skus.push(sku_);
        });
        return skus;
    }

  },
};
</script>
<style  >



.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}


</style>