<template>
  <Form v-if="quickquery.formitems&&quickquery.formitems.length>0" class="qick-modalcontent-form" :label-width="80" type="flex" style="text-align: left;">
     <template
            :data-tyep="item.ctypeid"
            v-for="item in quickquery.formitems"
          
          >
               <!-- 普通文本 -->
              <simpletext
                :item="item"
                v-if="item.ctype=='simpletext'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
             
              <!-- 金额 -->
               <amountText
                :item="item"
                v-if="item.ctype=='amountText'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <!-- 日期选取 -->
              <dateselect
                :item="item"
                v-if="item.ctype=='dateselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <!-- 月份选取 -->
              <monthselect
                :item="item"
                v-if="item.ctype=='monthselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <!-- 年份选取 -->
              <yearselect
                :item="item"
                v-if="item.ctype=='yearselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <!-- 时间 -->
              <datetime
                :item="item"
                v-if="item.ctype=='datetime'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
               <!-- 编号 -->
              <simplecode
                :item="item"
                v-if="item.ctype=='simplecode'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <dropselect
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'dropselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
    </template>
    <span class="query-box">
      <Button
        type="primary"
        :data-opid="quickquery.opid"
        :data-tableid="quickquery.tableid"
        @click="submitquerydata"
      >{{quickquery.title?quickquery.title:"查询"}}</Button>
       <Button
        type="primary"
        :data-opid="quickquery.opid"
        :data-tableid="quickquery.tableid"
        @click="resetquickquery"
      >重置</Button>
    </span>
  </Form>
</template>

<script>
import validity_ from "@/components/wxe/smartvalidate.js";
import simpletext from "@/components/wxe/simpletext.vue";
import firstmenuselect from "@/components/wxe/firstmenuselect";//一级菜单
import amountText from "@/components/wxe/amountText";
import dateselect from "@/components/wxe/dateselect";

import monthselect from "@/components/wxe/monthselect";
import yearselect from "@/components/wxe/yearselect";

import simplecode from "@/components/wxe/simplecode";
import integernumber from"@/components/wxe/integernumber";
import datetime from "@/components/wxe/datetime"
import dropselect from "@/components/wxe/dropselect"
export default {
  components: {
    simpletext,
    firstmenuselect,
    amountText,
    dateselect,
    simplecode,
    integernumber,
    datetime,
    dropselect,
    monthselect,
    yearselect
  },
  data() {
    return {
      submitdata: {},
      opid: "", //操作编号
      tid: "", //表格编号
    };
  },
  props: {
    quickquery: {
      type: Object,
      value: null,
    },
  },
  created() {
    
  },
  updated() {
     
  },
  methods: {
    quickqueryitemchange(data) {
     window.console.log(data);
    },
    submitquerydata() {
     var data_={};
         this.quickquery.formitems.forEach(item => {
           
           if(item.datetype=='daterange' || item.datetype=="datetimerange"){
              if(item.defvalue){
                data_[item.field]=item.defvalue.join(","); 
              }
           }else{
              if(item.defvalue){
                data_[item.field]=item.defvalue 
              }
           }
          
         });
       this.$emit("onsubmitquerydata", data_);
    },
    formitemchange(data){
      window.console.log(data);
    },
    resetquickquery(){
      var data_={};
     
      this.$emit("resetquerydata", data_);
    }
  },
};
</script>
<style >
.rows {
  margin: 4px;
  border:1px solid rgba(247, 242, 242, 0.014);
  display: inline-block;
  height: 36px !important;
  width: 255px;
}
.query-box {
  display: inline-block;
  min-width: 70px;
  padding: 0;
} 
.query-box button{
  margin-right: 20px;
}

 .ivu-form-item{
   margin-bottom: 10px !important;
 }

 /* .formitembox-mm{
   flex-basis:20% !important;
 } */
</style>