<template>
  <Layout style="height: 100%" class="main">
    <Sider    v-if="this.$store.state.showleftmenu"

      hide-trigger
      collapsible
      :width="256"
      :collapsed-width="64"
      style="height: 100vh"
      v-model="collapsed"
      class="left-sider"
      :style="{
        overflow: 'hidden',
        backgroundImage:
          'url(' + require('../../assets/images/index/menu.png') + ')',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <side-menu
        accordion
        ref="sideMenu"
        :collapsed="collapsed"
        style="margin-left: 22px"
        @on-select="onTabClick"
        :menu-list="menuList"
      >
        <!-- 需要放在菜单上面的内容，如Logo，写在side-menu标签内部，如下 -->
        <div class="logo-con" style="margin-left: -10px">
          <img v-show="!collapsed" :src="maxLogo" key="max-logo" />
          <img v-show="collapsed" :src="minLogo" key="min-logo" />
          <div
            v-show="!collapsed"
            style="
              width: 100%;
              font-size: 12px;
              padding: 4px;
              color: #fff;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ userinfo.companyName ? userinfo.companyName : "" }}
          </div>
        </div>
      </side-menu>
    </Sider>
    <Layout>
      <Header
        :style="{
          backgroundImage:
            'url(' + require('../../assets/images/index/bg.jpg') + ')',
          backgroundRepeat: 'no-repeat',
        }"
        class="layout-header-bar headertitle"
        style="text-align: left; background: #fff"
      >
        <div
          class="overhide"
          style="height: 100%; posation: relative; padding: 0px 20px"
        >
          <a @click="collapsedSider" type="text" class="sider-trigger-a">
            <Icon
              :class="[collapsed ? 'rotate-icon' : '']"
              type="md-menu"
              size="24"
            ></Icon>
          </a>
          <div
            style="
              display: inline-block;
              vertical-align: top;
              margin-left: 30px;
            "
          >
            <Breadcrumb>
              <BreadcrumbItem  >
                <span @click="gomain"><Icon type="ios-home-outline"></Icon> 工作台</span>
              </BreadcrumbItem>
              <BreadcrumbItem v-if="bread1">
                <Icon v-if="bread1.icon" :type="bread1.icon"></Icon>
                {{ bread1.title }}
              </BreadcrumbItem>
              <BreadcrumbItem v-if="bread2">
                <Icon v-if="bread2.icon" :type="bread2.icon"></Icon>
                {{ bread2.title }}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>

          <userAvatar
            style="
              float: right;
              height: auto;
              padding-right: 20px;
              line-height: 64px;
            "
          />

          <div
            style="
              float: right;
              height: auto;
              padding-right: 20px;
              line-height: 64px;
            "
          >
            <Tooltip
              :content="value ? '退出全屏' : '全屏'"
              placement="bottom"
              style="display: none"
            >
              <Icon type="md-contract" :size="23"></Icon>
            </Tooltip>
            <span
              style="
                color: #fff;
                font-size: 14px;
                font-weight: bold;
                margin-left: 15px;
              "
              >{{
                userinfo.companyName ? userinfo.companyName : "未设置公司"
              }}/{{ userinfo.orgName ? userinfo.orgName : "未设置部门" }}</span
            >
          </div>
        </div>
      </Header>
      <Content class="content-wrapper">
        <Tag
          type="border"
          v-for="(item, index) in selectedTages"
          :key="index"
          class="tag_box"
          :closable="item.isClose"
          checkable
          >{{ item.meta.title }}</Tag
        >
        <keep-alive>
          <router-view />
        </keep-alive>
       
      </Content>
    </Layout>

    <div>
      <Modal
        v-model="isModalVisible"
        title="选择机构"
        @on-ok="handleOk"
        @on-cancel="handleCancel"
      >
        <Form ref="formCustom" :label-width="100">
          <i-col
            style="
              overflow: hidden;
              padding-right: 10px;
              background: #fff;
              margin-right: 4px;
            "
          >
            <div style="background: #fff; margin-right: 10px">
              <OrgTreeView
                ref="orgtree"
                exp="orgType#eq#1"
                menuTitle="进入机构"
                :hasSubActive="common.getSubViewCount(viewGrid, 'orglist')"
                @onOrgSelected="onOrgSelected"
                @colseSubView="colseSubView('orglist')"
              />
            </div>
          </i-col>
        </Form>
      </Modal>
    </div>
  </Layout>
</template>
<script>
import {
  getlocalStorage,
  setlocalStorage,
  removelocalStorage,
} from "@/utils/localStorage.js";
import SideMenu from "./components/side-menu";
import minLogo from "@/assets/images/logo_01.png";
import maxLogo from "@/assets/images/logo_lg_01.png";
import "./main.less";
import userAvatar from "@/components/main/user/user.vue";
import Request from "@/utils/baseInterface.js";
import router from "@/router/index.js";
import { formatterAsycMenu } from "@/libs/util.js";
import { removeAllLocalStorage } from "@/utils/localStorage.js";
import store from "@/store"
import simpletable from "@/components/wxe/simpletable";
import smartForm from "@/components/wxe/smartForm"; //表单
import quickqueryform from "@/components/wxe/quickqueryform"; //快查
import Datautil from "@/utils/datautil";
import OrgTreeView from "@/components/wxe/OrgTreeView";

export default {
  components: {
    SideMenu,
    userAvatar,
    simpletable,
    smartForm,
    quickqueryform,
    OrgTreeView,
  },
  data() {
    return {
      minLogo,
      maxLogo,
      updatastr: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      menuList: [],
      newmenuid: [],
      newmenutit: [],
      selectedTages: [], //选中标签
      collapsed: false,
      bread1: null,
      bread2: null,
      value: "全屏",
      isModalVisible: false,
      userinfo: {},
      isVisiblerole: false,
      itemdatas: [
        {
          title: "编号",
          field: "id",
          ctype: "simpletext",
          isshowlist: true,
          isshowadd: false,
          isshowdetail: true,
          isshowedit: true,
          correct: true,
          defvalue: null,
          pkcol: true,
          canedit: false,
        },
        {
          field: "departname",
          title: "机构名称",
          ctype: "simpletext",
          correct: true,
          defvalue: null,
          canedit: true,
          datatype: "*",
        },
        {
          field: "orgType",
          title: "组织类型",
          ctype: "dropselect",
          correct: true,
          defvalue: "3",
          canedit: true,
          datatype: "*",
          dictCode: "sys_orgType",
        },
        {
          field: "description",
          title: "描述",
          ctype: "simpletext",
          correct: true,
          defvalue: null,
          canedit: true,
          datatype: "*",
        },
        {
          field: "parentdepartid",
          title: "父级菜单",
          ctype: "selectoutlet",
          targetfiled: "pname",
          isshowedit: false,
          correct: true,
          defvalue: null,
          canedit: false,
          datatype: "*",
        },
      ],

      gridedata: {
        tid: "rolelist",
        tableColumn: [],
        datas: [],
        total: 0,
        optwidth: 60,

        funopt: [
          {
            funname: "setmenu",
            icon: "vxe-icon--menu",
            title: "选择",
            buttoncolor: "success",
          },
        ],
      },

      quickquery: { formitems: [] },
      formitems: [],
      formconfig: { title: "编辑", modal1: false },
      selectedrow: null,
      queryparma: { page: 1, rows: 20 },
      menuqueryparma: { page: 1, rows: 20 },
      rid: null,
      viewGrid: [
        {
          viewName: "orglist",
          isShow: true,
          proportion: 1,
          index: 1,
          hasSubActive: true,
        },
        {
          viewName: "rolelist",
          isShow: true,
          proportion: 2,
          index: 2,
          hasSubActive: true,
        },
        {
          viewName: "menulist",
          isShow: true,
          proportion: 1,
          index: 3,
          hasSubActive: false,
        },
      ],
    };
  },
  created() {

    let that = this;
    var userinfo_ = getlocalStorage("userinfo");
    if (userinfo_) {
      var temp = JSON.parse(userinfo_);
      this.userinfo = temp;
    }




   this.isVisiblerole=Datautil.isadminrole()
   if (this.isVisiblerole)
   {
   

  var menusgroup = JSON.parse(getlocalStorage("menusgroup"));
    var newmenu = [];
    var vmenu = JSON.parse(getlocalStorage("menus"));
    for (let i = 0; i < vmenu.length; i++) {
      if (vmenu[i]["meta"].title == "运维管理") {
        var newmenu1 = [];
        newmenu1["component"] = "main";
        newmenu1["icon"] = "md-add";
        newmenu1["name"] = "opretion" + i;
        newmenu1["path"] = "/opt";
        newmenu1["id"] = "1";
        var newmenu2 = [];
        newmenu2["href"] = "1";
        newmenu2["icon"] = "md-add";
        newmenu2["title"] = "运维管理";
        newmenu1["meta"] = newmenu2;
        var newmenu3 = [];
        for (let j = 0; j < menusgroup.length; j++) {
          var newmenu9 = [];
          newmenu9["component"] = "main";
          newmenu9["icon"] = "md-cog";
          newmenu9["name"] = "menusgroup" + j;
          newmenu9["path"] = "/opt" + j;
          newmenu9["id"] = menusgroup[j].id;
          that.newmenuid["menusgroup" + j] = menusgroup[j].id;
          that.newmenutit["menusgroup" + j] = menusgroup[j].departname;
          var newmenu10 = [];
          newmenu10["href"] = menusgroup[j].id;
          newmenu10["icon"] = "md-add";
          newmenu10["title"] = menusgroup[j].departname;
          newmenu9["meta"] = newmenu10;
          newmenu3.push(newmenu9);
        }
        newmenu1["children"] = newmenu3;
        newmenu.push(newmenu1);
      } else {
        newmenu.push(vmenu[i]);
      }
    }
    this.menuList = newmenu;
   
   }

    else
    {
     
     var vmenu = JSON.parse(getlocalStorage("menus"));
        this.menuList = vmenu;

    }


   
    this.loadDictDatas();
    this.loadRoleDatas();
    var columndata = Datautil.$getGridColumn(this.itemdatas);
    this.gridedata.tableColumn = columndata;
    this.quickquery.formitems = Datautil.$getQueryFormItems(this.itemdatas);
    var columnmenudata = Datautil.$getGridColumn(this.menuitemdatas);
    this.gridemenudata.tableColumn = columnmenudata;
  },

  methods: {
    //分页加载数据
    LoadDatas(did) {
      this.$refs.orgtree.LoadDataspid(did);
    },
    colseSubView() {
    },
    gomain(){
         store.commit('setshowleftmenu',true);
        this.$router.replace("/");
        
    },
    onOrgSelected(data) {
      this.selectedOrg = data.selected;
      this.queryparma.orgId = data.selected.id;
      this.orgName = this.selectedOrg.departname;
      this.orgid = this.selectedOrg.id;
      //this.LoadDatas();
      //  this.viewGrid=this.common.activeView(this.viewGrid,"rolelist");
      setlocalStorage("selorg", data.selected);
      this.isModalVisible = false;
      this.$router.replace("/opt/room");
    },

    handleOk() {},
    handleCancel() {
      this.$Message.info("请选择机构");
    },
    collapsedSider() {
       store.commit('setshowleftmenu',true);
      this.collapsed = this.collapsed == true ? false : true;
    },
    loadDictDatas() {
      if (!getlocalStorage("dictdats")) {
        Request({ url: "/empapi/sys/dict/queryAllDict", method: "get" }).then(
          (res) => {
            setlocalStorage("dictdats", res.data.dicts);
          }
        );
      }
    },
    loadRoleDatas() {
      if (!getlocalStorage("rolelist")) {
        var userInfo = JSON.parse(getlocalStorage("userinfo"));
        var uid = userInfo.id;
        Request({
          url: "empapi/sys/role/getAllRoleCode/" + uid,
          method: "get",
        }).then((res) => {
          setlocalStorage("rolelist", res);
        });
      }
    },
    /***
     * 获取面包屑的名称
     */
   getBeadName(name) {
   var menus = JSON.parse(getlocalStorage("menus"));
      if (menus) {
        menus.forEach((item) => {
          var childs_ = item.children;
          if (childs_) {
            var ch = childs_.find((i) => i.name === name);
            if (ch) {
              var bread1 = {};
              bread1.title = item.meta.title;
              bread1.icon = item.meta.icon;

              var bread2 = {};
              bread2.title = ch.meta.title;
              bread2.icon = ch.meta.icon;

              this.bread1 = bread1;
              this.bread2 = bread2;
            }
          }
        });
      }
    },
    onTabClick(e, vv) {
      let route = this.$router;
      if (e.startsWith("menusgroup")) {
        let id = this.newmenuid[e];

             var bread1 = {};
              bread1.title ="运维管理";
              var bread2 = {};
              bread2.title = this.newmenutit[e];

              this.bread1 = bread1;
              this.bread2 = bread2;

        setlocalStorage("selmenutit",   this.newmenutit[e]);
        this.LoadDatas(id);
        this.isModalVisible = true;
        this.$router.replace("/loading");
        return;
      }

      console.log(this.$router.options.routes.find((route) => route.name === e) );
      let { name, params, query } = {};
      if (typeof e === "string") name = e;
      else {
        name = route.name;
        params = route.params;
        query = route.query;
      }
      if (name.indexOf("isTurnByHref_") > -1) {
        window.open(name.split("_")[1]);
        return;
      }
      var tabs = this.selectedTages;
      tabs.push();
      //把菜单写入tab
      this.$router.replace({
        name,
        params,
        query,
      });

      this.getBeadName(name);
    },
  },
};
</script>
<style>
.rotate-icon {
  transform: rotate(-90deg);
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.layout .ivu-menu {
  z-index: 0;
}
.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-logo-left {
  width: 90%;
  height: 30px;
  background: #5b6270;
  border-radius: 3px;
  margin: 15px auto;
}
.menu-icon {
  transition: all 0.3s;
}
.rotate-icon {
  transform: rotate(-90deg);
}
.menu-item span {
  display: inline-block;
  overflow: hidden;
  width: 69px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width 0.2s ease 0.2s;
}
.menu-item i {
  transform: translateX(0px);
  transition: font-size 0.2s ease, transform 0.2s ease;
  vertical-align: middle;
  font-size: 16px;
}
.collapsed-menu span {
  width: 0px;
  transition: width 0.2s ease;
}
.collapsed-menu i {
  transform: translateX(5px);
  transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
  vertical-align: middle;
  font-size: 22px;
}
.headertitle {
}
</style>
