<template>
  <div class="formitembox-mm">
      <FormItem :label="formattertitle(formitem)" :prop="formitem.field">
    <Input
      icon="md-funnel"
      @on-blur="itemchange"
      @on-enter="opendataselect"
      @on-click="opendataselect"
      v-model="formitem.defvalue"
      :class="formitem.correct == true ? '' : 'error'"
      readonly
       :disabled="formitem.canedit == true ? false : true"
      style="width:92%;height:29px"
    />
     <Modal
      v-model="isshow"
      width="25vw"
      title="多值数据管理器"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <div class="modalcontent">
           
         <Input v-model="newval">
              <Button slot="append" icon="md-add"  @click="addVal"></Button>
          </Input>
        <div class="tables">
             <div span="4">已添加数据：</div>
          <div class="flex" style="flex-wrap: wrap;">
               
                <div  v-for="(item,index) in selectdatas" :key="index" :data-id="item">
                  <Tag closable @on-close="removerow(item)">{{item}}</Tag>
                </div>
            </div>
        </div>
      </div>
    </Modal>
     </FormItem>
  </div>
</template>
<script>
import validity_ from "@/components/wxe/smartvalidate.js";
export default {
   data() {
    return {
        isshow: false,
        selectdatas:[],//可选值列表
        newval:null,//输入的值
    };
  },
  created() {
   this.loaddefaultdata();
  },
  updated(){
    
  },
  props: {
    formitem: {
      type: Object,
      value: null
    }
  },
  methods: {
    //格式化标题
    formattertitle(item){
      var title=item.title;
      if(item.datatype){
        title=title+'*'
      }
      title=title+":";
      return title;
    },
    itemchange(e) {
      let d_ = {};
      let filedname = this.formitem.field;
      let val = e.currentTarget.value; //取值
      d_.filedname = filedname;
      d_.val = val;
      //更新校验结果
      this.formitem.correct=true;
      this.$emit("formitemchange", d_);
    },
    opendataselect(){
      //带图标的好像没办法禁用
      if(this.formitem.canedit){
        this.isshow = true;
      }
       
    },
    removerow(row){
      //1. 查找下标
        if(this.selectdatas){
            this.selectdatas.forEach((item, index) => {
                if(item==row){
                    this.selectdatas.splice(index,1);
                     //如果希望父组件跟着动就放开，不需要就关闭
                    this.formitem.defvalue=this.selectdatas.join(",");
                   
                }
            });
        }
         let d_ = {};
        let filedname = this.formitem.field;
        let val = this.formitem.value; //取值
        d_.filedname = filedname;
        d_.val = val;
     
        this.$emit("formitemchange", d_);
    },
    /**
     * 添加值
     */
    addVal(){
      let flag1= this.selectdatas.findIndex(item => item=== this.newval)
      if(flag1==-1){
        this.selectdatas.push(this.newval);
        //如果希望父组件跟着动就放开，不需要就关闭
        this.formitem.defvalue=this.selectdatas.join(",");
         //更新校验结果
          this.formitem.correct=true;
      }else{

      }
      

    },
    ok() {
      if (!this.selectdatas || this.selectdatas.length < 1) {
        this.$Message.info({
          content: "您没有选择数据",
          duration: 2,
        });
      } else {

       if(this.selectdatas){
           var ids_=[];
           this.formitem.defvalue=this.selectdatas.join(",");
       }
        let d_ = {};
        let filedname = this.formitem.field;
        let val = this.formitem.defvalue; //取值
        d_.filedname = filedname;
        d_.val = val;
     
        this.$emit("formitemchange", d_);

      }
    },
    cancel() {},
     //加载默认配置数据
    loaddefaultdata(){
      if(this.formitem.defvalue){
        var selectdatas_=[];
       
        var defvalue_=this.formitem.defvalue.split(",");
        defvalue_.forEach((item,index)=>{
          selectdatas_.push(item);
        });
        this.selectdatas=selectdatas_;
      }
    },

  }
};
</script>
<style  scoped>

</style>