<template>
<black>
 <Button type="primary" size="small" icon="ios-cloud-upload" @click="handleClick">excel导入</Button>
  <input type="file"   @change="handleChange" style="display:none"  ref="input"
                accept=".xlsx, .xls"/>
</black>
 
</template>

<script>
 import axios from 'axios';
import {getlocalStorage} from "@/utils/localStorage.js"
export default {
    props:{
        ActionUrl:{
            Type:String,
            default:'/empapi/sys/tbsysemploy/importExcel'
        }
    },

    methods:{
        handleClick () {
            this.$refs.input.click();
        },
         handleChange(e){
          const files = e.target.files;

            if (!files) {
                return;
            }
            this.uploadFile(files[0]);
       },
       uploadFile(file){
            var checktype=this.checkfiletype(file);
            if(!checktype)return;;

            //文件上传
            var formData = new FormData();
            // formData.append('name', 'file');
            formData.append('file',file);

            var apitoken_= getlocalStorage("apitoken");
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'WXXCXTOKEN': apitoken_
            }
          };
          var surl_=this.common.baseUrl+this.ActionUrl;
          axios.post(surl_,formData,config)
            .then((loadres) => {
                if (loadres.status == 200) {
                    console.log("loadres:",loadres.data);
                    var result=loadres.data;
                

                    if(result.code==0){
                     this.$Message.info({ content: "导入成功", duration: 2,});
                    }else {
                    this.$Message.info({ content: "未知错误", duration: 2,});
                    }
                }
                else{
                    this.$Message.info({ content: "未知错误", duration: 2,});
                }

            })
        },
        checkfiletype(file){
            var ret=false;
            var imgName = file.name;
            var idx = imgName.lastIndexOf(".");  
            if (idx != -1){
                var ext = imgName.substr(idx+1).toUpperCase();   
                ext = ext.toLowerCase( ); 
                if (ext!='xlsx' && ext!='xls' ){
                     this.$Message.info({ content: "只支持xlsx、xls类型文件，请勿选择其它类型", duration: 2,});
                }else{
                        ret=true;
                }   
            }else{
                this.$Message.info({ content: "只支持xlsx、xls类型文件，请勿选择其它类型", duration: 2,});
            }
            return ret;
        },
    }
}
</script>

<style>

</style>