<template>
  <div ref="onsubmit">
    <Modal v-model="formconfig.modal1" width="68vw" :title="formconfig.title">
      <div class="modalcontent">
        <Form
          class="modalcontent-form"
          ref="formValidate"
          :label-width="68"
          inline
          style="padding-bottom: 35px"
        >
          <template
            :data-tyep="item.ctypeid"
            v-for="item in formitems"
          
          >
              <simpletext
                :item="item"
                v-if="item.ctype == 'simpletext'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <firstmenuselect
                :item="item"
                v-if="item.ctype == 'firstmenuselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <amountText
                :item="item"
                v-if="item.ctype == 'amountText'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <dateselect
                :item="item"
                v-if="item.ctype == 'dateselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
            
              <datetime
                :item="item"
                v-if="item.ctype == 'datetime'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
            
              <simplecode
                :item="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'simplecode'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
            
              <integernumber
                :item="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'integernumber'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              
              <proportionInterger
                :item="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'proportionInterger'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
            
              <imageupload
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'imageupload'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
             
              <simplescore
                :item="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'simplescore'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
             
              <simpletextarea
                :item="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'simpletextarea'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <photoupload
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'photoupload'"
                :key="item.field"
                @formitemchange="formitemchange"
                class="photoupload"/>
              <selectPhoto
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectPhoto'"
                :key="item.field"
                @formitemchange="formitemchange"
                class="photoupload"
              />
              <dropselect
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'dropselect'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
               
              <selectoutlet
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectoutlet'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
               <selectchannel
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectchannel'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
               <selectchanneldeduction
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectchanneldeduction'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              
            
                <selectrole
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectrole'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
            <selectdepart
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectdepart'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 
              <selectservice
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectservice'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 
               <multiselectoutlet
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'multiselectoutlet'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 
                <multiselectservice
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'multiselectservice'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 

               <videoupload
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'videoupload'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 
              <selectbrand 
               :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectbrand'"
                :key="item.field"
                @formitemchange="formitemchange"
              /> 

              <selectCategory
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectCategory'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
               <muititext
                :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'muititext'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
              <selectdicttable 
               :formitem="item"
                :data-type="item.ctype"
                v-if="item.ctype == 'selectdicttable'"
                :key="item.field"
                @formitemchange="formitemchange"
              />
          </template>
        </Form>
      </div> 
      <div slot="footer">
        <Button type="default" @click="cancel"> 取消 </Button>
        <Button
          type="primary"
          v-if="formconfig.option != 'detail' "
          :disabled="disabledsubmit"
          @click="ok"
          >确定</Button
        >
        <Button
          type="primary"
          v-if="formconfig.option == 'audit'"
          :disabled="disabledsubmit"
          @click="auditAgree"
          >通过</Button
        >
        <Button
          type="primary"
          v-if="formconfig.option == 'audit'"
          :disabled="disabledsubmit"
          @click="auditdisagree"
          >不通过</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
// import checkbox from "@/components/form/checkbox.vue";
import validity_ from "@/components/wxe/smartvalidate.js";
import simpletext from "@/components/wxe/simpletext.vue";
import firstmenuselect from "@/components/wxe/firstmenuselect"; //一级菜单
import amountText from "@/components/wxe/amountText";
import dateselect from "@/components/wxe/dateselect";
import simplecode from "@/components/wxe/simplecode";
import integernumber from "@/components/wxe/integernumber";
import proportionInterger from "@/components/wxe/proportionInterger";
import imageupload from "@/components/wxe/imageupload";
import simplescore from "@/components/wxe/simplescore";
import datetime from "@/components/wxe/datetime";
import simpletextarea from "@/components/wxe/simpletextarea";

import photoupload from "@/components/wxe/photoupload";

import selectPhoto from "@/components/select/selectPhoto";

import dropselect from "@/components/wxe/dropselect";
import selectoutlet from "@/components/wxe/selectoutlet";
import selectchannel from "@/components/wxe/selectchannel";
import selectchanneldeduction from "@/components/wxe/selectchanneldeduction";
import selectrole from "@/components/wxe/selectrole";
import selectdepart from "@/components/wxe/selectdepart";
import selectservice from "@/components/wxe/selectservice";
import multiselectoutlet from "@/components/wxe/multiselectoutlet";
import multiselectservice from "@/components/wxe/multiselectservice";
import selectbrand from "@/components/wxe/selectbrand";

import selectCategory from "@/components/select/selectcategory";

import videoupload from "@/components/wxe/videoupload";
import muititext from "@/components/wxe/muititext";
import selectdicttable from "@/components/wxe/selectDictTable";
export default {
  components: {
    simpletext,//单行文本
    firstmenuselect,//一级菜单选择
    amountText,//金额
    dateselect,//日期选择
    simplecode,//编码
    integernumber,//整数
    proportionInterger,//整数占比
    imageupload,//图片上传
    simplescore,//评分
    datetime,//时间选择
    simpletextarea,//多行文本
    photoupload,
    dropselect,//下拉选择
    selectoutlet,//门店选择
    selectrole,//角色设置
    selectdepart,
    selectservice,//选择服务
    multiselectoutlet,//门店多选
    multiselectservice,//服务多选
    videoupload,
    selectchannel,
    selectchanneldeduction,
    selectbrand,//选择品牌
    selectCategory,//选择分类
    muititext,//多输入
    selectPhoto,
    selectdicttable//字典表
  },
  data() {
    return {
      modal1: false,
      submitdata: {},
      disabledsubmit: false,
    };
  },
  created() {
    // console.log(this.formconfig);

    this.modal1 = this.formconfig.modal1;
  },
  updated() {
    // console.log('哈哈哈表数据',this.formconfig);
  },
  props: {
    formitems: {
      type: Array,
      value: null,
    },
    formconfig: {
      type: Object,
      value: null,
    },
  },
  methods: {
    ok() {
      //this.disabledsubmit=true;//防止重复提交
      var d_ = this.getsubmitdata();
      //this.$emit("onsubmit", d_);
      //先校验表单数据
      var check = this.validiteFormItems();
      if (check == true) {
        var data_ = {};
        data_.datas = this.getsubmitdata();
        data_.option = this.formconfig.option;
        data_.tid=this.formconfig.tid;
        this.$emit("onsubmit", data_);
      } else {
        this.$Message.info({
          content: "表单校验不通过，请检查",
          duration: 2,
        });
        return;
      }
    },
    //审核不同意
    auditdisagree(){
       //this.disabledsubmit=true;//防止重复提交
      var d_ = this.getsubmitdata();
      //this.$emit("onsubmit", d_);
      //先校验表单数据
      var check = this.validiteFormItems();
      if (check == true) {
        var data_ = {};
        data_.datas = this.getsubmitdata();
        data_.option = this.formconfig.option;
        data_.agree = false;
        data_.tid=this.formconfig.tid;
        this.$emit("onsubmit", data_);
      } else {
        this.$Message.info({
          content: "表单校验不通过，请检查",
          duration: 2,
        });
        return;
      }
    },
    //审核同意
     auditAgree(){
       //this.disabledsubmit=true;//防止重复提交
      var d_ = this.getsubmitdata();
      //this.$emit("onsubmit", d_);
      //先校验表单数据
      var check = this.validiteFormItems();
      if (check == true) {
        var data_ = {};
        data_.datas = this.getsubmitdata();
        data_.option = this.formconfig.option;
        data_.agree = true;
        data_.tid=this.formconfig.tid;
        this.$emit("onsubmit", data_);
      } else {
        this.$Message.info({
          content: "表单校验不通过，请检查",
          duration: 2,
        });
        return;
      }
    },
    cancel() {
      //记得把选中数据清空数据
      this.formitems.forEach((item) => {
        item.defvalue = null;
        item.correct = true;
      });
      this.formconfig.modal1 = false;
    },
    subEvent(e) {
      //window.console.log(e);
    },
    formitemchange(data) {
      var data_={};
      this.disabledsubmit = false; //数据校验不通过，数据改变时回复提交按钮
      // console.log("formitemchange:",data);
      //1. 先取出绑定的字段名称
      let filedname = data.filedname;
      let val = data.val; //取值
      data_.fieldname=data.filedname;
      data_.defvalue=data.val;
      data_.deftext=data.deftext;
      //把数据加入到待提交数据中
      this.submitdata[filedname] = val;
      // console.log("formitemchange:"+e.currentTarget.value);
      //this.pinying=spell.chineseToPinYin(e.currentTarget.value);
       this.$emit("formitemchnage", data_);
    },

    getsubmitdata() {
      var data = {};
      this.formitems.forEach((item) => {
        if (item.defvalue) {
          data[item.field] = item.defvalue;
        }
        if (item.targetfiled) {
          data[item.targetfiled] = item.deftext;
        }
      });
      window.console.log("s:", data);
      return data;
    },
    //表单校验
    validiteFormItems() {
      var ret = true;
      this.formitems.forEach((item) => {
        if (item.datatype) {
          var validateret = validity_(item.datatype, item.defvalue);
          if (!validateret.success) {
            item.correct = false;
            ret = false;
          } else {
            item.correct = true;
          }
        }
      });
      return ret;
    },
  },
};
</script>
<style  >



.iteminpu {
  display: inline-block;
  width: 100%;
  height: 29px;
  line-height: 1.5;
  padding: 4px 7px;
  font-size: 12px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  color: #515a6e;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}
.iteminpu:focus {
  display: inline-block;
  outline: none;
  border-color: #33aacc !important;
  box-shadow: 0 0 0 2px rgba(0, 149, 191, 0.2) !important;
}
.rulemsg {
  position: absolute;
  font-size: 0.2em;
  top: 100%;
  left: 0;
  line-height: 1;
  padding-top: 6px;
  color: #ed4014;
}
.showlable {
  display: block;
}
.hidelable {
  display: none;
}
.error {
  border: 1px solid #f32323;
}


</style>